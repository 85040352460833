.content-clubEvent[data-v-5d4c5bbf]{
    width: 100%;
    padding: 0 30px;
    font-family: 'Inter';
}
.filterInputSearchEventClub[data-v-5d4c5bbf]{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
}
.inputSearchEventClub[data-v-5d4c5bbf]{
    width: 100%;
    height: 40px;
    background: var(--t-blue);
    border-radius: 8px;
    border: 0;
    color: white;
    padding: 0px 20px 0px 40px;
    margin-top: 20px;
}
.filterInputSearchEventClub[data-v-5d4c5bbf]::before {
    display: block;
    content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='magnifying-glass' class='svg-inline--fa fa-magnifying-glass ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' color='%23fff' data-v-65953c93=''%3E%3Cpath fill='currentColor' d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z'%3E%3C/path%3E%3C/svg%3E");
    /* content: "\f002"; */
    position: absolute;
    font-size: 20px;
    width: 15px;
    height: 15px;
    top: 30px;
    left: 10px;
    color: white;
}
.content-clubEvent input[data-v-5d4c5bbf]::-webkit-input-placeholder, .content-clubEvent textarea[data-v-5d4c5bbf]::-webkit-input-placeholder {
    color: white;
    font-family: var(--font-family-roboto_condensed);
    font-size: 14px;
}
.content-clubEvent input[data-v-5d4c5bbf]::placeholder,
.content-clubEvent textarea[data-v-5d4c5bbf]::placeholder {
    color: white;
    font-family: var(--font-family-roboto_condensed);
    font-size: 14px;
}
.content-clubEvent .buttonCreateEvent[data-v-5d4c5bbf]{
    width: 100%;
    margin: 12px 0;
    height: 45px;
    background-color: var(--t-blue);
    border: 1px solid var(--t-blue);
    border-radius: 8px;
    color: var(--white);
    font-size: 16px;
    cursor: pointer;
    font-family: 'Inter';
}
.buttonCreateEvent[data-v-5d4c5bbf]:hover{
    background-color: var(--black); 
    color: #fff;
}
.tableListClubEvents[data-v-5d4c5bbf]{
    margin: 0;
    width: 100%;
}
.buttonActions[data-v-5d4c5bbf]{
    justify-content: flex-end !important;
}
.labelListEvents[data-v-5d4c5bbf]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--t-blue);
    text-align: left;
    display: block;
    padding: 12px 0;
    margin-right: 15px;
}
.formCreateEventClub[data-v-5d4c5bbf]{
    display: flex;
    margin-top: 15px;
}
.buttonCancel[data-v-5d4c5bbf]{
    width: 45px;
    height: 45px;
    background-color: white;
    border: 1px solid var(--t-blue);
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 15px;
}
.buttonCancel[data-v-5d4c5bbf]::before {
    content: '\f1f8';
    display: block;
    color: var(--t-blue);
}
.buttonSave[data-v-5d4c5bbf]{
    width: 45px;
    height: 45px;
    background-color: var(--t-blue);
    border: 1px solid var(--t-blue);
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.buttonSave[data-v-5d4c5bbf]::before {
    content: '\f00c';
    display: block;
    color: #fff;
}
.row[data-v-5d4c5bbf] {
    display: flex;
}
.row .dataEvent input[data-v-5d4c5bbf] {
    height: 40px;
    width: 100%;
    background: #28115A;
    border-radius: 3px;
    border: 0;
    color: white;
    padding-left: 14px;
}
.row .dataEvent textarea[data-v-5d4c5bbf] {
    width: 100%;
    background-color: #281159;
    border: 0;
    color: white;
    padding: 12px 15px;
}
.row .dataEvent[data-v-5d4c5bbf] {
    margin: 5px;
    display: flex;
    position: relative;
}
.row-4 .dataEvent[data-v-5d4c5bbf] {
    width: 25%;
}
.row.row-1 .dataEvent[data-v-5d4c5bbf] {
    width: 100%;
}
.row.row-2 .dataEvent[data-v-5d4c5bbf] {
    width: 50%;
}
.dataEvent .inputIcon[data-v-5d4c5bbf]{
    position: absolute;
    top: 10px;
    right: 10px;
}
.iconCheckOk[data-v-5d4c5bbf]{
    width: 80px;
    height: 80px;
    background: #2C2C2C;
    border-radius: 40px;
    margin: 0 auto;
    text-align: center;
    font-size: 40px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.labelEventCreated[data-v-5d4c5bbf]{
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: var(--t-blue);
    margin-top: 25px;
}
.buttonSendEvent[data-v-5d4c5bbf]{
    width: 170px;
    height: 40px;
    background: #590CF6;
    border-radius: 12px;
    border: 0;
    color: white;
    margin: 0 auto;
    cursor: pointer;
}
.actions-center[data-v-5d4c5bbf]{
    justify-content: center !important;
}
.tableListClubEvents th[data-v-5d4c5bbf] {
    /* background-image: url(https://anima-uploads.s3.amazonaws.com/projects/621fc5babe91377f45cca168/releases/62275bda4aa2a115d7083fa6/img/header-1@1x.svg); */
    background-size: auto;
    background-position: center center;
}
.tableListClubEvents td[data-v-5d4c5bbf], .tableListClubEvents th[data-v-5d4c5bbf] {
    padding: 15px 10px;
}
.tableListClubEvents > tr[data-v-5d4c5bbf]:nth-child(even){
    background: var(--light-brown);
}
.tableListClubEvents > tr[data-v-5d4c5bbf]:nth-child(odd){
    background: var(--t-blue);
}
.actionsEvents[data-v-5d4c5bbf]{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 80px;
    padding-right: 10px;
}