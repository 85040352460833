.s1[data-v-67f70830] {
  color: black;
  font-style: normal;
  font-weight: bold;
  text-decoration: underline;
  font-size: 12pt;
}
.p[data-v-67f70830],
p[data-v-67f70830] {
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
  margin: 0pt;
}
.s3[data-v-67f70830] {
  color: #467885;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.s4[data-v-67f70830] {
  color: #467885;
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
  font-size: 11pt;
}
h1[data-v-67f70830] {
  color: black;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}
h2[data-v-67f70830] {
  color: black;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}
.s6[data-v-67f70830] {
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}
.s7[data-v-67f70830] {
  color: black;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}
.s8[data-v-67f70830] {
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}
li[data-v-67f70830] {
  display: block;
}
#l1[data-v-67f70830] {
  padding-left: 0pt;
  counter-reset: c1 1;
}
#l1>li>*[data-v-67f70830]:first-child:before {
  counter-increment: c1;
  content: counter(c1, upper-roman)". ";
  color: black;
  font-style: normal;
  font-weight: bold;
  text-decoration: underline;
  font-size: 12pt;
}
#l1>li:first-child>*[data-v-67f70830]:first-child:before {
  counter-increment: c1 0;
}
#l2[data-v-67f70830] {
  padding-left: 0pt;
}
#l2>li>*[data-v-67f70830]:first-child:before {
  content: "- ";
  color: black;
  font-family: Symbol, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
#l3[data-v-67f70830] {
  padding-left: 0pt;
}
#l3>li>*[data-v-67f70830]:first-child:before {
  content: "- ";
  color: black;
  font-family: Symbol, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
#l4[data-v-67f70830] {
  padding-left: 0pt;
}
#l4>li>*[data-v-67f70830]:first-child:before {
  content: "- ";
  color: black;
  font-family: Symbol, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
#l5[data-v-67f70830] {
  padding-left: 0pt;
  counter-reset: d1 1;
}
#l5>li>*[data-v-67f70830]:first-child:before {
  counter-increment: d1;
  content: counter(d1, upper-latin)". ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
#l5>li:first-child>*[data-v-67f70830]:first-child:before {
  counter-increment: d1 0;
}
li[data-v-67f70830] {
  display: block;
}
#l6[data-v-67f70830] {
  padding-left: 0pt;
  counter-reset: e1 1;
}
#l6>li>*[data-v-67f70830]:first-child:before {
  counter-increment: e1;
  content: counter(e1, upper-latin)". ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
#l6>li:first-child>*[data-v-67f70830]:first-child:before {
  counter-increment: e1 0;
}
#l7[data-v-67f70830] {
  padding-left: 0pt;
  counter-reset: e2 1;
}
#l7>li>*[data-v-67f70830]:first-child:before {
  counter-increment: e2;
  content: counter(e2, decimal)". ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
#l7>li:first-child>*[data-v-67f70830]:first-child:before {
  counter-increment: e2 0;
}
#l8[data-v-67f70830] {
  padding-left: 0pt;
  counter-reset: e3 1;
}
#l8>li>*[data-v-67f70830]:first-child:before {
  counter-increment: e3;
  content: "(" counter(e3, lower-roman)") ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
#l8>li:first-child>*[data-v-67f70830]:first-child:before {
  counter-increment: e3 0;
}
#l9[data-v-67f70830] {
  padding-left: 0pt;
  counter-reset: f1 6;
}
#l9>li>*[data-v-67f70830]:first-child:before {
  counter-increment: f1;
  content: counter(f1, decimal)". ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
#l9>li:first-child>*[data-v-67f70830]:first-child:before {
  counter-increment: f1 0;
}
li[data-v-67f70830] {
  display: block;
}
#l10[data-v-67f70830] {
  padding-left: 0pt;
  counter-reset: f1 10;
}
#l10>li>*[data-v-67f70830]:first-child:before {
  counter-increment: f1;
  content: counter(f1, decimal)". ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
#l10>li:first-child>*[data-v-67f70830]:first-child:before {
  counter-increment: f1 0;
}
li[data-v-67f70830] {
  display: block;
}
#l11[data-v-67f70830] {
  padding-left: 0pt;
  counter-reset: g1 1;
}
#l11>li>*[data-v-67f70830]:first-child:before {
  counter-increment: g1;
  content: counter(g1, decimal)". ";
  color: black;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}
#l11>li:first-child>*[data-v-67f70830]:first-child:before {
  counter-increment: g1 0;
}
#l12[data-v-67f70830] {
  padding-left: 0pt;
}
#l12>li>*[data-v-67f70830]:first-child:before {
  content: "- ";
  color: black;
  font-family: Symbol, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
li[data-v-67f70830] {
  display: block;
}
#l13[data-v-67f70830] {
  padding-left: 0pt;
  counter-reset: h1 1;
}
#l13>li>*[data-v-67f70830]:first-child:before {
  counter-increment: h1;
  content: counter(h1, upper-roman)". ";
  color: black;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}
#l13>li:first-child>*[data-v-67f70830]:first-child:before {
  counter-increment: h1 0;
}
#l14[data-v-67f70830] {
  padding-left: 0pt;
}
#l14>li>*[data-v-67f70830]:first-child:before {
  content: "- ";
  color: black;
  font-family: Symbol, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
#l15[data-v-67f70830] {
  padding-left: 0pt;
  counter-reset: h2 1;
}
#l15>li>*[data-v-67f70830]:first-child:before {
  counter-increment: h2;
  content: counter(h2, upper-latin)". ";
  color: black;
  font-style: normal;
  font-weight: bold;
  text-decoration: underline;
  font-size: 12pt;
}
#l15>li:first-child>*[data-v-67f70830]:first-child:before {
  counter-increment: h2 0;
}
#l16[data-v-67f70830] {
  padding-left: 0pt;
}
#l16>li>*[data-v-67f70830]:first-child:before {
  content: "- ";
  color: black;
  font-family: Symbol, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
li[data-v-67f70830] {
  display: block;
}
#l17[data-v-67f70830] {
  padding-left: 0pt;
}
#l17>li>*[data-v-67f70830]:first-child:before {
  content: "- ";
  color: black;
  font-family: Symbol, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}
li[data-v-67f70830] {
  display: block;
}
#l18[data-v-67f70830] {
  padding-left: 0pt;
}
#l18>li>*[data-v-67f70830]:first-child:before {
  content: "- ";
  color: black;
  font-family: Symbol, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}
li[data-v-67f70830] {
  display: block;
}
#l19[data-v-67f70830] {
  padding-left: 0pt;
}
#l19>li>*[data-v-67f70830]:first-child:before {
  content: "- ";
  color: black;
  font-family: Symbol, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}
li[data-v-67f70830] {
  display: block;
}
#l20[data-v-67f70830] {
  padding-left: 0pt;
}
#l20>li>*[data-v-67f70830]:first-child:before {
  content: "- ";
  color: black;
  font-family: Symbol, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}
li[data-v-67f70830] {
  display: block;
}
#l21[data-v-67f70830] {
  padding-left: 0pt;
  counter-reset: d1 8;
}
#l21>li>*[data-v-67f70830]:first-child:before {
  counter-increment: d1;
  content: counter(d1, upper-latin)". ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
#l21>li:first-child>*[data-v-67f70830]:first-child:before {
  counter-increment: d1 0;
}
li[data-v-67f70830] {
  display: block;
}
#l22[data-v-67f70830] {
  padding-left: 0pt;
  counter-reset: n1 9;
}
#l22>li>*[data-v-67f70830]:first-child:before {
  counter-increment: n1;
  content: counter(n1, upper-roman)". ";
  color: black;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}
#l22>li:first-child>*[data-v-67f70830]:first-child:before {
  counter-increment: n1 0;
}
li[data-v-67f70830] {
  display: block;
}
#l23[data-v-67f70830] {
  padding-left: 0pt;
}
#l23>li>*[data-v-67f70830]:first-child:before {
  content: "- ";
  color: black;
  font-family: Symbol, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
table[data-v-67f70830],
tbody[data-v-67f70830] {
  vertical-align: top;
  overflow: visible;
}