.container-members-club[data-v-b50813dd]{
    width: 100%;
    padding: 0 0px;
    font-family: 'Inter';
}
.listMembersClub[data-v-b50813dd] {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: calc(100vh - 480px);
    overflow: scroll;
}
.listMembersClub > div[data-v-b50813dd] {
    width: 45%;
    display: flex;
    padding: 0px;
    align-items: center;
    position: relative;
    margin: 15px 5px;
    border-radius: 30px;
    gap: 5px;
}
.cardMember[data-v-b50813dd]:hover{
    background-color: #383838;
}
.cardMember:hover .option label[data-v-b50813dd]{
    color: white;
}
.cardMember:hover .content-input input + i[data-v-b50813dd]{
    border: 2px solid white;
}
.cardMember:hover .flex-column-invited[data-v-b50813dd]{
    display: none;
}
.cardMember .option[data-v-b50813dd]{
    display: none;
}
.cursor[data-v-b50813dd]{
    cursor: pointer;
}
.cardMember:hover .option[data-v-b50813dd]{
    display: flex;
}
.checkin[data-v-b50813dd]{
    flex-direction: column;
    margin-left: 20px;
    text-align: initial;
    color: white;
    font-size: 14px;
    font-weight: 700;
    width: calc(100% - 50px);
    display: flex;
    justify-content: center;
}
.cardMember:hover p[data-v-b50813dd]{
    display: none;
}

/* .listMembersClub img {
    width: 50px;
    border-radius: 50%;
} */
.listMembersClub p[data-v-b50813dd] {
    color: var(--t-blue);;
    padding-left: 15px;
    font-family: var(--font-family-roboto_condensed);
    width: calc(100% - 60px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
}
.listMembersClub button[data-v-b50813dd] {
    position: absolute;
    top: 12px;
    right: 10px;
}
.cardMember button[data-v-b50813dd]{
    border: 0;
    background-color: transparent;
}
.count-members[data-v-b50813dd]{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}
.count-members p[data-v-b50813dd] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--t-blue);
}
.membersClubHeader[data-v-b50813dd]{
    margin: 20px 0;
}
.membersClubHeader .filterButton[data-v-b50813dd]{
    border: 0;
    background-color: transparent;
    margin-left: 15px;
}
.buttonInviteMembers[data-v-b50813dd]{
    position: absolute;
    top: 0;
    right: 0px;
    width: 157px;
    height: 44px;
    background: #590CF6;
    border-radius: 12px;
    border: 0;
    color: white;
    cursor: pointer;
}
.container-members-club input[data-v-b50813dd]::-webkit-input-placeholder, .container-members-club textarea[data-v-b50813dd]::-webkit-input-placeholder {
    color: white;
    font-family: var(--font-family-roboto_condensed);
    font-size: 14px;
}
.container-members-club input[data-v-b50813dd]::placeholder,
.container-members-club textarea[data-v-b50813dd]::placeholder {
    color: white;
    font-family: var(--font-family-roboto_condensed);
    font-size: 14px;
}
.tabsParticipanting[data-v-b50813dd]{
    display: flex;
    justify-content: space-around;
}
.tabParticipanting[data-v-b50813dd] {
    margin-top: 27px;
    margin-bottom: 20px;
    padding: 10px 20px;
    color: rgb(206, 190, 173);
    cursor: pointer;
}
.tabParticipanting span[data-v-b50813dd] {
    display: block;
    margin: 4px 0;
}
.tabParticipanting.active[data-v-b50813dd] {
    color: var(--t-blue);
    border-bottom: 2px solid var(--t-blue);
}
.textUpload[data-v-b50813dd]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin: 7px 0;
}
.textFormat[data-v-b50813dd]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}
.btnFileExample[data-v-b50813dd]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #A885D7;
    border: 0;
    background-color: transparent;
    margin: 8px 0;
}
.dragZone[data-v-b50813dd]{
    font-family: 'Inter';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 10px;
    width: 548px;
    height: 139px;
    background: #2C2C2C;
    border-radius: 16px;
    color: white;
    text-align: center;
    line-height: 24px;
}
.dragZone button[data-v-b50813dd]{
    color: #590CF6;
    width: 157px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #590CF6;
    border-radius: 12px;
}
.titleModal[data-v-b50813dd] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #A885D7;
}
.subtitleModal[data-v-b50813dd]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}
.buttonSend[data-v-b50813dd]{
    width: 157px;
    height: 44px;
    background: #590CF6;
    border-radius: 12px;
    border: 0;
    color: white;
    cursor: pointer;
}
.buttonClose[data-v-b50813dd]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #A885D7;
    border: 0;
    background-color: transparent;
    cursor:pointer;
}
.option[data-v-b50813dd] {
    padding: 0 15px !important;
}
.cardMember.userCheckin[data-v-b50813dd]:hover {
    background-color: #027497;
}
.checkin span[data-v-b50813dd]{
    font-weight: 400;
}
.buttonRSVP[data-v-b50813dd]{
    display: none;
}
.cardMember.userCheckin:hover .buttonRSVP[data-v-b50813dd]{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.checkout[data-v-b50813dd]{
    background-color: #B31D4A;
    height: 50px;
    width: 50px;
    border-radius: 0px 30px 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease;
    cursor: pointer;
}
.checkout[data-v-b50813dd]:hover{
    width: calc(100% - 60px);
    border-radius: 30px;
    position: absolute;
    top: 0;
    right: 0;
}
.userCheckin[data-v-b50813dd]::before {
    position: absolute;
    content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='star' class='svg-inline--fa fa-star fa-sm ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' color='%23fff' data-v-023c9746=''%3E%3Cpath fill='currentColor' d='M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z'%3E%3C/path%3E%3C/svg%3E");
    background-color: #1DB382;
    width: 15px;
    height: 15px;
    padding: 5px;
    /* padding: 10px; */
    /* display: flex; */
    border-radius: 50%;
    bottom: -5px;
    left: 30px;
    /* align-items: center; */
    /* justify-content: center; */
    color: white;
}
.userGoing[data-v-b50813dd]::before {
    position: absolute;
    content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='star' class='svg-inline--fa fa-star fa-sm ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' color='%23fff' data-v-023c9746=''%3E%3Cpath fill='currentColor' d='M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z'%3E%3C/path%3E%3C/svg%3E");
    background-color: #1DB382;
    width: 15px;
    height: 15px;
    padding: 5px;
    /* padding: 10px; */
    /* display: flex; */
    border-radius: 50%;
    bottom: -5px;
    left: 30px;
    /* align-items: center; */
    /* justify-content: center; */
    color: white;
}
.userInvited[data-v-b50813dd]::before {
    position: absolute;
    content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='envelope' class='svg-inline--fa fa-envelope ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' color='%23fff' data-v-a7a6c62a=''%3E%3Cpath fill='currentColor' d='M0 128C0 92.65 28.65 64 64 64H448C483.3 64 512 92.65 512 128V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V128zM48 128V150.1L220.5 291.7C241.1 308.7 270.9 308.7 291.5 291.7L464 150.1V127.1C464 119.2 456.8 111.1 448 111.1H64C55.16 111.1 48 119.2 48 127.1L48 128zM48 212.2V384C48 392.8 55.16 400 64 400H448C456.8 400 464 392.8 464 384V212.2L322 328.8C283.6 360.3 228.4 360.3 189.1 328.8L48 212.2z'%3E%3C/path%3E%3C/svg%3E");
    background-color: #590CF6;
    width: 15px;
    height: 15px;
    padding: 5px;
    /* padding: 10px; */
    /* display: flex; */
    border-radius: 50%;
    bottom: -5px;
    left: 30px;
    /* align-items: center; */
    /* justify-content: center; */
    color: white;
}
.userNoCheckin[data-v-b50813dd]::before {
    position: absolute;
    content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='circle-xmark' class='svg-inline--fa fa-circle-xmark fa-sm ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' color='%23fff' data-v-023c9746=''%3E%3Cpath fill='currentColor' d='M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z'%3E%3C/path%3E%3C/svg%3E");
    background-color: #B31D4A;
    width: 15px;
    height: 15px;
    padding: 5px;
    /* padding: 10px; */
    /* display: flex; */
    border-radius: 50%;
    bottom: -5px;
    left: 30px;
    /* align-items: center; */
    /* justify-content: center; */
    color: white;
}
.userInterestedRoster[data-v-b50813dd]::before {
    display: none !important;
}
.userInterested[data-v-b50813dd]::before {
    position: absolute;
    content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='head-side' class='svg-inline--fa fa-head-side fa-sm ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' color='%23fff' data-v-023c9746=''%3E%3Cpath fill='currentColor' d='M383.1 192c0-17.62-14.38-32-31.1-32c-17.62 0-31.1 14.38-31.1 32s14.37 32 31.1 32C369.6 224 383.1 209.6 383.1 192zM509.2 275c-20.1-47.12-48.5-151.8-73.12-186.8C397.6 33.62 334.5 0 266.1 0H200.1c-104.5 0-195.9 80.12-199.9 184.5c-2.25 59.88 22.99 113.6 63.86 150.4l.0063 153.1C64.01 501.3 74.75 512 88.01 512c13.25 0 23.1-10.74 23.1-23.1V313.4L96.01 299.1C49.13 257 33.01 187.4 63.88 126.6C89.26 76.75 143.1 48 198.1 48h67.12c51.1 0 100.9 25.38 130.7 67.88c11.25 15.88 27.1 65.62 40.37 102C443.7 237.2 450.1 255.8 456.2 272h-56.25V384c0 8.837-7.163 16-16 16h-79.99v88c0 13.25 10.74 23.1 23.1 23.1c13.25 0 23.1-10.74 23.1-23.1V448h31.1c35.34 0 63.1-28.65 63.1-63.1V320h31.1C503.1 320 518.6 296.1 509.2 275z'%3E%3C/path%3E%3C/svg%3E");
    background-color: #FFC429;

    width: 15px;
    height: 15px;
    padding: 5px;
    /* padding: 10px; */
    /* display: flex; */
    border-radius: 50%;
    bottom: -5px;
    left: 30px;
    /* align-items: center; */
    /* justify-content: center; */
    color: white;
}
.userCheckin[data-v-b50813dd]:hover::before {
    content: 'Going';
    width: 50px;
    font-size: 11px;
    left: 0;
    border-radius: 100px;
}
.userGoing[data-v-b50813dd]:hover::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' %3E%3Cpath fill='%23fff' d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/%3E%3C/svg%3E");
    /* width: 50px; */
    font-size: 11px;
    /* left: 0; */
    border-radius: 100px;
}
.userInvited[data-v-b50813dd]:hover::before {
    content: 'Invited';
    width: 50px;
    font-size: 11px;
    left: 0;
    border-radius: 100px;
}
.userNoCheckin[data-v-b50813dd]:hover::before {
    content: 'Not Going';
    width: 50px;
    font-size: 9px;
    left: 0;
    border-radius: 100px;
}
.userInterested[data-v-b50813dd]:hover::before {
    content: 'Interest';
    width: 50px;
    font-size: 11px;
    left: 0;
    border-radius: 100px;
}
.checkout[data-v-b50813dd]{
    color: #fff;
}
.textCheckout[data-v-b50813dd]{
    display: none
}
.checkout:hover .textCheckout[data-v-b50813dd]{
    display: contents;
}
.checkout:hover .fontawesome5pro-solid-normal-white-20px[data-v-b50813dd]{
    margin-right: 12px;
}
.inputSearchUser[data-v-b50813dd]::-webkit-input-placeholder {
    color: var(--white);
    font-size: var(--font-size-l2);
    font-style: normal;
    font-weight: 400;
}
.inputSearchUser[data-v-b50813dd]::placeholder {
    color: var(--white);
    font-size: var(--font-size-l2);
    font-style: normal;
    font-weight: 400;
}
  
/* .filterInputSearchUser::before {
    display: block;
    font-family: "Font Awesome 5 Pro-Regular";
    content: "\f002";
    position: absolute;
    font-size: 20px;
    top: 8px;
    left: 8px;
    color: white;
} */
.filterInputSearchUser[data-v-b50813dd] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 27pt;
    border-radius: 10px;
    width: 100%;
    background-color: var(--t-blue);
    border: 0px;
    color: white;
    font-size: 16px;
}
.search[data-v-b50813dd]{
    margin-left: 15px;
}
.filterFILTERInputSearchUser[data-v-b50813dd] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.inputSearchUser[data-v-b50813dd] {
    width: 100%;
    margin-left: 5px;
    background-color: var(--light-brown);
    border: 0px;
    border-radius: 4px;
    color: white;
}
.flex-column-invited[data-v-b50813dd]{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.parragraphLavender[data-v-b50813dd]{
    color: var(--lavender);
    text-align: left;
    margin-left: 15px;
}
.unregistered-lavender[data-v-b50813dd]{
    color: var(--lavender) !important;
    padding-left: 0px !important;
}