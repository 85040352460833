
.container-notifications[data-v-d516df43]{
    width: 100%;
}
.title-ntf[data-v-d516df43]{
    color: var(--t-blue);
    font-size: 25px;
    font-weight: 700;
    font-family: 'Inter';
    display: inline-block;
    width: 100%;
    text-align: left;
}
.card-notificacion[data-v-d516df43] {
    width: 100%;
    display: flex;
    padding: 15px 0px;
    align-items: center;
}
.card-notificacion img[data-v-d516df43] {
    width: 50px;
    object-fit: cover;
    height: 50px;
    min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
    margin-right: 10px;
    border-radius: 50%;
    border: 3px solid #484848;
}
.ntf-information[data-v-d516df43]{
    display: flex;
    flex-direction: row;
    font-family: 'Inter';
    align-items: center;
    width: 100%;
}
.name-bold[data-v-d516df43] {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    color: var(--lavender);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
}
.name-bold span[data-v-d516df43]{
    font-weight: 100;
}
.name-light[data-v-d516df43]{
    text-align: left;
    color: var(--t-blue);
    font-size: 18px;
    display: block;
}
.list-notificacion[data-v-d516df43]{
    margin-top: 10px;
    overflow: scroll;
}
.name-light span[data-v-d516df43]{
    color: var(--lavender);
    font-weight: bold;
}
.txtTime[data-v-d516df43] {
    color: var(--t-blue);
    font-family: 'Inter';
    width: 100%;
    text-align: left;
    display: block;
}
.header-ntf[data-v-d516df43]{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.buttonCloseNtf[data-v-d516df43] {
    border: 0;
    width: 30px;
    background-color: transparent;
    cursor: pointer;
}
.btnRequest[data-v-d516df43]{
    display: flex;
    gap: 5px;
    width: 190px;
    justify-content: center;
}
.card-request[data-v-d516df43]{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}
.buttonCancelRequest[data-v-d516df43]{
    width: 30px;
    height: 30px;
    background-color: white;
    border: 1px solid var(--t-blue);
    border-radius: 7px;
}
.btnRequestEvent[data-v-d516df43]{
    width: 30px;
    height: 30px;
    color: white;
    font-size: 12px;
    background-color: transparent;
    border: 1px solid var(--electric-yellow);
    border-radius: 7px;
}
.buttonAcceptRequest[data-v-d516df43]{
    width: 28px;
    background-color: var(--electric-yellow);
    border: 0;
    border-radius: 9px;
    margin-left: 5px;
}
.buttonApproveOrgNotification[data-v-d516df43]{
    width: 94px;
    height: 34px;
    background-color: var(--electric-yellow);
    border: 0;
    border-radius: 9px;
    margin-left: 5px;
    color: white;
    font-size: 16px;
}
.Notifications-no-content[data-v-d516df43]{
    font-family: 'Inter';
    text-align: center;
    color: var(--t-blue);
    font-size: 18px;
    margin-top: 25px;
}
.visible-scrollbar[data-v-d516df43]{
    display: block;
    width: 100%;
    overflow: auto;
    height: calc(100vh - 150px);
    overflow-x: hidden;
}
.visible-scrollbar[data-v-d516df43]:hover::-webkit-scrollbar{
    display: block;
}
.visible-scrollbar[data-v-d516df43]::-webkit-scrollbar {
    width: 5px;
    display: none;
}
.visible-scrollbar[data-v-d516df43]::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
.visible-scrollbar[data-v-d516df43]::-webkit-scrollbar-thumb {
    background: var(--t-blue); 
    border-radius: 10px;
}
.visible-scrollbar[data-v-d516df43]::-webkit-scrollbar-thumb:hover {
    background: var(--lavender);
}