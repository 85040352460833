.fontawesome5pro-regular-normal-lavender-20px {
  color: var(--lavender);
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.section-navbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 50px;
  margin-top: 6px;
}

.section-label {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
}

.section-column {
  border-bottom: 2px solid transparent;
  width: 100%;
}

.section-column-selected {
  background-color: var(--t-blue);
  width: 100%;
  align-items: center;
  justify-content: center;
}

#left-rad {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
}

#right-rad {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.titleOptions{
  display: flex;
  align-items: center;
  font-weight: 700;
  color: white;
  font-size: 20px;
  margin-bottom: 20px;
}

.listOptionsPost li{
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.listOptionEvents{

  
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;

  width: 330px;
  height: 192px;

  position: absolute;
  z-index: 90;
  top: 55px;
  right: 15px;

  
  background: var(--t-blue);
  border-radius: 3px;

  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: white;
  font-family: 'Inter';
}

.listOptionEvents.active{
  display: block;
}

.aha-logo {
  width: 40px;
  height: 40px;
  margin-left: -4px;
  margin-right: -4px;
}

.iconOptions{
  font-size: 22px;
  margin-right: 15px;
  margin-left: 5px;
  cursor:pointer;
}

.cursor{
  cursor: pointer;
}

.buttonGridContainer{
	display: flex;
  flex-direction: column;
  gap: 4px;
}

.buttonCancelFriendRequest{
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
  background-color: var(--t-blue);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--t-blue);
  color: white;
  width: 45px;
  height: 14px;
  padding: 10px;
}

.buttonSaveFriendRequest{
  color: var(--t-blue);
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
  width: 45px;
  height: 14px;
  padding: 10px;
  background-color: white;
  border: 1px solid var(--t-blue);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.buttonCancelSentRequest{
  font-family: var(--font-family-roboto_condensed);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
  background-color: #590CF6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #590CF6;
  color: white;
  width: 73px;
  height: 40px;
  padding: 14px 0px;
  gap: 10px;
}

.iconConnectionsOptions{
  margin-right: 20px;
  font-size: 22px;
  margin-left: 10px;
}

.connectionsOptions.aha-logo {
  width: 45px !important;
  margin-right: 10px;
  margin-left: 0px;
}

.liLogoAha{
  margin-top: -11px;
}

.optionConnectionsList{
  height: auto;
}

.optionConnectionsList .titleOptions{
  margin-top: 10px;
  margin-left: 10px;
}

.buttonModalDelete {
  width: 45%;
  height: 50px;
  background: var(--t-blue);
  border-radius: 12px;
  border: 0;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.bCancel {
  background: #ffffff;
  color: var(--t-blue);
  border: 1px solid var(--t-blue);
}

.icon-times{
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}