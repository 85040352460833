.tabsBulletin[data-v-229e1384] {
    /* background-color: #383838; */
        /* TARA */
        background-color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* color: white; */
        color: var(--t-blue);
    font-family: 'Inter';
    border-radius: 8px;
}
.bltTab[data-v-229e1384]{
    cursor: pointer;
    padding: 10px 0;
    width: 100%;
}
.bltTab[data-v-229e1384]:first-child {
    border-radius: 8px 0px 0px 8px;
}
.bltTab[data-v-229e1384]:last-child {
    border-radius: 0px 8px 8px 0px;
}
.containerPost[data-v-229e1384]{
    margin-top: 25px;
    width: 100%;
    background-color: #ff000000;
    border: 1px solid white;
    border-radius: 10px;
}
.containerPost textarea[data-v-229e1384]{
    width: 100%;
    height: 50px;
    background-color: #ff000000;
    border: 0;
    color: white;
    padding: 15px;
    resize: none;
    outline: none;
    font-family: 'Inter';
}
.btnsFiles[data-v-229e1384]{
    display: flex;
    justify-content: flex-end;
}
.btnsFiles button[data-v-229e1384] {
    background-color: transparent;
    border: 0;
    padding: 10px 20px;
}
.btnPost[data-v-229e1384]{
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
}
.btnPost select[data-v-229e1384]{
    width: 60%;
    background: #28115A;
    border-radius: 3px;
    color: white;
    padding: 0 15px;
}
.btnPost .savePost[data-v-229e1384]{
    width: 170px;
    height: 44px;
    background: #590CF6;
    border-radius: 12px;
    border: 0;
    color: white;
}
.itemPost[data-v-229e1384]{
    width: 100%;
    background: #28115A;
    border-radius: 16px;
    margin-bottom: 30px;
}
.titlePost[data-v-229e1384]{
    display: flex;
    flex-direction: column;
    padding: 30px;
    position: relative;
}
.btnBots[data-v-229e1384]{
    border: 0;
    background-color: transparent;
    position: absolute;
    top: 20px;
    right: 20px;
}
.titlePost h2[data-v-229e1384]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #A885D7;
    text-align: left;
}
.infoEvent[data-v-229e1384]{
    display: flex;
    margin: 5px 0;
}
.infoDetail[data-v-229e1384] {
    display: flex;
    align-items: center;
    color: white;
    font-family: 'Inter';
    margin-right: 15px;
}
.infoDetail div[data-v-229e1384]{
    margin-right: 10px;
}
.filePost[data-v-229e1384]{
    position: relative;
}
.filePost img[data-v-229e1384]{
    width: 100%;
}
.btnReactions[data-v-229e1384]{
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    right: 0;
    width: 60px;
    background: rgba(23, 3, 46, 0.42);
    border-radius: 24px 0px 0px 0px;
    padding: 15px 0;
}
button.emoji[data-v-229e1384], .comments[data-v-229e1384] {
    border: 0;
    background-color: transparent;
    color: white;
    margin: 10px 0;
}
.infoPost[data-v-229e1384]{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    text-align: left;
    position: relative;
}
.infoProfile[data-v-229e1384]{
    display: flex;
    align-items: center;
    font-size: 16px;
    color: white;
    font-weight: 700;
    font-family: 'Inter';
}
.infoProfile img[data-v-229e1384]{
    width: 35px;
    border-radius: 50%;
    border: 2px solid #383838;
    margin-right: 12px;
}
.textPost[data-v-229e1384]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-top: 10px;
}
.star[data-v-229e1384]{
    position: absolute;
    top: 40px;
    right: 22px;
    border: 0;
    background-color: transparent;
}
textarea[data-v-229e1384]::-webkit-input-placeholder {
    color: white;
    font-family: 'Inter';
}
textarea[data-v-229e1384]::placeholder {
    color: white;
    font-family: 'Inter';
}
.listOptionEvents[data-v-229e1384]{
    width: 200px;
    height: 600px;
    position: absolute;
    z-index: 90;
    top: 55px;
    right: 15px;
    display: none;
}
.listOptionEvents.active[data-v-229e1384],
.listOptionEvents.active .overlap[data-v-229e1384] {
    display: block;
}
.itemPost.postImage[data-v-229e1384]{
    position: relative;
}
.itemPost.postImage .titlePost[data-v-229e1384]{
    /* background-image: linear-gradient(to bottom, #00000085, #5151517a, #00000021, #00bf7200, #a8eb1200); */
    /* position: absolute; */
    width: 100%;
    z-index: 1;
    padding: 0px 0px;
    margin-bottom: 10px;
    border-radius: 16px 16px 0px 0px;
}
.itemPost.postImage .filePost img[data-v-229e1384]{
    border-radius: 16px 16px 0 0;
}
.itemPost.postImage .infoPost[data-v-229e1384]{
    padding: 10px 30px;
}
.itemPost.postImage .infoProfile img[data-v-229e1384]{
    width: 30px;
}
.tabActive[data-v-229e1384]{
    /* background-color: #250D44; */
        background-color: var(--t-blue);
        color: white;
}
.containerPost img[data-v-229e1384]{
    width: 100%;
}
.VideoInput_input[data-v-229e1384]{
    display: none
}
.pressed[data-v-229e1384]{
    opacity: 50;
}
.PostWithoutPosting[data-v-229e1384]{
    margin-top: 20px;
}
.textDrag[data-v-229e1384]{
    width: 90%;
    padding: 10px 0;
    border: 1px dashed #b4b4b4;
    margin: 0 auto;
    border-radius: 4px;
    color: #878787;
    background-color: #3b3b3b87;
    font-family: 'Inter';
}