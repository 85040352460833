.fontawesome5pro-regular-normal-lavender-20px {
  color: var(--lavender);
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.club-view-navbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}

.section-label {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
}

.club-view-row {
  border-bottom: 2px solid transparent;
  width: 100%;
  background-color: var(--t-blue);
}

.club-view-row-selected {
  background-color: transparent;
  width: 100%;
  align-items: center;
  justify-content: center;
}

#left-rad {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
}

#left-rad-down {
  border-top-left-radius: none;
  border-bottom-left-radius: 8px;
  overflow: hidden;
}

#left-rad-up {
  border-top-left-radius: 8px;
  border-bottom-left-radius: none;
  overflow: hidden;
}

#right-rad {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

#right-rad-down {
  border-top-right-radius: none;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

#right-rad-up {
  border-top-right-radius: 8px;
  border-bottom-right-radius: none;
  overflow: hidden;
}
