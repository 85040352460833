.labelListEvents[data-v-f4594899] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--t-blue);
    padding: 8px 0;
    display: block;
    text-align: left;
}
.buttonsSettings[data-v-f4594899]{
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
}
.buttonCancel[data-v-f4594899]{
    width: 120px;
    height: 40px;
    background-color: white;
    border: 1px solid var(--t-blue);
    border-radius: 10px;
    margin-right: 15px;
    cursor: pointer;
    color: var(--t-blue);
}
.buttonSave[data-v-f4594899]{
    width: 120px;
    height: 40px;
    background-color: var(--t-blue);
    border: 1px solid var(--t-blue);
    border-radius: 10px;
    cursor: pointer;
    color: #fff;
}
.InviteMembers--inputDisabled[data-v-f4594899] {
    border-radius: 3px;
    padding: 10px;
    background-color: var(--valhalla);
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.InviteMembers--inputDisabled > span[data-v-f4594899] {
    width: 100% !important;
    overflow: scroll;
}
.InviteMembers--inputDisabled > button[data-v-f4594899] {
    background: none;
    border: none;
    cursor: pointer;
}