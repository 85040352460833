.containerConnections[data-v-3285d0f2]{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}
.cardConnectionButtons[data-v-3285d0f2]{
    width: calc(100% - 25px);
    padding: 12px 0px 12px 12px;
    align-items: center;
    cursor: pointer;

    display: grid;
    grid-template-columns: calc(67% - 15px) 33%;
    grid-column-gap: 10px;
}
.cardConnection[data-v-3285d0f2]{
    width: 100%;
    padding: 12px 0px 12px 12px;
    align-items: center;
    cursor: pointer;
    display: flex;
}
.pendingConnectionsSelectBar[data-v-3285d0f2]{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    color: var(--lavender);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding: 15px 50px 0px 30px;

    width: 100%;
    gap: 10px;
}
.pendingConActive[data-v-3285d0f2]{
    color: var(--t-blue);
}
.ConnectionsPendingWrap[data-v-3285d0f2]{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}
.ConnectionsPendingLine[data-v-3285d0f2]{
    display: none;
    background-color: var(--t-blue);
    width: 100%;
    height: 2px;
    margin-top: 5px;
}
.ConnectionsPendingLine.active[data-v-3285d0f2]{
    display: block;
}

/* .imageConnection{
    width: 50px;
    height: 50px;
    border: 2px solid #2C2C2C;
    border-radius: 50%;
    overflow: hidden;
} */

/* .imageConnection img{
    height: 100%;
} */
.infoConnection[data-v-3285d0f2]{
    margin-left: 10px;  
    text-align: left;
}
.nameConnection[data-v-3285d0f2]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--t-blue);
}
.cardActive.nameConnection[data-v-3285d0f2],
.cardActive.countClubs[data-v-3285d0f2]{
    color: white !important;
}
.countClubs[data-v-3285d0f2]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--t-blue);
}
.cardActive[data-v-3285d0f2],
.cardConnection[data-v-3285d0f2]:hover{
    background-color: var(--t-blue);
    color: white;
    border-radius: 50px 0 0 50px;
}
.cardConnection:hover .nameConnection[data-v-3285d0f2],
.cardConnection:hover .countClubs[data-v-3285d0f2]{
    color: white;
}
.cardConnection.cardActive[data-v-3285d0f2]::before {
    content: '';
    width: 10px;
    background-color: var(--t-blue);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 12;
}
.infoCard[data-v-3285d0f2]{
    display: flex;
    align-items: center;
}
.countConnections[data-v-3285d0f2]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--t-blue); 
    text-align: left;
    padding: 15px;
}
.searchConnections[data-v-3285d0f2]{
    display: flex;
    width: 100%;
    margin-top: 10px;
    align-items: center;
    padding-left: 30px;
    padding-right: 50px;
}
.inputSearch[data-v-3285d0f2]{
    width: 100%;
    background-color: var(--t-blue);
    border-radius: 4px;  
    display: flex;
    align-items: center;
}
.inputSearch input[data-v-3285d0f2]{
    width: 100%;
    padding: 10px 0px;
    background-color: transparent;
    border: 0;
    border-radius: 4px;
    color: white;
}
.btnSort[data-v-3285d0f2]{
    width: 40px;
}
.btnFilter[data-v-3285d0f2]{
    width: 40px;
}
.inputSearch input[data-v-3285d0f2]::-webkit-input-placeholder {
    color: var(--white);
    font-family: var(--font-family-roboto_condensed);
    font-size: var(--font-size-l2);
    font-style: normal;
    font-weight: 400;
}
.inputSearch input[data-v-3285d0f2]::placeholder {
    color: var(--white);
    font-family: var(--font-family-roboto_condensed);
    font-size: var(--font-size-l2);
    font-style: normal;
    font-weight: 400;
}
.itemSort[data-v-3285d0f2],
.itemFilter[data-v-3285d0f2]{
    display: none;
}
.itemSort.sortActive[data-v-3285d0f2],
.itemFilter.filterActive[data-v-3285d0f2]{
    display: block;
    position: absolute;
    left: 10vw;
    top: 17vh;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    width: 300px;
    background-color: var(--t-blue);
    border-radius: 8px;
    padding: 16px 20px;
}
.itemSort.sortActive .labelChechbox[data-v-3285d0f2],
.itemFilter.filterActive .labelChechbox[data-v-3285d0f2]{
    color: var(--white);
}
.buttonApply[data-v-3285d0f2] {
    background-color: var(--electric-yellow);
    border-radius: 12px;
    height: 44px;
    border: 0;
    width: 100%;
    color: white;
    margin-top: 15px;
}
.labelChechbox[data-v-3285d0f2]{
    margin-left: 20px;
    font-family: 'Inter';
}
.option-status[data-v-3285d0f2]{
    margin: 10px 0;
}
.option-status i[data-v-3285d0f2]{
    border-color: #FFFFFF;
}
.titleSort[data-v-3285d0f2]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: left;
    width: 100%;
    display: block;
}
.tabConnections[data-v-3285d0f2] {
    display: flex;
    justify-content: space-around;
    width: 100%;
    Padding-left: 30px;
    padding-right: 50px;
    font-family: 'Inter';
    margin-top: 6px;
    align-items: center;
}
.tabC[data-v-3285d0f2] {
    width: 50%;
    color: var(--t-blue);
    cursor: pointer;
    padding: 8px 0;
}
.tabActive[data-v-3285d0f2]{
    background-color: var(--t-blue);
    color: var(--white)
}
.tabMyConnection[data-v-3285d0f2]{
    border-radius: 8px 0px 0px 8px;
}
.tabMyFriends[data-v-3285d0f2]{
    border-radius: 0px 0px 0px 0px;
}
.tabPendingConnection[data-v-3285d0f2]{
    border-radius: 0px 8px 8px 0px;
}
.containerPost[data-v-3285d0f2]{
    margin-top: 25px;
    width: 100%;
    background-color: #ff000000;
    border: 1px solid white;
    border-radius: 10px;
}
.containerPost textarea[data-v-3285d0f2]{
    width: 100%;
    height: 50px;
    background-color: #ff000000;
    border: 0;
    color: white;
    padding: 15px;
    outline: none;
    resize: none;
    font-family: 'Inter';
}
.btnsFiles[data-v-3285d0f2]{
    display: flex;
    justify-content: flex-end;
}
.btnsFiles button[data-v-3285d0f2] {
    background-color: transparent;
    border: 0;
    padding: 10px 20px;
}
.btnPost[data-v-3285d0f2]{
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
}
.btnPost select[data-v-3285d0f2]{
    width: 60%;
    background: #28115A;
    border-radius: 3px;
    color: white;
    padding: 0 15px;
}
.btnPost .savePost[data-v-3285d0f2]{
    width: 170px;
    height: 44px;
    background: #590CF6;
    border-radius: 12px;
    border: 0;
    color: white;
}
.itemPost[data-v-3285d0f2]{
    width: 100%;
    background: var(--t-off-white);
    border-radius: 16px;
    margin-bottom: 30px;
}
.titlePost[data-v-3285d0f2]{
    display: flex;
    flex-direction: column;
    padding: 30px;
    position: relative;
}
.btnBots[data-v-3285d0f2]{
    border: 0;
    background-color: transparent;
    position: absolute;
    top: 20px;
    right: 20px;
}
.titlePost h2[data-v-3285d0f2]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #A885D7;
    text-align: left;
}
.infoEvent[data-v-3285d0f2]{
    display: flex;
    margin: 5px 0;
}
.infoDetail[data-v-3285d0f2] {
    display: flex;
    align-items: center;
    color: white;
    font-family: 'Inter';
    margin-right: 15px;
}
.infoDetail div[data-v-3285d0f2]{
    margin-right: 10px;
}
.filePost[data-v-3285d0f2]{
    position: relative;
}
.filePost img[data-v-3285d0f2]{
    width: 100%;
}
.btnReactions[data-v-3285d0f2]{
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    right: 0;
    width: 60px;
    background: rgba(23, 3, 46, 0.42);
    border-radius: 24px 0px 0px 0px;
    padding: 15px 0;
}
button.emoji[data-v-3285d0f2], .comments[data-v-3285d0f2] {
    border: 0;
    background-color: transparent;
    color: white;
    margin: 10px 0;
}
.infoPost[data-v-3285d0f2]{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    text-align: left;
    position: relative;
}
.infoProfile[data-v-3285d0f2]{
    display: flex;
    align-items: center;
    font-size: 16px;
    color: var(--t-blue);
    font-weight: 700;
    font-family: 'Inter';
}
.infoProfile img[data-v-3285d0f2]{
    width: 35px;
    border-radius: 50%;
    border: 2px solid #383838;
    margin-right: 12px;
}
.textPost[data-v-3285d0f2]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-top: 10px;
}
.star[data-v-3285d0f2]{
    position: absolute;
    top: 40px;
    right: 22px;
    border: 0;
    background-color: transparent;
}
textarea[data-v-3285d0f2]::-webkit-input-placeholder {
    color: white;
    font-family: 'Inter';
}
textarea[data-v-3285d0f2]::placeholder {
    color: white;
    font-family: 'Inter';
}
.listOptionEvents[data-v-3285d0f2]{
    width: 200px;
    height: 600px;
    position: absolute;
    z-index: 90;
    top: 55px;
    right: 15px;
    display: none;
}
.listOptionEvents.active[data-v-3285d0f2],
.listOptionEvents.active .overlap[data-v-3285d0f2] {
    display: block;
}
.itemPost.postImage[data-v-3285d0f2]{
    position: relative;
}
.itemPost.postImage .titlePost[data-v-3285d0f2]{
    /* background-image: linear-gradient(to bottom, #00000085, #5151517a, #00000021, #00bf7200, #a8eb1200); */
    /* position: absolute; */
    width: 100%;
    z-index: 1;
    padding: 0px 0px;
    margin-bottom: 10px;
    border-radius: 16px 16px 0px 0px;
}
.itemPost.postImage .filePost img[data-v-3285d0f2]{
    border-radius: 16px 16px 0 0;
}
.itemPost.postImage .infoPost[data-v-3285d0f2]{
    padding: 10px 30px;
}
.itemPost.postImage .infoProfile img[data-v-3285d0f2]{
    width: 30px;
}
.btn-post-event[data-v-3285d0f2] {
    display: flex;
    justify-content: flex-end;
}
.modalRepostPost .modal-title[data-v-3285d0f2] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--t-blue);
    text-align: left;
}
.modalRepostPost .textReport[data-v-3285d0f2] {
    color:  var(--t-blue);
    font-family: 'Inter';
    font-weight: 700;
    line-height: 24px;
    font-size: 18px;
    text-align: left;
}
.modalRepostPost .textReport p[data-v-3285d0f2]{
    color:  var(--t-blue);
    font-family: 'Inter';
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    text-align: left;
}
.optionsReport[data-v-3285d0f2]{
    display: flex;
    padding: 23px 0 0 0;
}
.optionsReport .option-status[data-v-3285d0f2]{
    margin-right: 50px;
}
.buttonClose[data-v-3285d0f2]{
    background: #FFFFFF;
    border: 1px solid #7400F9;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 14px 20px;
    width: 150px;
    color: #7400F9;
    cursor: pointer;
}
.buttonSend[data-v-3285d0f2]{
    background: #7400F9;
    border: 1px solid #7400F9;
    border-radius: 12px;
    padding: 14px 20px;
    width: 150px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
}
.emailOfficerAdd[data-v-3285d0f2]{
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: #A885D7;
}
.inputContainerOfficer[data-v-3285d0f2]{
    background: #28115A;
    border-radius: 3px;
    width: 100%;
    margin-top: 5px;
}
.inputContainerOfficer input[data-v-3285d0f2]{
    background: var(--t-blue);
    border-radius: 3px;
    color: white;
    padding: 7px 15px;
    width: 100%;
    border: 0;
}
.listConnections[data-v-3285d0f2]{
  width: 100%;
  overflow: auto; 
  overflow-x: hidden;
  height: 61vh;
}
.listConnections[data-v-3285d0f2]:hover::-webkit-scrollbar{
  display: block;
}
.listConnections[data-v-3285d0f2]::-webkit-scrollbar {
  width: 5px;
  display: none;
}
.listConnections[data-v-3285d0f2]::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
.listConnections[data-v-3285d0f2]::-webkit-scrollbar-thumb {
  background: var(--lavender); 
  border-radius: 10px;
}
.listConnections[data-v-3285d0f2]::-webkit-scrollbar-thumb:hover {
  background: var(--light-brown);
}