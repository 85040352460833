.TwoColumns__wrapper[data-v-90ee1ace] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
.TwoColumns__column[data-v-90ee1ace] {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
}