.fontawesome5pro-regular-normal-lavender-20px {
  color: var(--lavender);
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.club-view-navbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}

.section-label {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
}

.club-view-row {
  border-bottom: 2px solid transparent;
  width: 100%;
  background-color: var(--t-blue);
}

.club-view-row-selected {
  background-color: transparent;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.club-view-row-selected .section-label{
  color: var(--t-blue)
}

#left-rad {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
}

#left-rad-down {
  border-top-left-radius: none;
  border-bottom-left-radius: 8px;
  overflow: hidden;
}

#left-rad-up {
  border-top-left-radius: 8px;
  border-bottom-left-radius: none;
  overflow: hidden;
}

#right-rad {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

#right-rad-down {
  border-top-right-radius: none;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

#right-rad-up {
  border-top-right-radius: 8px;
  border-bottom-right-radius: none;
  overflow: hidden;
}

.tabResources {
  position: relative;
  display: flex;
  justify-content: center;
}

.bubbleComingSoon {
  position: absolute;
  background-color: var(--black);
  padding: 10px 20px;
  border-radius: 9px;
  color: white;
  font-family: 'Inter';
  top: 35px;
  z-index: 1;
  display: none;
  width: 150px;
}

.bubbleComingSoon::before {
  content: '';
  background-color: #a885d700;
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid var(--black);
  top: -20px;
  left: 43%;
}

.bubblePostEnable {
  position: absolute;
    background-color: var(--t-blue);
    padding: 10px 20px;
    border-radius: 9px;
    color: white;
    font-family: 'Inter';
    top: -18px;
    z-index: 1;
    display: none;
    width: 200px;
    left: 60px;
}

.bubblePostEnable::before {
  content: '';
    background-color: #a885d700;
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-right: 10px solid var(--t-blue);
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid transparent;
    top: 17px;
    left: -19px;
}

.bubbleActive{
  display: block;
}

.title-club{
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--t-blue);
  font-size: var(--font-size-xxxl);
  text-align: left;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding:  20px 30px;
}