.container-members-club{
    width: 100%;
    padding: 0 30px;
    font-family: 'Inter';
}

.listMembersClub {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 15px;
}

.buttonSaveFriendRequest{
    color: var(--t-blue);
    font-family: var(--font-family-font_awesome_5_pro-regular);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
    width: 45px;
    height: 14px;
    padding: 10px;
    background-color: white;
    border: 1px solid var(--t-blue);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

.cardMember button{
    border: 0;
    background-color: transparent;
}

.count-members{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}

.count-members p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--t-blue);  
}

.membersClubHeader{
    margin: 20px 0;
}

.membersClubHeader .filterButton{
    border: 0;
    background-color: transparent;
    margin-left: 15px;
}

.buttonInviteMembers{
    position: absolute;
    top: 0;
    right: 0px;
    width: 157px;
    height: 44px;
    background: #590CF6;
    border-radius: 12px;
    border: 0;
    color: white;
    cursor: pointer;
}

.container-members-club input::-webkit-input-placeholder, .container-members-club textarea::-webkit-input-placeholder {
    color: white;
    font-family: var(--font-family-roboto_condensed);
    font-size: 14px;
}

.container-members-club input::placeholder,
.container-members-club textarea::placeholder {
    color: white;
    font-family: var(--font-family-roboto_condensed);
    font-size: 14px;
}