.containerPost[data-v-03d9dfda]{
    margin-top: 25px;
    width: 100%;
    background-color: #ff000000;
    border: 1px solid white;
    border-radius: 10px;
}
.containerPost textarea[data-v-03d9dfda]{
    width: 100%;
    height: 50px;
    background-color: #ff000000;
    border: 0;
    color: white;
    padding: 15px;
    resize: none;
    outline: none;
    font-family: 'Inter';
}
.btnsFiles[data-v-03d9dfda]{
    display: flex;
    justify-content: flex-end;
}
.btnsFiles button[data-v-03d9dfda] {
    background-color: transparent;
    border: 0;
    padding: 10px 20px;
}
.btnPost[data-v-03d9dfda]{
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
}
.btnPost select[data-v-03d9dfda]{
    width: 60%;
    background: #28115A;
    border-radius: 3px;
    color: white;
    padding: 0 15px;
}
.btnPost .savePost[data-v-03d9dfda]{
    width: 170px;
    height: 44px;
    background: #590CF6;
    border-radius: 12px;
    border: 0;
    color: white;
}
.itemPost[data-v-03d9dfda]{
    width: 100%;
    background: var(--t-off-white);
    border-radius: 16px;
    margin-bottom: 30px;
}
.itemPostOnlyText[data-v-03d9dfda]{
    width: 100%;
    background: #8e8998;
    border-radius: 16px;
    margin-bottom: 30px;
}
.titlePost[data-v-03d9dfda]{
    display: flex;
    flex-direction: column;
    padding: 30px;
    position: relative;
}

/*.btnBots{
    border: 0;
    background-color: transparent;
    position: absolute;
    top: 20px;
    right: 20px;
}*/
.textOnlyPost[data-v-03d9dfda]{
    background-color: #8e8998;
    text-align: center;
    font-size: 24px;
    border-radius: 4px;
    padding: 10px;
    line-height: normal;
    word-break: break-word;
}
.btnBots[data-v-03d9dfda]{
    border: 0;
    background-color: transparent;	
    position: absolute;	
    top: 10px;
    right: 20px;
    color: white;
}
.titlePost h2[data-v-03d9dfda]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #A885D7;
    text-align: left;
}
.infoEvent[data-v-03d9dfda]{
    display: flex;
    margin-top: 10px;
}
.infoDetail[data-v-03d9dfda] {
    display: flex;
    align-items: center;
    color: white;
    font-family: 'Inter';
    margin-right: 15px;
}
.infoDetail div[data-v-03d9dfda]{
    margin-right: 10px;
}
.filePost[data-v-03d9dfda]{
    position: relative;
    border-radius: 0px 0px 16px 16px !important;
    /* overflow: hidden; */
}
.filePost img[data-v-03d9dfda]{
    width: auto;
    border-radius: 0 !important;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.btnReactions[data-v-03d9dfda]{
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    right: 0;
    width: 60px;
    background: rgba(23, 3, 46, 0.42);
    border-radius: 24px 0px 0px 0px;
    padding: 15px 0;
}
.btnReactions-horizontal[data-v-03d9dfda]{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
button.emoji[data-v-03d9dfda], .comments[data-v-03d9dfda] {
    border: 0;
    background-color: transparent;
    color: white;
    margin: 10px 0;
}
.infoPost[data-v-03d9dfda]{
    width: 100%;
    flex-direction: column;
    padding: 20px 30px;
    text-align: left;
    position: relative;
    display: flex;
}
.postMixed .filePost[data-v-03d9dfda]{
    border-radius: 0 !important;
}

/*.infoPostHeader{
    width: 100%;
    flex-direction: column;
    padding: 20px 30px;
    text-align: left;
    position: relative;

    display: grid;
    grid-template-columns: 0fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}*/
.infoPostHeader[data-v-03d9dfda]{
    width: 100%;
    flex-direction: column;
    padding-top: 15px;
    padding-left: 25px;
    text-align: left;
    position: relative;
    display: grid;
    grid-template-columns: 0fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
.infoPostHeader.eventInfoPostHeader[data-v-03d9dfda] {
    padding-top: 0 !important;
    padding-left: 0 !important;
}
.infoProfile[data-v-03d9dfda]{
    display: flex;
    align-items: center;
    font-size: 18px;
    color: var(--t-blue);
    font-weight: 700;
    font-family: 'Inter';
    grid-area: 1 / 2 / 2 / 3;
}
.infoWhere[data-v-03d9dfda]{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    color: var(--t-blue);
    font-family: 'Inter';
    grid-area: 2 / 2 / 3 / 3;
}

/* .infoProfile img{
    width: 35px;
    border-radius: 50%;
    border: 2px solid #383838;
    margin-right: 12px;
} */
.textPost[data-v-03d9dfda]{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 18px;
    color: #FFFFFF;
    margin-top: 5px;
    word-break: break-all;
}
.star[data-v-03d9dfda]{
    position: absolute;
    top: 40px;
    right: 22px;
    border: 0;
    background-color: transparent;
}
textarea[data-v-03d9dfda]::-webkit-input-placeholder {
    color: white;
    font-family: 'Inter';
}
textarea[data-v-03d9dfda]::placeholder {
    color: white;
    font-family: 'Inter';
}
.listOptionEvents[data-v-03d9dfda]{
    position: absolute;
    z-index: 90;
    top: 55px;
    right: 15px;
    display: none;
    text-align: left;
    background: var(--t-blue);
    border-radius: 3px;
    color: white;
    height: auto;
    padding: 15px;
    font-family: 'Inter';
}
.listOptionEvents.active[data-v-03d9dfda],
.listOptionEvents.active .overlap[data-v-03d9dfda] {
    display: block;
}
.itemPost.postImage[data-v-03d9dfda]{
    position: relative;
}
.itemPostOnlyText.postImage[data-v-03d9dfda]{
    position: relative;
}
.itemPost.postImage .titlePost[data-v-03d9dfda]{
    /* background-image: linear-gradient(to bottom, #00000085, #5151517a, #00000021, #00bf7200, #a8eb1200); */
    /* position: absolute; */
    width: 100%;
    z-index: 1;
    padding: 0px 0px;
    margin-bottom: 10px;
    border-radius: 16px 16px 0px 0px;
}
.itemPost.postImage .filePost img[data-v-03d9dfda]{
    border-radius: 16px 16px 0 0;
}
.itemPost.postImage .infoPost[data-v-03d9dfda]{
    padding: 10px 30px;
}

/* .itemPost.postImage .infoProfile img{
    width: 30px;   
} */
.AllEmojis[data-v-03d9dfda]{
    display: none;
}
.iconReaction:hover + .AllEmojis[data-v-03d9dfda],
.AllEmojis[data-v-03d9dfda]:hover{
    display: block;
    position: absolute;
    display: flex;
    background-color: var(--t-blue);
    border-radius: 30px;
    padding: 0px 10px;
    z-index: 111;
    left: 0;
    top: 0;
}
.countReactions:hover .AllEmojis[data-v-03d9dfda]{
    display: block;
    position: absolute;
    display: flex;
    background-color: var(--t-blue);
    border-radius: 30px;
    padding: 0px 10px;
    z-index: 111;
    left: 0;
    top: 20px;
    right: auto;
}
.AllEmojis .emojiIcon[data-v-03d9dfda]{
    padding: 5px;
    cursor: pointer;
}
.AllEmojis .emojiIcon img[data-v-03d9dfda]{
    width: 30px;
    height: 30px;
}
.AllEmojis .emojiIcon:hover img[data-v-03d9dfda]{
    width: 35px;
    height: 35px;
}
.itemComment[data-v-03d9dfda]{
    display: flex;
}
.listComments[data-v-03d9dfda]{
    margin-top: 20px;
}
.textComment[data-v-03d9dfda]{
    color: white;
    text-align: left;
    padding: 10px;
    background-color: var(--t-blue);
    border-radius: 10px;
    margin-left: 10px;
    font-family: 'Inter';   
    margin-bottom: 10px;
    width: 100%;
}
.iconReaction[data-v-03d9dfda]{
    margin-right: 5px;
    padding-right: 5px;
}
.infoComment[data-v-03d9dfda]{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
}
.textComment img[data-v-03d9dfda]{
    width: inherit;
    border-radius: 12px;
    margin: 10px 0;
}
.imgProfile img[data-v-03d9dfda]{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
button.emoji[data-v-03d9dfda], .comments[data-v-03d9dfda] {
    border: 0;
    background-color: transparent;
    color: white;
    margin: 10px 0;
}
.commentsText[data-v-03d9dfda] {
    border: 0;
    background-color: transparent;
    color: white;
    font-size: 18px;
    margin-top: 2px;
    margin-left: 0px;
    font-family: 'Inter';
    padding-left: 10px;
}
.btnGrid2 .fontawesome5pro-solid-normal-white-20px[data-v-03d9dfda]{
    margin-right: 5px;
}
.itemPostSaved[data-v-03d9dfda] {
    width: 100%;
    margin: 15px 0;
    display: flex;
    align-items: center;
}
.itemPostSaved img[data-v-03d9dfda] {
    width: 60px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-right: 20px;
}
.itemPostInformation[data-v-03d9dfda]{
    text-align: left;
    width: 100%;
}
.nameProfile[data-v-03d9dfda] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #A885D7;
    margin: 5px 0;
}
.description[data-v-03d9dfda]{
    color: white;
    font-family: 'Inter';
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 150px);
    display: block;
}
.cardPostSaved[data-v-03d9dfda]{
    display: flex;
    width: 100%;
    padding: 5px 10px;
    cursor: pointer;
    align-items: center;
}
.actionsPost[data-v-03d9dfda]{
    width: 30px;
}
.itemPostSaved[data-v-03d9dfda]:hover{
    background-color: var(--t-blue);
    color: var(--white);
}
.btnBots[data-v-03d9dfda]{
    cursor: pointer;
    z-index: 1;
}
.titleOptions[data-v-03d9dfda]{
    display: flex;
    align-items: center;
    font-weight: 700;
    color: white;
    font-size: 24px;
    margin-bottom: 20px;
}
.titleOptions .iconOptions[data-v-03d9dfda]{
    font-size: 11px;
    color: var(--white);
    margin-right: 15px;
}
.iconOptions[data-v-03d9dfda]{
    font-size: 14px;
    color: #fff;
    margin-right: 15px;
    margin-left: 0px;
}
.listOptionsPost li[data-v-03d9dfda]{
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.aha-logo[data-v-03d9dfda] {
    width: 25px;
    height: 25px;
    margin-left: -5px;
    margin-right: 10px;
}
.aha-logo-post[data-v-03d9dfda] {
    width: 40px !important;
    height: 40px !important;
    margin-left: 10px;
}
.aha-logo-text-post[data-v-03d9dfda] {
    width: 38px !important;
    height: 38px !important;
    margin-left: 10px;
}
.aha-logo-text-post-event[data-v-03d9dfda] {
    width: 38px !important;
    height: 38px !important;
    margin-left: 10px;
    margin-right: 10px;
}
.countEmoji[data-v-03d9dfda]{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
    position: relative
}
.listOptionsPost li[data-v-03d9dfda]{
    cursor: pointer;
}
.btnVideos[data-v-03d9dfda]{
    bottom: 80px;
}
.VideoInput_video[data-v-03d9dfda]{
    height: 100%;
    border-radius: 16px;
}
.VideoInput_input[data-v-03d9dfda]{
    display: none;
}
.contImage[data-v-03d9dfda] {
    width: 35px;
    border-radius: 50%;
    border: 2px solid #383838;
    margin-right: 12px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    grid-area: 1 / 1 / 3 / 2;
}
.contImage img[data-v-03d9dfda]{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.contImages[data-v-03d9dfda]{
    width: 100%;
    /*height: 400px;*/
    display: block;
    overflow: hidden;
    justify-content: center;
}
.contImages img[data-v-03d9dfda]{
    object-fit: cover;
    height: 400px;
    width: 100%;
}
.oneImages .column .contImages img[data-v-03d9dfda]{
    /* width: auto; */
    height: 500px;
}
.twoImages .column[data-v-03d9dfda] {
    width: 50%;
}
.twoImages[data-v-03d9dfda]{
    display: flex;
    height: 400px;
    overflow: hidden;
    width: 100%;
    justify-content: center;
}
.oneImages[data-v-03d9dfda]{
    display: flex;
    overflow: hidden;
    width: 100%;
    justify-content: center;
}
.oneImages .contImages[data-v-03d9dfda]{
    /* height: auto; */
}
.oneImages .column[data-v-03d9dfda]{
    display: contents;
}
.threeImages[data-v-03d9dfda]{
    display: flex;
    height: 400px;
    overflow: hidden;
    width: 100%;
    justify-content: center;
}
.threeImages .column[data-v-03d9dfda] {
    width: 60%;
    flex-direction: column;
    display: flex;
}
.threeImages .column.col2[data-v-03d9dfda] {
    width: 40%;
    flex-direction: column;
    display: flex;
}
.column.col2 img[data-v-03d9dfda]{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.moreImages[data-v-03d9dfda]{
    display: flex;
    height: 400px;
    overflow: hidden;
    width: 100%;
    justify-content: center;
}
.moreImages .column[data-v-03d9dfda]{
    width: 60%;
    display: flex;
    flex-direction: column;
}
.moreImages .column.col2[data-v-03d9dfda]{
    width: 40%;
    display: flex;
    flex-direction: column;
}
.moreImages .column img[data-v-03d9dfda]{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.lastImage[data-v-03d9dfda]{
   position: relative;
}
.lastImage p[data-v-03d9dfda]{
    display: flex;
    background-color: #00000073;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 32px;
}
.btnParentGrid[data-v-03d9dfda] {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-row-gap: 0px;
    justify-items: flex-end;
    align-content: center;
    align-items: center;
}
.btnParentGrid.event-btnParentGrid[data-v-03d9dfda] {
    margin-right: 25px !important;
}
.btnGrid1[data-v-03d9dfda] { 
    grid-area: 1 / 1 / 2 / 2; 
    display: flex;
    margin-right: 10px;
}
.btnGrid2[data-v-03d9dfda] { 
    grid-area: 1 / 2 / 2 / 3; 
    display: flex;
}
.btnGrid3[data-v-03d9dfda] { 
    grid-area: 1 / 3 / 2 / 4;
    cursor: pointer;
}
.btnParentGridOnly2[data-v-03d9dfda] {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-row-gap: 0px;
    justify-items: flex-end;
    align-content: center;
    align-items: center;
    margin-right: 30px;
}
.btnGrid1Only2[data-v-03d9dfda] { 
    grid-area: 1 / 1 / 2 / 2; 
    display: flex;
    margin-right: 10px;
}
.btnGrid2Only2[data-v-03d9dfda] { 
    grid-area: 1 / 2 / 2 / 3; 
    display: flex;
}
.modalRepostPost .modal-title[data-v-03d9dfda] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--t-blue);
    text-align: left;
}
.modalRepostPost .textReport[data-v-03d9dfda] {
    color: var(--t-blue);
    font-family: 'Inter';
    font-weight: 700;
    line-height: 24px;
    font-size: 18px;
    text-align: left;
}
.modalRepostPost .textReport p[data-v-03d9dfda]{
    color:  var(--t-blue);
    font-family: 'Inter';
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    text-align: left;
}
.optionsReport[data-v-03d9dfda]{
    display: flex;
    padding: 23px 0 0 0;
}
.optionsReport .option-status[data-v-03d9dfda]{
    margin-right: 50px;
}
.buttonClose[data-v-03d9dfda]{
    background: #FFFFFF;
    border: 1px solid var(--t-blue);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 14px 20px;
    width: 150px;
    color: var(--t-blue);
    cursor: pointer;
}
.buttonSend[data-v-03d9dfda]{
    background: var(--t-blue);
    border: 1px solid var(--t-blue);
    border-radius: 12px;
    padding: 14px 20px;
    width: 150px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
}
.descriptionPost[data-v-03d9dfda]{
    color: white;
    font-family: 'Inter';
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 150px);
    display: block;
    margin-top: 5px;
}
.nameProfilePost[data-v-03d9dfda]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
    color: var(--t-blue);
    margin: 5px 0;
}
.dots[data-v-03d9dfda]{
    cursor: pointer;
}
.event-cover-img[data-v-03d9dfda] {
    width: 100% !important;
}
.event-textPost[data-v-03d9dfda] {
    margin-top: 15px;
    margin-bottom: 15px !important;
}
.selectOptionsReport[data-v-03d9dfda]{
    width: 100%;
    background-color: #2C2C2C;
    padding: 10px;
    border-radius: 3px;
    margin-top: 12px;
}
.selectOptionsReport select[data-v-03d9dfda]{
    color: white;
}
.postBlur[data-v-03d9dfda]{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center; 
    font-size: 26px;
    color: var(--t-blue);
    font-family: 'Inter';
    font-weight: 700;
    flex-direction: column;
}
.postFlag[data-v-03d9dfda]{
    overflow: hidden;
}
.postFlag .filePost[data-v-03d9dfda], .postFlag .infoPost[data-v-03d9dfda]{
    -webkit-filter: blur(40px) grayscale(50%);
            filter: blur(40px) grayscale(50%);
}
.postFlag .btnBots[data-v-03d9dfda]{
    display: none;
}
.pointer[data-v-03d9dfda]{
    cursor: pointer;
}
.containerComments[data-v-03d9dfda]{
    width: 25%;
    position: fixed;
    top: 80px;
    right: -25%;
    z-index: 2;
    height: 100vh;
    transition: all 500ms linear;
  background: var(--t-off-white);
    background-size: cover;
}
.commentsActive[data-v-03d9dfda]{
    right: 0;
}
.profileInfo[data-v-03d9dfda]{
    display: flex;
}
.contentImage[data-v-03d9dfda]{
    width: 80%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contentImage img[data-v-03d9dfda]{
    width: 100%;
}
.profileInfo img[data-v-03d9dfda]{
    width: 30px;
    height: 30px;
    border-radius: 50%;   
    margin-right: 15px;
}
.nameProfile[data-v-03d9dfda]{
    display: flex;
    flex-direction: column;
    color: var(--t-blue);
    font-family: 'Inter';
    text-align: initial;
    line-height: 18px;    
    width: 100%;
}
.nameProfile span[data-v-03d9dfda]{
    font-weight: 700;
}
.nameProfile .time[data-v-03d9dfda]{
    font-size: 14px;
}
.btnEllipsis[data-v-03d9dfda]{
    background-color: transparent;
    border: 0;
}
.postDescription.ellps[data-v-03d9dfda]{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.postDescription[data-v-03d9dfda]{
    color: var(--t-blue);
    font-family: 'Inter';
    margin-top: 10px;
    text-align: left;
}
.countActivity[data-v-03d9dfda]{
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    border-bottom: 1px solid #949494;
    padding-bottom: 15px;
}
.countReactions[data-v-03d9dfda]{
    display: flex;
    align-items: center;
    color: var(--t-blue);
    font-family: 'Inter';
    position: relative;
}
.emojiIcon img[data-v-03d9dfda]{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.countComments[data-v-03d9dfda]{
    color: var(--t-blue);
    font-family: 'Inter';
}
.allComments[data-v-03d9dfda] {
    display: flex;
    color: #a885d7;
    font-weight: 700;
    font-family: 'Inter';
    align-items: center;
    justify-content: flex-end;
}
.allComments p[data-v-03d9dfda]{
    text-decoration: underline;
}
.inputComment[data-v-03d9dfda]{
    display: flex;
    margin: 10px 0;
    bottom: 70px;
    border-top: 1px solid #949494;
    padding: 15px;
    position: absolute;
    background-color: var(--t-blue);
    width: 100%;
    align-items: center;
}
.containerListComments[data-v-03d9dfda]{
    overflow: auto;
    height: calc(80vh - 200px);
}
.containerListComments[data-v-03d9dfda]::-webkit-scrollbar,
.allCategoriesAC[data-v-03d9dfda]::-webkit-scrollbar {
    display: none;
    width: 5px;
}
.containerListComments[data-v-03d9dfda]:hover::-webkit-scrollbar{
    display: block;
}
.containerListComments[data-v-03d9dfda]::-webkit-scrollbar-thumb{
    background: var(--lavender);
    border-radius: 4px;
}
.containerListComments[data-v-03d9dfda]::-webkit-scrollbar-thumb:active {
    /* background-color: red; */
}
.containerListComments[data-v-03d9dfda]::-webkit-scrollbar-thumb:hover{
    background: var(--lavender);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
.containerListComments[data-v-03d9dfda]::-webkit-scrollbar-track{
    background: transparent;
    border-radius: 4px;
}
.containerListComments[data-v-03d9dfda]::-webkit-scrollbar-track:hover,
.containerListComments[data-v-03d9dfda]::-webkit-scrollbar-track:active {
    background: transparent;
}
.iconPaperclip[data-v-03d9dfda]{
    margin: 5px 10px;
}
.inputComment input[data-v-03d9dfda] {
    width: 100%;
    border: 1px solid gray;
    background-color: #ff000000;
    padding: 10px;
    color: white;
    border-radius: 8px;
}
[data-v-03d9dfda]::-webkit-input-placeholder{
    color: white;
    font-family: 'Inter';
}
[data-v-03d9dfda]::placeholder{
    color: white;
    font-family: 'Inter';
}
.listComments[data-v-03d9dfda]{
    margin-top: 20px;
}
.btnReactions[data-v-03d9dfda]{
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    right: 0;
    width: 60px;
    background: rgba(23, 3, 46, 0.42);
    border-radius: 24px 0px 0px 0px;
    padding: 5px 0;
}
button.emoji[data-v-03d9dfda], .comments[data-v-03d9dfda] {
    border: 0;
    background-color: transparent;
    color: white;
    margin: 10px 0;
}
.contentOnlyText[data-v-03d9dfda]{
    height: 100vh;
    font-size: 31px;
    padding: 10px 40px;
    background: rgb(119,163,210);
    background: linear-gradient(155deg, rgba(119,163,210,1) 0%, rgba(117,127,182,1) 29%, rgba(116,102,162,1) 61%, rgba(41,95,140,1) 100%);
}
.btnArrow[data-v-03d9dfda] {
    width: 40px;
    height: 40px;
    background-color: #3131311c;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    padding: 0 15px;
    position: absolute;
    cursor: pointer;
}
.btnArrow[data-v-03d9dfda]:hover{
    background-color: #3131313f;
}
.dtnRight[data-v-03d9dfda] {
    right: 0;
}
.dtnLeft[data-v-03d9dfda]{
    left: 0;
}
.btnReactions-text[data-v-03d9dfda]{
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    right: 0;
    width: 60px;
    background: rgba(23, 3, 46, 0.42);
    border-radius: 24px 0px 0px 0px;
}