.listMembersClub {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.listMembersClub > div {
    width: 45%;
    display: flex;
    padding: 15px;
    align-items: center;
    position: relative;
    gap: 5px;
}

/* .listMembersClub img {
    width: 50px;
    border-radius: 50%;
} */

.listMembersClub p {
    color: var(--t-blue);
    padding-left: 15px;
    font-family: var(--font-family-roboto_condensed);
}

.listMembersClub button {
    position: absolute;
    top: 30px;
    right: 0;
}

.cardMember button{
    border: 0;
    background-color: transparent;
}

.cursor{
    cursor: pointer;
}

.count-members{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}

.count-members p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--t-blue);  
}

.membersClubHeader{
    margin: 20px 0;
}

.membersClubHeader .filterButton{
    border: 0;
    background-color: transparent;
    margin-left: 15px;
}

.buttonInviteMembers{
    position: absolute;
    top: 0;
    right: 0px;
    width: 157px;
    height: 44px;
    background: #590CF6;
    border-radius: 12px;
    border: 0;
    color: white;
    cursor: pointer;
}

.count-members{
    display: flex;
}

.containerSearchM{
    width: calc(100%);
    display: flex;
    align-items: center;
}

.inputSearchM{
    background: var(--t-blue);
    border: 0;
    margin-left: 10px;
    border-radius: 5px;
    height: 40px;
    color: white;
    padding: 0 15px;
    display: flex;
    align-items: center;   
}

.inputSearchMI{
    background: #28115A;
    border: 0;
    width: 100%;
    height: 40px;
    color: white;
    padding: 0 20px;
}

.containerInputSearchFollowers{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}