body {
  font-family: 'Inter';
  /* background: var(--light-brown); */
  text-align: center;
}

.carousel.carousel-slider {
  margin: 0px auto;
  overflow: visible;
}

.carousel .slide {
  color: white;
}

.carousel .slide-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.carousel .slide img {
  margin: 30px;
  width: 35%;
  height: 200px;
  object-fit: contain;
}
.carousel .slide iframe {
  margin: 30px;
  width: 35%;
}
.carousel .text-container {
  text-align: left;
}

.carousel .text-container h2 {
  margin-top: 0px;
}

.carousel .text-container p:last-child {
  margin-bottom: 0px;
}

.carousel .control-dots {
  bottom: 40px;
}

.carousel .thumbs-wrapper {
  margin: 30px;
}

.text-container{
  width: 100%;
  background: var(--t-blue);
  border-radius: 12px;
  padding: 25px;
  font-family: 'Inter';
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.text-container h2 {
  color: white;
  font-weight: 700;
  font-family: 'Inter';
  margin-bottom: 20px;
  text-align: center;
}

.text-container table tr td:first-child {
  font-weight: 700;
  padding: 4px 20px 4px 0;
}

.carousel.carousel-slider .control-arrow:hover{
  background-color: transparent;
  color: var(--t-blue);
}

.carousel .control-prev.control-arrow:before {
  content: '\f053';
  font-family: 'Font Awesome 5 Pro-Regular';
  color: #A885D7;
  border: 0 !important;
}

.carousel .control-next.control-arrow:before {
  content: '\f054';
  font-family: 'Font Awesome 5 Pro-Regular';
  color: #A885D7;
  border: 0 !important;
}