.tui-image-editor-container .tui-image-editor-help-menu.top {
    display: none;
}

.tui-image-editor-download-btn,
.tui-image-editor-header-logo,
.tui-image-editor-header-buttons {
    display: none !important;
}

.modalImageEditor .modal-dialog {
    width: 900px;
}

.modalImageEditor .modal-content,
.modalImageEditor .modal-body {
    padding: 0px;
}

.modalImageEditor .modal-body {
    height: 400px;
}

.tui-image-editor-container {
    background-color: #a3111100 !important;
    height: 100% !important;
    width: calc(100% - 50px) !important;
    margin: 0 auto !important;
    display: block;
}

.tui-image-editor-container .tui-image-editor-controls {
    border-radius: 0 0 10px 10px;
    background-color: #242424 !important;
}

.iconImageCenter {
    width: 300px;
    height: 300px;
    background: rgb(39 48 59 / 80%);
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.iconCamera {
    font-size: 65px;
    padding: 30px;
    background-color: var(--t-blue);
    border-radius: 50%;
}

.iconImageCenter p {
    padding: 25px 0;
    font-size: 16px;
    color: var(--white);
    font-weight: 700;
    font-family: 'Inter';
    text-decoration: underline;
}

.modalImageEditor .modal-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--t-blue);
}

.tui-image-editor-main-container {
    background-color: #383838 !important;
}

#filePickerModal {
    display: none;
}

.filePickerModal {
    display: flex;
    justify-content: center;
    padding: 0 100px;
}

.buttonSendEvent {
    width: 46%;
    height: 50px;
    background: var(--t-blue);
    border-radius: 12px;
    border: 0;
    color: white;
    margin: 0 auto;
    cursor: pointer;
}

.containersButton {
    width: 250px;
    height: 90%;
    background-color: #242424;
    margin: 20px 0 10px 0;
    display: block;
    border-radius: 15px;
}

.tui-image-editor-submenu-item {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-around; */
}

.tui-image-editor-container.bottom .tui-image-editor-submenu>div {
    padding-bottom: 0 !important;
    padding-top: 10px !important;
}

.tui-image-editor-main {
    display: flex;
    justify-content: center;
}


.tui-image-editor-container .tui-image-editor-main {
    top: 0 !important
}

.tui-image-editor {
    top: -20px !important;
}


.tui-image-editor-submenu-style {
    border-radius: 15px 0px 0px 15px
}

.tui-image-editor-container .tui-image-editor-controls {
    border-radius: 0px 15px 15px 0px
}

.containersButtons {
    width: 50px;
    margin: 0px 40px 0 10px;
    display: flex;
    opacity: 0.1;
    flex-direction: column;
    justify-content: space-between;
    height: 400px;
}

.iconImageCenterMini {
    width: 40px;
    height: 40px;
    background: rgb(39 48 59 / 80%);
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: -50px;
    right: 10px;
    cursor: pointer;
}

.iconImageCenterMini .iconCamera {
    font-size: 18px;
    padding: 0px;
    background-color: #28115A;
    border-radius: 50%;
}

.tui-image-editor-submenu-item span,
.tui-image-editor-submenu-item label {
    font-family: 'Inter' !important;
}

.modalImageEditor.menuTopHidden .tui-image-editor-help-menu {
    display: none;
}

.modalWPreview .modal-dialog {
    width: 800px !important;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item li.line {
    display: inline-block;
    vertical-align: top;
    background-color: transparent;
    min-width: auto;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-button.line {
    position: relative !important;
    cursor: pointer !important;
    display: inline-block !important;
    font-weight: normal !important;
    font-size: 11px !important;
    margin: 0 9px 0 9px !important;
    background-color: transparent;
    height: auto !important;
    min-width: auto;
}

.tui-image-editor-container .color-picker-control .tui-colorpicker-palette-button {
    width: 10px;
    height: 10px;
    vertical-align: inherit;
}

.tui-image-editor-container .tui-colorpicker-palette-preview {
    font-size: 0;
}

.modalWPreview .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tui-image-editor-submenu,
.tui-image-editor-container .tui-image-editor-help-menu.left,
.tui-image-editor-controls {
    display: none !important;
}

.tui-image-editor {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    left: 0 !important;
}

.tui-image-editor-container.right .tui-image-editor-main-container {
    right: 0 !important;
    width: 100% !important;
}

.controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 40px;
    display: flex;
    align-items: center;
}

.slider {
    padding: 22px 0px;
}

.zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    background: #8c8e9b;
    width: 100%;
}

.zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid var(--t-blue);
    background: var(--t-blue);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    -moz-transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid var(--t-blue);
    background: var(--t-blue);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    -webkit-transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

@media (max-width: 480px) {

    .modalWPreview .modal-dialog,
    .modalImageEditor .modal-dialog {
        width: 100% !important;
    }

    .textStep {
        margin: 50px 25px 0px 0 !important;
        width: 200px;
    }
    .HeaderSetupStudent .texts{
        width: calc(100% - 100px);
    }

    .HeaderSetupStudent .texts h3,
    .HeaderSetupStudent .texts h4{
        text-align: left;
    }

    .cardsClub::-webkit-scrollbar-thumb{
        background: #590df6;
    }
    .cardsClub::-webkit-scrollbar-track{
        background: transparent !important;
    }
    .reviewProfile, .reviewInterests{
        width: 100% !important;
    }
    .ClubReview{
        width: 170px !important;
    }
    .listClubsSelected{
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
    }
}