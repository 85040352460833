@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.css');
.navegatorStep[data-v-9bae4d96]{
    width: 100%;
    margin: 0 auto;
    position: fixed;
    background: linear-gradient(180deg, #34266C 0%, #332367 77.6%, rgba(50, 35, 102, 0) 100%);
    z-index: 9;
}
.container-setup-school[data-v-9bae4d96]{
    padding-bottom: 100px;
    background: var(--simple-brown-light);
    background-attachment: fixed;
    min-height: 100vh;
}
.button-save[data-v-9bae4d96]{
    padding: 14px 20px;
    width: 190px;
    background: #590CF6;
    border-radius: 12px;
    margin: 0px 14px;
    border: 0;
    color: white;
    cursor: pointer;
}
.container-setup-school .steps[data-v-9bae4d96] {
    padding: 30px 0;
    color: #A885D7;
    width: calc(100% - 350px);
    font-family: 'Inter';
    margin: 0 auto;
    display: flex;
    font-size: 14px;
}
.allSteps[data-v-9bae4d96] {
    width: calc(100% - 90px);
    display: flex;
}
.allSteps .step[data-v-9bae4d96] {
    width: 25%;
    display: flex;
    flex-flow: column;
    align-items: baseline;
}
.step[data-v-9bae4d96]{
    position: relative;
}
.activeStep[data-v-9bae4d96]::before{
    width: calc(100% - 100px);
    height: 5px;
    background-color: #f1e0ff;
    display: block;
    content: '';
    position: absolute;
    border-radius: 12px;
    right: 0px;
    top: 28px;
}
.imageSchool[data-v-9bae4d96]{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.circle-setup[data-v-9bae4d96] {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid #a885d7;
    position: relative;
    cursor: pointer;
    margin: 10px auto;
}
.stepIcon[data-v-9bae4d96]{
    width: 100px;
    display: block;
}
.stepTitle[data-v-9bae4d96] {
    display: block;
    padding-top: 6px;
}
.active > .stepTitle[data-v-9bae4d96] {
    color: white;
}
.activeStep > .stepTitle[data-v-9bae4d96] {
    color: white;
}
.active .circle-setup[data-v-9bae4d96],
.activeStep .circle-setup[data-v-9bae4d96] {
    border: 4px solid #f1e0ff;
    background-color: #f1e0ff;
}
.active .circle-setup[data-v-9bae4d96]::before {
    content: "";
    width: 36px;
    height: 36px;
    display: block;
    border: 4px solid #f1e0ff;
    border-radius: 50%;
    position: absolute;
    top: -11px;
    right: -11px;
}
.icon-camera[data-v-9bae4d96]{
    font-size: 75px;
    padding: 75px 0;
}
.inputs[data-v-9bae4d96]{
    margin: 20px 0;
    width: 70%;
}
.inputs .row .title-input[data-v-9bae4d96] {
    width: 175px;
    display: inline-block;
    margin: 10px 0;
    padding: 0 25px;
    font-size: 16px;
    font-weight: 700;
}
.containers-input[data-v-9bae4d96] {
    width: calc(100% - 200px);
    margin-bottom: 20px;
}
.inputs .row input[type="text"][data-v-9bae4d96] {
    width: 100%;
    height: 40px;
    background: #28115A;
    border-radius: 3px;
    border: 0;
    padding: 0 10px;
    color: white;
    font-size: 16px;
}
.grades[data-v-9bae4d96]{
    display: flex;
    justify-content: space-evenly;
    width: calc(100% - 200px);
}
.row[data-v-9bae4d96]{
    display: flex;
}
.grades select[data-v-9bae4d96]{
    background-color: #28115a;
    color: white;
    padding: 0 10px;
    border-radius: 3px;
}
.row .option[data-v-9bae4d96]{
    justify-content: flex-start;
    padding: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    margin-top: 10px;
}
.label-to[data-v-9bae4d96]{
    width: 100px;
    display: inline-block;
    margin: 10px 0;
    padding: 0 25px;
    font-size: 16px;
    font-weight: 700;
}

/* Welcome */
.container-welcome[data-v-9bae4d96] {
    width: calc(100% - 350px);
    font-family: 'Inter';
    margin: 0 auto;
    color: white;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding-top: 250px;
    min-height: 700px;
}
.container-welcome h1[data-v-9bae4d96]{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
}
.container-welcome h2[data-v-9bae4d96]{
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 60px;
}
.container-welcome span[data-v-9bae4d96]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
}
.welcome-text span[data-v-9bae4d96]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    margin-top: 12px;
    display: block;
}
.button-start[data-v-9bae4d96]{
    padding: 14px 20px;
    width: 200px;
    background: #590CF6;
    border-radius: 12px;
    margin: 0px 14px;
    border: 0;
    color: white;
    cursor: pointer;
}

/* Communities */
.container-communities[data-v-9bae4d96] {
    width: calc(100% - 350px);
    font-family: 'Inter';
    margin: 0 auto;
    color: white;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding-top: 130px;
    padding-bottom: 40px;
}
.container-communities h1[data-v-9bae4d96]{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
}
.container-communities h2[data-v-9bae4d96]{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}
.container-communities span[data-v-9bae4d96]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}
.container-communities .button-skip[data-v-9bae4d96]{
    padding: 14px 20px;
    width: 190px;
    background: white;
    border-radius: 12px;
    margin: 0px 14px;
    border: 0;
    color: #590CF6;
    cursor: pointer;
}
.container-communities .button-save[data-v-9bae4d96]{
    padding: 14px 20px;
    width: 190px;
    background: #590CF6;
    border-radius: 12px;
    margin: 0px 14px;
    border: 0;
    color: white;
    cursor: pointer;
}
.communities-text[data-v-9bae4d96]{
    width: 100%;
}
.buttonsCommunities[data-v-9bae4d96]{
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
}
.communities-cards[data-v-9bae4d96]{
    width: calc(100% - 350px);
    margin: 0px auto;
    display: flex;
    flex-wrap: wrap;
}
.card[data-v-9bae4d96] {
    width: 23%;
    background: #34266B;
    border-radius: 10px;
    margin: 10px;
    padding: 20px;
}
.card-header[data-v-9bae4d96] {
    display: flex;
    margin-bottom: 20px;
    position: relative;
}
.card-header .icon-user[data-v-9bae4d96] {
    font-size: 48px;
    width: 70px;
    text-align: left;
}
.card-header p[data-v-9bae4d96] {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 20px;
    color: white;
}
.staff-inputs .row[data-v-9bae4d96]{
    display: flex;
    align-items: baseline;
    margin-top: 6px;
    margin-bottom: 10px;
}
.staff-inputs label[data-v-9bae4d96] {
    width: 70px;
    text-align: left;
    color: white;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Inter';
}
.club-adv[data-v-9bae4d96] {
    min-height: 0 !important;
}
.club-adv input[data-v-9bae4d96] {
    background-color: #2c2c2c !important;
    /* margin-top: 20px;
    margin-bottom: 20px; */
}
.staff-inputs input[data-v-9bae4d96] {
    border: 0;
    padding: 10px;
    height: 40px;
    background: #28115A;
    border-radius: 3px;
    width: 100%;
    /* margin-bottom: 10px; */
    color: white;
}
.invites-count[data-v-9bae4d96] {
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: #a885d7;
    margin: 10px 0px;
    font-family: 'Roboto'
}
.add-advisor[data-v-9bae4d96] {
    display: flex;
    justify-content: center;
    color: #a885d7;
    margin-bottom: 25px;
    margin-top: 10px;
}
.add-advisor .icon-user[data-v-9bae4d96] {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #A885D7;
    margin-right: 12px;
}
.add-advisor .btnAddAdvisor[data-v-9bae4d96] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #A885D7;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    display: flex;
}
.add-advisor .btnAddAdvisor p[data-v-9bae4d96] {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}
.buttonSendInvites[data-v-9bae4d96]{
    background: #7400F9;
    border-radius: 12px;
    width: 100%;
    border: 0;
    padding: 14px 20px;
    font-size: 14px;
    color: white;
    cursor: pointer;
}
.staff-inputs[data-v-9bae4d96], .card-desc[data-v-9bae4d96]{
    min-height: 90px;
}
.card-desc[data-v-9bae4d96]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #F1E0FF;
    margin: 30px 0;
}
.card-adv-label[data-v-9bae4d96]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #F1E0FF;
    margin-top: 20px;
}
.icon-header[data-v-9bae4d96]{
    position: absolute;
    background-color: #34266b;
    bottom: 5px !important;
    left: 35px !important;
    font-size: 13px;
    padding: 0px 4px 4px 4px;
    color: white;
    font-weight: 700;
    font-family: 'Inter';
}
.icon-star[data-v-9bae4d96]{
    font-family: var(--font-family-font_awesome_5_pro-solid);
}
.card-header span[data-v-9bae4d96]{ 
    color: white;
    font-family: 'Inter';
    font-style: normal;
    font-size: 17px;
    line-height: 23px;
}
.card.card-desactive[data-v-9bae4d96] {
    -webkit-filter: grayscale(0.6);
            filter: grayscale(0.6);
    opacity: 0.6;
}

/* Clubs */
.container-clubs[data-v-9bae4d96] {
    width: calc(100% - 350px);
    font-family: 'Inter';
    margin: 0 auto;
    color: white;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding-top: 130px;
    padding-bottom: 40px;
}
.container-clubs h1[data-v-9bae4d96]{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
}
.container-clubs h2[data-v-9bae4d96]{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}
.container-clubs span[data-v-9bae4d96]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}
.container-clubs .button-skip[data-v-9bae4d96]{
    padding: 14px 20px;
    width: 190px;
    background: white;
    border-radius: 12px;
    margin: 0px;
    border: 0;
    color: #590CF6;
    cursor: pointer;
}
.container-clubs .button-save[data-v-9bae4d96]{
    padding: 14px 20px;
    width: 190px;
    background: #590CF6;
    border-radius: 12px;
    margin: 0px;
    border: 0;
    color: white;
    cursor: pointer;
}
.btn-center[data-v-9bae4d96] {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.listclubs[data-v-9bae4d96]{
    width: calc(100% - 350px);
    font-family: 'Inter';
    margin: 0 auto;
    color: white;
    display: flex;
    position: relative;
}
.listClubs[data-v-9bae4d96] {
    width: calc(100% - 310px);
    font-family: 'Inter';
    margin: 0 auto;
    color: white;
    background-color: #34266b;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    padding-right: 0;
}
.transparent[data-v-9bae4d96] {
    background-color: #00000000 !important;
}
.listCategories[data-v-9bae4d96]{
    width: 310px;
    font-family: 'Inter';
    margin: 0 auto;
    color: white;
    text-align: left;
    overflow: scroll;
}
.listCategories ul[data-v-9bae4d96]{
    position: fixed;
    overflow-y: scroll;
    width: 310px;
    max-height: 100%;
    padding-bottom: 550px;
}
.listCategories li[data-v-9bae4d96] {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    height: 40px;
    background: #250D44;
    border-radius: 100px;
    margin: 16px 0px;
    width: 95%;
    justify-content: space-between;
}
.listCategories ul > li[data-v-9bae4d96] {
    margin-top: 0px !important;
}
.listCategories ul > li.active[data-v-9bae4d96] {
    border-radius: 100px 0px 0px 100px;
    background-color: #34266b;
    width: 100%;
}
.listCategories ul > li.active .icon-chevron-right[data-v-9bae4d96] {
    display: none;
}
.listClubs > div[data-v-9bae4d96] {
    margin-right: auto;
    display: flex;
    align-items: center;
    padding: 10px;
    height: 44px;
    background: #250D44;
    border-radius: 8px;
    width: 30%;
    margin-bottom: 15px;
    color: #a885d7;
    justify-content: space-between;
}
.greyTemplate[data-v-9bae4d96] {
    color: white !important;
    background: #7400F9 !important;
    /*cursor: not-allowed !important;*/
}
.savedTemplate[data-v-9bae4d96]{
    background: #444444 !important;
    /*cursor: not-allowed !important;*/
}
.selectTemplate[data-v-9bae4d96] {
    cursor: pointer !important;
}
.icon-chevron-right[data-v-9bae4d96] {
    font-size: 16px;
}
.icon-plus[data-v-9bae4d96] {
    font-size: 17px;
    color: #a885d7;
}
.icon-times-2[data-v-9bae4d96]{
    font-size: 17px;
    color: #220E41;
    cursor: pointer;
}
.icon-times-white[data-v-9bae4d96]{
    font-size: 17px;
    color: #ffffff;
    cursor: pointer;
}

/* Teams */
.container-teams[data-v-9bae4d96] {
    width: calc(100% - 350px);
    font-family: 'Inter';
    margin: 0 auto;
    color: white;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding-top: 130px;
    padding-bottom: 40px;
}
.container-teams h1[data-v-9bae4d96]{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
}
.container-teams h2[data-v-9bae4d96]{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}
.container-teams span[data-v-9bae4d96]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}
.container-teams .button-skip[data-v-9bae4d96]{
    padding: 14px 20px;
    width: 190px;
    background: white;
    border-radius: 12px;
    margin: 0px;
    border: 0;
    color: #590CF6;
    cursor: pointer;
}
.btnGoToSetup[data-v-9bae4d96]{
    margin-left: 20px !important;
}
.listTeams[data-v-9bae4d96] {
    width: calc(100% - 350px);
    font-family: 'Inter';
    margin: 0 auto;
    color: white;
    background-color: #34266b;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 15px 0;
    position: relative;
}
.listTeams > div[data-v-9bae4d96] {
    display: flex;
    align-items: center;
    padding: 10px;
    height: 44px;
    background: #250D44;
    border-radius: 8px;
    width: 23%;
    margin-bottom: 15px;
    color: #a885d7;
    justify-content: space-between;
}


/* Teams */
.container-complete[data-v-9bae4d96] {
    width: calc(100% - 350px);
    font-family: 'Inter';
    margin: 0 auto;
    color: white;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding-top: 250px;
    padding-bottom: 40px;
}
.container-complete h1[data-v-9bae4d96]{
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
}
.container-complete h2[data-v-9bae4d96]{
    font-weight: 100;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    margin-top: 40px;
}
.container-complete span[data-v-9bae4d96]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-top: 40px;
    display: block;
}
.icon-spinner-third[data-v-9bae4d96]{
    margin-top: 20px;
    -webkit-animation:spin-data-v-9bae4d96 2s linear infinite;
    animation:spin-data-v-9bae4d96 2s linear infinite;
}
.lilSendSignUp[data-v-9bae4d96] {
    height: 15px !important;
    width: 15px !important;
    /* border: 2px solid; */
}
@-webkit-keyframes spin-data-v-9bae4d96 {
100% { -webkit-transform: rotate(360deg);
}
}
@keyframes spin-data-v-9bae4d96 {
100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg);
}
}
.complete-text[data-v-9bae4d96]{
    text-align: center;
    width: 100%;
}
.icon-times[data-v-9bae4d96]{
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
.text-invites[data-v-9bae4d96]{
    color: var(--t-blue);
    font-family: 'Inter';
    font-weight: 700;
    line-height: 24px;
    font-size: 16px;
}
.subtext-invites[data-v-9bae4d96]{
    color: var(--t-blue);
    font-family: 'Inter';
    font-weight: 400;
    line-height: 19px;
    font-size: 16px;
}
.modalInviteMemebers  a[data-v-9bae4d96]{
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: var(--t-blue);
    padding-top: 10px;
}
.container-drag[data-v-9bae4d96]{
    padding: 20px;
    background: var(--t-blue);
    border-radius: 16px;
    margin-top: 16px;
    color: white;
    text-align: center;
    font-family: 'Inter';
    line-height: 25px;
}
.container-drag span[data-v-9bae4d96]{
    display: block;
    color: var(--white);
    text-decoration: underline;
    cursor: pointer;
}
.modal-footer[data-v-9bae4d96]{
    display: flex;
    justify-content: space-between;
}
.buttonClose[data-v-9bae4d96]{
    background: #FFFFFF;
    border: 1px solid var(--t-blue);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 14px 20px;
    width: 150px;
    color: var(--t-blue);
    cursor: pointer;
}
.buttonSend[data-v-9bae4d96]{
    background: var(--t-blue);
    border: 1px solid var(--t-blue);
    border-radius: 12px;
    padding: 14px 20px;
    width: 150px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
}
button.inProcess[data-v-9bae4d96]{
    pointer-events: none;
    opacity: 0.8;
}
.buttonSendSpinner[data-v-9bae4d96]{
    height: 25px;
    border: 3px solid;
    width: 25px;
    display: block;
    border-radius: 50%;
    border-bottom-color: white;
    border-top-color: #0000ff00;
    border-right-color: #0000ff00;
    border-left-color: #0000ff00;  
    margin-top: -3px !important;
}
.modalInviteMemebers .row[data-v-9bae4d96]{
    align-items: baseline;
}
.modalInviteMemebers .row label[data-v-9bae4d96]{
    width: 100px;
    color: var(--t-blue);
    font-family: 'Inter';
}
.modalInviteMemebers .row input[data-v-9bae4d96]{
    width: calc(100% - 100px);
    height: 35px;
    margin-bottom: 14px;
    background-color: var(--t-blue);
    border: 0;
}
.option-chechbox[data-v-9bae4d96]{
    padding-right: 0px;
    display: flex;
    flex-direction: row;
    height: 20px;
    color: white;
    font-family: 'Inter';
}
.option-chechbox[data-v-9bae4d96]{
    padding-right: 50px;
    display: flex;
    flex-direction: row;
    height: 20px;
}
.option-chechbox label[data-v-9bae4d96] {
    padding-left: 15px;
    padding-top: 2px;
    color: var(--t-blue);
    font-family: var(--font-family-roboto_condensed);
}
.add-advisor-modal[data-v-9bae4d96]{
    display: flex;
    justify-content: flex-start;
    color: #a885d7;
    margin: 20px 0px;
    margin-top: 20px;
}
.add-advisor-modal .icon-user[data-v-9bae4d96] {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #A885D7;
    margin-right: 12px;
}
.add-advisor-modal p[data-v-9bae4d96] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #A885D7;
}
.card-desactive[data-v-9bae4d96]{
    pointer-events: none;
}
button.buttonSendInvites.inactive[data-v-9bae4d96] {
    pointer-events: none;
    background-color: #250D44;
    opacity: 0.5;
}

/* Modal Clubs */
.modalCreateClub .modal-title[data-v-9bae4d96]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #FFF;
}
.modalCreateClub .modal-body[data-v-9bae4d96] {
    position: relative;
    height: calc(100vh - 22vh);
    overflow: scroll;
}
.nav[data-v-9bae4d96]{
    display: flex;
    margin-bottom: 30px;
}
.nav div[data-v-9bae4d96] {
    background-color: #250D44;
    width: 33%;
    text-align: center;
    padding: 9px;
    color: white;
    font-family: 'Inter';
    font-size: 19px;
    margin: 0 2px;
    height: 35px;
    cursor: pointer;
}
.nav div[data-v-9bae4d96]:first-child {
    border-radius: 6px 0px 0px 6px;
}
.nav div[data-v-9bae4d96]:last-child {
    border-radius: 0px 6px 6px 0px;
}
.affiliated[data-v-9bae4d96] {
    /* margin: 10px 0; */
    width: 100%;
    background-color: #2C2C2C;
    padding: 10px;
    border-radius: 3px;
    color: white;
}
.selectAffiliated label[data-v-9bae4d96] {
    font-size: 16px;
    color: #A885D7;
    font-family: 'Inter';
    font-weight: bold;
    margin: 10px 0;
    display: block;
    text-align: left;
}
.affiliated select[data-v-9bae4d96]{
    font-size: 15px;
    color: #fff;
}
.modalCreateClub h2[data-v-9bae4d96]{
    color: #a985d7;
    font-size: 16px;
    margin: 10px 0;
    font-family: 'Inter';
    font-weight: 700;  
    text-align: left;
}
.modalCreateClub .subtitle[data-v-9bae4d96]{
    color: white;
    font-size: 14px;
    display: block;
    font-family: 'Inter';
    text-align: left;
}
.modalCreateClub .containers-input[data-v-9bae4d96] {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
}
.modalCreateClub .option-chechbox[data-v-9bae4d96] {
    display: flex;
    flex-direction: row;
    height: 20px;
    padding: 0;
}
.maximunMembers input[data-v-9bae4d96]{
    width: 100%;
    background-color: #2c2c2c;
    color: white;
    border: 0;
    height: 45px;
    padding: 13px;
    font-size: 16px;
}
.infoMembers > div[data-v-9bae4d96]{
    margin-bottom: 13px;
}
.addAdvisor label[data-v-9bae4d96]{ 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
}
.modalCreateClub .modal-footer button[data-v-9bae4d96] {
    margin: 0px 7px;
    width: 50%;
}
.addAdvisor[data-v-9bae4d96]{
    margin: 25px 0 0 0;
}
.modalCreateClub .add-advisor-modal[data-v-9bae4d96] {
    margin: 10px 0 0 0;
}
.nav .active[data-v-9bae4d96]{
    background-color: #34266B;
}
.textDescription[data-v-9bae4d96]{
    background-color: #2c2c2c;
    width: 100%;
    height: 160px;
    border: 0;
    border-radius: 8px;
}
.textSkills[data-v-9bae4d96]{
    background-color: #2c2c2c;
    width: 100%;
    height: 70px;
    border: 0;
    border-radius: 8px;
    color: white;
    padding: 10px;
}
.react-numeric-input[data-v-9bae4d96]{
    width: 100% !important;
}
.react-numeric-input input[data-v-9bae4d96]{
    border: 0 !important;
}
.react-numeric-input b[data-v-9bae4d96]{
    background-color: #121212 !important;
}
/*.react-numeric-input b:hover{
    background-color: purple !important;
}
 */
.react-numeric-input b[data-v-9bae4d96]:before{
    width: 60px;
    content: "\f077";
    font-family: 'Font Awesome 5 Pro-Regular';
    display: inline-block;
    font-weight: 900;
    position: absolute;
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    font-size: 20px;
}
.react-numeric-input b[data-v-9bae4d96]:last-child:before {
    content: "\f078";
}
.listCategories ul[data-v-9bae4d96]::-webkit-scrollbar {
    display: block;
    width: 5px;
}
.listCategories ul[data-v-9bae4d96]::-webkit-scrollbar-thumb{
background: var(--lavender);
border-radius: 4px;
}
.listCategories ul[data-v-9bae4d96]::-webkit-scrollbar-thumb:active {
/* background-color: red; */
}
.listCategories ul[data-v-9bae4d96]::-webkit-scrollbar-thumb:hover{
background: var(--lavender);
box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
.listCategories ul[data-v-9bae4d96]::-webkit-scrollbar-track,
.allCategoriesAC[data-v-9bae4d96]::-webkit-scrollbar-track {
background: transparent;
border-radius: 4px;
}
.listCategories ul[data-v-9bae4d96]::-webkit-scrollbar-track:hover, 
.listCategories ul[data-v-9bae4d96]::-webkit-scrollbar-track:active {
background: transparent;
}
[data-v-9bae4d96]::-webkit-scrollbar {
    /* display: none; */
}

/* .rc-time-picker-panel-select{
    width: 100px !important; ;
}

.rc-time-picker-panel{
    width: 210px !important; ;
}

.rc-time-picker-panel-input-wrap{
    display: none;
}

.rc-time-picker-panel-select{
    border: 0px solid #e9e9e9 !important;
    font-size: 20px !important; ;
    color: #fff;
    background-color: #121212;
    min-height: 50px;
}

.rc-time-picker-panel-inner{
    background-color: transparent !important; 
    box-shadow: none !important; 
    border: 0px solid #ccc !important; 
}

.rc-time-picker-panel-select li{
    padding: 9px 0 !important; ;
    text-align: center !important;
    height: auto !important;
}

.rc-time-picker-panel-combobox{
    display: flex;
    position: absolute;
    top: -58px;
}

li.rc-time-picker-panel-select-option-selected {
    background: #2c2c2c !important;
}

.rc-time-picker-panel-select {
    border-radius: 10px;
    margin-right: 10px;
}

.rc-time-picker-panel-select li:hover {
    background: #2c2c2cd4 !important;
} */
.content-time[data-v-9bae4d96]{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-family: 'Inter';
}
.meeting-location .containers-input input[data-v-9bae4d96]{
    width: 100%;
    height: 40px;
    background-color: #2c2c2c;
    border: 0;
    border-radius: 3px;
}
.rc-time-picker-input[data-v-9bae4d96]{
    padding: 12px 12px 12px 45px !important;
}
.switch[data-v-9bae4d96] {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}
.switch input[data-v-9bae4d96] { 
    opacity: 0;
    width: 0;
    height: 0;
}
.slider[data-v-9bae4d96] {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #878787;
    transition: .4s;
    padding: 0 !important;
}
.slider[data-v-9bae4d96]:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 5px;
    bottom: 5px;
    background-color: #383838;
    transition: .4s;
}
input:checked + .slider[data-v-9bae4d96] {
    background-color: #878787;
}

/* input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
} */
input:checked + .slider[data-v-9bae4d96]:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
    width: 51px;
    left: -22px;
    border-radius: 34px;
    background-color: #590CF6;
}

/* Rounded sliders */
.slider.round-chechbox[data-v-9bae4d96] {
    border-radius: 34px;
}
.slider.round-chechbox[data-v-9bae4d96]:before {
    border-radius: 50%;
}
.chechbox-input[data-v-9bae4d96]{
    display: flex;
    align-items: center;
}
.chechbox-input > span[data-v-9bae4d96]{
    color: white;
    font-family: 'Inter';
    margin-left: 15px;
    font-weight: bold;
}
.grades-team[data-v-9bae4d96]{
    background-color: #2c2c2c;
    padding: 2px 10px;
    border-radius: 11px;
}
.grades-team .content-input input[type="checkbox"] + i[data-v-9bae4d96]:before {
    content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' color='%23fff' data-v-86e907fa=''%3E%3Cpath fill='currentColor' d='M440.1 103C450.3 112.4 450.3 127.6 440.1 136.1L176.1 400.1C167.6 410.3 152.4 410.3 143 400.1L7.029 264.1C-2.343 255.6-2.343 240.4 7.029 231C16.4 221.7 31.6 221.7 40.97 231L160 350.1L407 103C416.4 93.66 431.6 93.66 440.1 103V103z'%3E%3C/path%3E%3C/svg%3E");
    font-family: 'Font Awesome 5 Pro-Regular';
    color: transparent;
    background-color: transparent;
    display: block;
    height: 13px;
    width: 13px;
    font-size: 14px;
    top: 0px;
    left: 1px;
}
.container-team .subtitle[data-v-9bae4d96]{
    font-size: 14px;
    color: white;
    font-family: 'Inter';
    padding: 10px 0;
}
.container-grades-team[data-v-9bae4d96]{
    margin-bottom: 20px;
}
.listTeamsSelected[data-v-9bae4d96] {
    font-family: 'Inter';
    margin: 0 auto;
    color: white;
    background-color: #34266b;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 15px 0;
    display: none;
}
.listTeamsSelected.active[data-v-9bae4d96]{
    display: flex;
}
.listTeamsSelected > div[data-v-9bae4d96] {
    display: flex;
    align-items: center;
    padding: 10px;
    height: 44px;
    background: #250D44;
    border-radius: 8px;
    width: 30%;
    margin-bottom: 15px;
    color: #a885d7;
    justify-content: space-between;
}
.listClub[data-v-9bae4d96]{
    width: calc(100% - 310px);
}
.modalCreateClub .add-advisor-modal-team[data-v-9bae4d96] {
    margin: 10px 0 0 0;
    padding: 10px;
    background-color: #2c2c2c;
}
.add-advisor-modal-team[data-v-9bae4d96] {
    display: flex;
    justify-content: flex-start;
    color: #a885d7;
    margin: 20px 0px;
}
.add-advisor-modal-team .icon-user[data-v-9bae4d96] {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #A885D7;
    margin-right: 12px;
}
.add-advisor-modal-team p[data-v-9bae4d96] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #A885D7;
}
.selected-clubs[data-v-9bae4d96] {
    width: calc(100% - 350px);
    font-family: 'Inter';
    margin: 0 auto;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.selected-club[data-v-9bae4d96] {
    color: white;
    background-color: #7400F9;
    border-radius: 5px;
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    margin-right: 15px;
    margin-bottom: 10px;
    display: flex;
}
.saved-club[data-v-9bae4d96]{
    display: flex;
}
.selected-club p[data-v-9bae4d96], .saved-club p[data-v-9bae4d96]{ 
    margin-right: 10px;
}
.saved-club[data-v-9bae4d96] {
    color: #a885d7;
    background-color: #444444;
    border-radius: 5px;
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    margin-right: 15px;
    margin-bottom: 10px;
}
.inputSearch[data-v-9bae4d96]{
    width: 100%;
    height: 35px;
    border-radius: 8px;
    border: 0;
    background-color: #260c44;
    color: white; 
    display: flex;
    align-items: center; 
    margin-top: 20px;
}
.input-search[data-v-9bae4d96]{
    color: white;
    width: 100%;
    border: 0;   
    background-color: #260c44;
    padding-left: 10px;
}
.contentInputSearch[data-v-9bae4d96]{
    width: 100%;
    font-family: 'Inter';
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: right;
}
.iconSearch[data-v-9bae4d96]{
    padding: 0 0 0 15px;
}
.buttonsSetup[data-v-9bae4d96] {
    display: flex;
    justify-content: flex-end;
}
.clubs-buttons[data-v-9bae4d96]{
    width: 420px;
}
.buttonsSetup .button-save[data-v-9bae4d96]{
    margin-left:40px;
}
.container-myschool[data-v-9bae4d96] {
    padding-top: 130px;
}
.container-myschool .button-save[data-v-9bae4d96]{
    margin: 0px 0px 0px 14px;
}
.container-myschool .button-skip[data-v-9bae4d96]{
    margin: 0px 0px 0px 14px;
}
.content-time > div[data-v-9bae4d96]{
    width: 100%;
    background-color: #2c2c2c;
    padding: 3px 0;
    position: relative;
    display: flex;
    align-items: center;
    padding: 3px 13px;
}
.content-time > p[data-v-9bae4d96]{
    padding: 0 15px;
}
.SetupCreateClub__clubName[data-v-9bae4d96] {
    width: 100%;
    padding: 10px;
    background-color: #2c2c2c;
    outline: none;
    border: none;
    border-radius: 5px;
    color: white;
}
.ModalCreateClub__titleInput[data-v-9bae4d96] {
    background-color: #383838;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #FFF;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid transparent;
    transition: 0.3s;
}
.ModalCreateComm__titleInput[data-v-9bae4d96] {
    background-color:  #34266B;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #FFF;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid transparent;
    transition: 0.3s;
}
.ModalCreateClub__titleInput[data-v-9bae4d96]:focus {
    border-bottom: 1px solid white;
}
.ModalCreateComm__titleInput[data-v-9bae4d96]:focus {
    border-bottom: 1px solid white;
}
.ModalCreateClub__headerWrapper[data-v-9bae4d96] {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.ModalCreateClub__headerWrapper > div[data-v-9bae4d96] {
    padding-left: 10px;
}
.custom-club-button[data-v-9bae4d96] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--t-blue);;
    border-radius: 12px;
    padding: 12px 24px;
    cursor: pointer;
}
.custom-club-text[data-v-9bae4d96] {
    margin-left: 6px;
    color: white;
    font-family: var(--font-family-roboto);
}
.rowTitleComm[data-v-9bae4d96] {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.allWidth[data-v-9bae4d96] {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.InviteMembers--selectionContainer[data-v-9bae4d96] {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
}
.InviteMembers--selectionChip[data-v-9bae4d96] {
    border: 1px solid var(--lavender);
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}
.InviteMembers--selectionChip.active[data-v-9bae4d96] {
    background-color: var(--lavender);
}
.InviteMembers--codeWrapper[data-v-9bae4d96] {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.InviteMembers--row[data-v-9bae4d96] {
    display: flex;
    flex-direction: row;
    padding: 5px 0;
}
.InviteMembers--formControl[data-v-9bae4d96] {
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.InviteMembers--formControl > input[data-v-9bae4d96] {
  background-color: var(--heavy-metal);
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 3px;
  width: 70%;
}
.InviteMembers--formControl > span[data-v-9bae4d96] {
    text-align: left;
    border: 0;
}
.InviteMembers--inputDisabled[data-v-9bae4d96] {
    border-radius: 3px;
    padding: 10px;
    background-color: var(--heavy-metal);
    width: 70%;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.InviteMembers--inputDisabled > span[data-v-9bae4d96] {
    width: 100% !important;
    overflow: scroll;
}
.InviteMembers--inputDisabled > button[data-v-9bae4d96] {
    background: none;
    border: none;
    cursor: pointer;
}
.InviteMembers--actions[data-v-9bae4d96] {
    justify-content: flex-end;
}
.InviteMembers--generateCode[data-v-9bae4d96] {
    padding: 8px;
    background-color: var(--electric-yellow);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;
}
.align-left[data-v-9bae4d96] {
    justify-content: flex-start !important;
    gap: 20px;
}
.btnProcess[data-v-9bae4d96] {
    pointer-events: none;
    opacity: 0.6;
}
.button-save.btnProcess[data-v-9bae4d96]{
    padding: 16px 20px !important;
}