.menuMyAccount[data-v-9106c367]{
    width: 100%;
}
.menuMyAccount li[data-v-9106c367]{
    display: flex;
    width: 100%;
    padding: 18px 30px;
    border-radius: 50px 0px 0px 50px;
    color: white;
    font-family: 'Inter';
    cursor: pointer;
}
.menuMyAccount li.active[data-v-9106c367] {
    background-color: #250D44;
}
.menuIcon[data-v-9106c367]{
    margin-right: 20px;
}
.containerMyAccount[data-v-9106c367]{
    width: 100%;
}
.containerMyAccount .row .titleInput[data-v-9106c367]{
    font-size: 14px;
    margin-bottom: 3px;
    font-family: 'Inter';
}
.containerMyAccount .row input[data-v-9106c367]{
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background-color: var(--t-blue);
    border: 0;
    padding: 0 15px;
    color: white;
}
.listRadioButtons[data-v-9106c367]{
    display: flex;
    font-family: 'Inter';
}
.option-status[data-v-9106c367] {
    padding-right: 20px;
}
.containerAboutMe[data-v-9106c367]{
    width: 100%;
    background-color: #28115a;
    border-radius: 3px;
    padding-bottom: 10px;
}
.containerAboutMe textarea[data-v-9106c367]{
    width: 100%;
    background-color: transparent;
    border:0;
    padding: 10px 20px;
    color: white;
    font-family: 'Inter';
}
.containerMyAccount .row.row2[data-v-9106c367]{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.containerMyAccount .row.row2 .col[data-v-9106c367]{
    width: 49%;
}
.leftColumn[data-v-9106c367]{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}
.containerMyAccount .row.row3 .col[data-v-9106c367]{
    width: 33%;
}
.containerMyAccount .row.row3.btns[data-v-9106c367]{
    display: flex;
    justify-content: flex-end;
}
.btnCancel[data-v-9106c367]{
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid var(--t-blue);
    border-radius: 12px;
    color: var(--t-blue);
    margin-top: 20px;
}
.btnSave[data-v-9106c367]{
    width: 100%;
    height: 40px;
    background: var(--t-blue);
    border: 0px solid var(--t-blue);
    border-radius: 12px;
    color: #FFFFFF;
    margin-top: 20px;
}
.imgProfile[data-v-9106c367]{
    width: 95px;
    height: 95px;
    min-width: 95px;
  min-height: 95px;
  max-width: 95px;
  max-height: 95px;
    border-radius: 50%;
    border: 5px solid #383838;
    object-fit: cover;
    margin-right: 20px;
    position: relative;
}
.imgProfile img[data-v-9106c367]{
    border-radius: 50%;
}
.photoProfile[data-v-9106c367] {
    display: flex;
    margin-top: 60px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
    flex-direction: inherit;
}
.photoProfile h2[data-v-9106c367]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    text-align: left;
}
.photoProfile span[data-v-9106c367]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    text-align: left;
}
.infoProfile[data-v-9106c367]{
    text-align: left;
}
.imgProfile[data-v-9106c367]::before{
    content: '\f03e';
    background-color: #A885D7;
    font-family: var(--font-family-font_awesome_5_pro-regular);
    position: absolute;
    bottom: -5px;
    z-index: 1;
    right: -5px;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-self: center;
    padding: 8px;
    font-size: 17px;
}
.inputWIcon[data-v-9106c367]{
    display: flex;
    align-items: center;
}
.IconTrash[data-v-9106c367]{
    margin-left: 12px;
}
.containerMyAccount .row span[data-v-9106c367]{
    color: var(--t-blue);
    font-size: 16px;
    font-family: 'Inter';
    text-align: left;
    width: 100%;
    display: block;
    margin: 15px 0;
}
.inputAddress[data-v-9106c367]{
    width: 95% !important;
}
.btnAddNewLink[data-v-9106c367]{
    padding: 10px 15px;
    background: var(--t-blue);
    color: white;
    border-radius: 12px;
    border: 0;
    margin-top: 15px;
}
.middle[data-v-9106c367]{
    align-items: center;
}
.lessTop[data-v-9106c367]{
    margin-top: 2px !important;
}
.selectMediaType[data-v-9106c367]{
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background-color: var(--t-blue);
    border: 0;
    padding: 0 20px;
    color: white;
}
.inputDisabledAccount[data-v-9106c367]{
    width: 100%;
    height: 45px;
    border-radius: 3px;
    background-color: var(--t-blue);
    opacity: 0.6;
    color: white;
    font-family: 'Inter';
    text-align: left;
    padding-left: 10px;
    padding-top: 13px;
}
.disabled[data-v-9106c367]{
    background-color: var(--t-blue) !important;
    opacity: 0.6;
}
.btnAddSchool[data-v-9106c367]{
    padding: 10px 15px;
    background: var(--t-blue);
    color: white;
    border-radius: 12px;
    border: 0;
}
.btnAddWork[data-v-9106c367]{
    padding: 10px 15px;
    background: var(--t-blue);
    color: white;
    border-radius: 12px;
    border: 0;
}