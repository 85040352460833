.infoSettings{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 20px;
}

.imageSettings{
    width: 60px;
    height: 60px;
    border: 2px solid gray;
    overflow: hidden;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.imageSettings img{
    height: 60px;
    width: 60px;
    min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
    object-fit: cover;
}

.nameSettings{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
}

.listOrgsSettings{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    text-align: left;
}

.upper{
    margin-top: 20px;
}