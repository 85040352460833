.club-left-section {
  width: 25%;
  z-index: 2;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
background: #aa9275;
  background-size: cover;
  position: fixed;
  padding-top: 25px;
  padding-bottom: 110px;
  height: 100%;
  overflow-y: auto;
}

.column-selected {
  border-right: 10px solid var(--onyx) !important;
}

.menu-sidebar::-webkit-scrollbar {
  display: none;
}

.superadmin {
  color:  var(--t-blue);
  margin-top: 6px;
  font-family: var(--font-family-roboto_condensed);
  font-size: 14px;
  font-weight: bold;
}

/* .section {
  color: white;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 60px 0px 0px 60px;
  background-color: transparent;
} */

.section-text:hover {
  cursor: pointer;
}

.section-text {
  color: var(--t-blue);;
}

.section-cont {
  /* padding-right: 20px; */
}

.section-selected {
  color: var(--lavender);
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 60px 0px 0px 60px;
  background-color: "#250D44";
}

.icons-navbar {
  display: flex;
  flex-direction: row;
}
