.club-view-information[data-v-5ba2b7b1] {
  width: 100%;
  padding: 3px 30px;
  display: flex;
  flex-direction: column;
  min-height: 10px;
}
.club-view-information h1[data-v-5ba2b7b1] {
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--t-blue);
  font-size: var(--font-size-xxxl);
  text-align: left;
}
.club-view-information p[data-v-5ba2b7b1] {
  width: 100%;
  padding: 20px 0;
  text-align: left;
}
.information-title-row[data-v-5ba2b7b1] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  grid-area: 1 / 1 / 2 / 2;
}
.information-title-row-edit[data-v-5ba2b7b1] {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  grid-area: 1 / 1 / 2 / 2; 

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.information-title-row-edit h1[data-v-5ba2b7b1] {
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--lavender);
  font-size: var(--font-size-xxxl);
  text-align: left;
  grid-area: 1 / 1 / 2 / 2;
}
.edit-info-button[data-v-5ba2b7b1] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--t-blue);
  border-radius: 12px;
  padding: 12px 24px;
}
.edit-info-buttonGrid1[data-v-5ba2b7b1]{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--t-blue);
  border-radius: 12px;
  width: 50%;
  grid-area: 1 / 1 / 2 / 2;
}
.edit-info-buttonGrid2[data-v-5ba2b7b1]{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--t-blue);
  border-color: var(--t-blue);
  border: 0px;
  border-radius: 12px;
  margin-left: 10px;
  padding: 12px 24px;
  grid-area: 1 / 2 / 2 / 3;
  color: white;
}
.club-view-edit-text[data-v-5ba2b7b1] {
  margin-left: 6px;
  color: white;
  font-family: var(--font-family-roboto);
}
.key-words-row[data-v-5ba2b7b1] {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.key-word[data-v-5ba2b7b1] {
  background-color: #28115a;
  padding: 10px 20px;
  border-radius: 82px;
  margin-right: 14px;
}
#officers-row[data-v-5ba2b7b1] {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
#view-all-officers[data-v-5ba2b7b1] {
  text-decoration: underline;
}
.question-icon[data-v-5ba2b7b1] {
  margin-left: 20px;
}
.officers-row[data-v-5ba2b7b1] {
  display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px;
    width: 33%;
    margin-top: 15px;
}
.moder-row[data-v-5ba2b7b1] {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.officers-column[data-v-5ba2b7b1] {
  display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 48px;
    width: 33%;
}
.officer-avatar[data-v-5ba2b7b1] {
  width: 50px;
  height: 50px;
  border: 2px solid var(--onyx);
  border-radius: 100%;
  margin-bottom: 5px;
}
.officer-name[data-v-5ba2b7b1] {
  font-weight: bold;
  margin-bottom: 4px;
  text-align: center;
}
.advisors-column[data-v-5ba2b7b1] {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.advisors-row[data-v-5ba2b7b1] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.advisors-row-right[data-v-5ba2b7b1] {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.advisor-avatar[data-v-5ba2b7b1] {
  width: 50px;
  height: 50px;
  border: 2px solid var(--onyx);
  border-radius: 100%;
}
.advisor-name[data-v-5ba2b7b1] {
  margin-left: 14px;
}
.infoLocation[data-v-5ba2b7b1],
.infoLocation > div[data-v-5ba2b7b1]{
  display: flex;
}
.infoLocation[data-v-5ba2b7b1]{
  flex-wrap: wrap;
}
.infoLocation > div[data-v-5ba2b7b1] {
  color: white;
  align-items: center;
  font-family: 'Inter';
  padding: 9px 0;
}
.infoDate[data-v-5ba2b7b1], .infoTime[data-v-5ba2b7b1]{
  width: 30%;
}
.infoAddress[data-v-5ba2b7b1]{
  width: 100%;
}
.iconInfoLocation[data-v-5ba2b7b1] {
  font-size: 18px;
  padding-right: 10px;
}
.infoLocation .information-title-row[data-v-5ba2b7b1] {
  margin: 20px 0 10px 0;
}
.buttonParticipanting[data-v-5ba2b7b1] {
  display: flex;
  justify-content: space-evenly;
}
.buttonParticipanting button[data-v-5ba2b7b1] {
  background-color: transparent;
  border: 0;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Inter';
  width: 100%;
}
button.btnGoing.activeButton div[data-v-5ba2b7b1],
button.btnInterested.activeButton div[data-v-5ba2b7b1] {
  background-color: #a785d9;
}
.buttonParticipanting button div[data-v-5ba2b7b1]{
  font-size: 20px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #383838;
  margin: 10px 0;
}
.desactiveButton[data-v-5ba2b7b1] {
  cursor: not-allowed !important;
  color: #383838 !important;
}
.desactiveButton > div[data-v-5ba2b7b1] {
  background-color: #383838 !important;
  color: gray !important;
}
.btnInterested[data-v-5ba2b7b1]{
}
.btnNotInterested[data-v-5ba2b7b1]{
}
.btnGoing[data-v-5ba2b7b1]{
}
.row[data-v-5ba2b7b1] {
  display: flex;
  width: 100%;
}
.row-edit[data-v-5ba2b7b1] {
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
}
.row .col[data-v-5ba2b7b1]{
  width: 100%;
}
.row .col input[data-v-5ba2b7b1] {
  width: 100%;
  height: 40px;
  background: #28115A;
  border-radius: 3px;
  border: 0;
  padding-left: 10px;
  color: white;
}
.row .col textarea[data-v-5ba2b7b1] {
  width: 100%;
  height: 100px;
  background: #28115A;
  border-radius: 3px;
  border: 0;
  padding-left: 10px;
  color: white;
}
.inputEvent[data-v-5ba2b7b1] {
  width: 95%;
    height: 40px;
    background: #28115A;
    border-radius: 3px;
    display: flex;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
}
.chevron-down[data-v-5ba2b7b1] {
  height: 20px;
  letter-spacing: 0;
  margin-left: 0px;
  margin-top: 10px;
  width: 18px;
}
.inputIcon[data-v-5ba2b7b1]{
  padding: 0;
  margin: 0;
}
.inputEvent select[data-v-5ba2b7b1]{
  color: white;
  padding: 0 10px;
}
.textareaEvent[data-v-5ba2b7b1]{
    width: 100%;
    height: 100px;
}
.buttonSave[data-v-5ba2b7b1]{
  background-color: var(--t-blue);
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--t-blue);
    color: white;
    width: 100px;
    margin-left: 12px;
}
.buttonCancel[data-v-5ba2b7b1]{
  background-color: white;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--t-blue);
    width: 100px;
}
.buttonsEvent[data-v-5ba2b7b1]{
  justify-content: right;
  margin-top: 40px;
}
.modalInviteAttendees .titleModal[data-v-5ba2b7b1]{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--t-blue);
  text-align: left;
}
.textUpload[data-v-5ba2b7b1]{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin: 7px 0;
}
.textFormat[data-v-5ba2b7b1]{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}
.btnFileExample[data-v-5ba2b7b1]{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #A885D7;
  border: 0;
  background-color: transparent;
  margin: 8px 0;
}
.dragZone[data-v-5ba2b7b1]{
  font-family: 'Inter';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  width: 548px;
  height: 139px;
  background: #2C2C2C;
  border-radius: 16px;
  color: white;
  text-align: center;
  line-height: 24px;
}
.dragZone button[data-v-5ba2b7b1]{
  color: #590CF6;
  width: 157px;
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #590CF6;
  border-radius: 12px;
}
.buttonSend[data-v-5ba2b7b1]{
  width: 157px;
  height: 44px;
  background: #590CF6;
  border-radius: 12px;
  border: 0;
  color: white;
  cursor: pointer;
}
.buttonClose[data-v-5ba2b7b1]{
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: #590CF6;
  border: 1px;
  border-color: #590cf6;
  background-color: white;
  cursor:pointer;

  width: 157px;
  height: 44px;
  border-radius: 12px;
}
.modalInviteAttendees .modal-header[data-v-5ba2b7b1]{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  text-align: left;
  color: var(--t-blue);
}
.modalHeader2[data-v-5ba2b7b1]{
  padding-top: 20px;
}
.modalInviteAttendees textarea[data-v-5ba2b7b1]{
  padding: 10px;
  width: 100%;
  height: 130px;
  background: #28115A;
  border-radius: 3px;
  border: 0;
  color: white;
  font-family: 'Inter';
}
.GridEventInfo[data-v-5ba2b7b1]{
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: flex-end;
  align-items: center;
}
.moderators-row[data-v-5ba2b7b1] {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0px;
  width: 100%;
  margin-top: 15px;
}
.officer[data-v-5ba2b7b1] {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.profileOfficer[data-v-5ba2b7b1]{
  width: 30%;
  color: var(--t-blue);
  font-family: 'Inter';
  line-height: 18px;
}
.nameOfficer[data-v-5ba2b7b1] {
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 700;
}
.professionOfficer[data-v-5ba2b7b1] {
  margin: 0 !important;
  padding: 0 !important;
}
.emailOfficer[data-v-5ba2b7b1]{
  width: 70%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: var(--t-blue);
}
.emailOfficerAdd[data-v-5ba2b7b1]{
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: var(--t-blue);
}
.emailOfficerAddHalf[data-v-5ba2b7b1]{
  padding-right: 10px;
  width: 50%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: var(--t-blue);
}
.inputContainerOfficer[data-v-5ba2b7b1]{
  background: #28115A;
  border-radius: 3px;
  width: 100%;
  margin-top: 5px;
  color: white;
  font-weight: normal;
}
.inputContainerOfficer input[data-v-5ba2b7b1]{
  background: var(--t-blue);
  border-radius: 3px;
  color: white;
  padding: 7px 15px;
  width: 100%;
  border: 0;
}
.buttonTrashOfficer[data-v-5ba2b7b1]{
  background-color: white;
  border-radius: 50%;
  padding: 8px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--t-blue);
}
.buttonPenOfficer[data-v-5ba2b7b1]{
  background-color: var(--t-blue);
  border-radius: 50%;
  padding: 8px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--t-blue);
}
.actionsOfficer[data-v-5ba2b7b1]{
  display: flex;
  width: 100px;
  justify-content: space-between;
  padding-left: 12px;
}
.moderatorsCenter[data-v-5ba2b7b1]{
  display: inline-flex;
  grid-area: 2 / 1 / 3 / 2;
  flex-wrap: wrap;
  justify-content: left;
  gap:20px;
  margin-top: 10px;
}
.buttonAdd[data-v-5ba2b7b1]{
  padding: 10px 15px;
  background: var(--t-blue);
  color: white;
  border-radius: 12px;
  border: 0; 
  grid-area: 1 / 2 / 2 / 3;
}
.inputInfo[data-v-5ba2b7b1]{
  grid-area: 2 / 1 / 3 / 2;
}
.inputEventType[data-v-5ba2b7b1]{
  color: white;
    font-family: 'Inter';
}