/*StartPost*/

.round-avatar-create-post {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.startPost{
    border-radius: 40px;
    border: 1px solid #FFFFFF;
    border-width: 1px;
    width: 90%;
    color: #FFFFFF;
    padding: 15px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: var(--violent-violet);
    text-align: left;
}

.startPost-container{
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    border: 1px solid var(--t-blue);
    border-width: 1px;
    width: 100%;
    font-family: 'Inter';
    align-items: center;

    padding: 10px;
}

/*SelectPostType*/

.modalCreatePost .modal-content{
    display: flex;
    flex-direction: column;
    padding: 20px !important;
}

.modalCreatePost .modal-header{
    display: flex;
    justify-content: space-between;
    padding:5px !important;
}

.modalCreatePost .modal-body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding:5px !important;
}

.modalCreatePost .modal-footer{
    display: flex;
    justify-content: space-between;
    padding:5px !important;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    height: 50px;
    margin-top: 15px;
}

.titleModalPost{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    display: inline-block;
}

.modalCreatePostHeader__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.modalCreatePostHeader__number {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    border: 1px solid white;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.modalCreatePostHeader__number.active {
    border: 0;
    background-color: #A885D7;
    font-weight: 600;
}

.createPost__selectTypeLabel {
    font-size: 16px;
    font-weight: 700;
    padding: 10px;
    color: #A885D7;
    font-family: 'Inter';
    margin-bottom: 3px;
  }
  
  .createPost__selectTypeOptions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }
  
  .createPost__selectTypeActions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    gap: 20px;
  }
  
  .createPost__selectTypeOption {
    flex: 1 1;
    background-color: #2C2C2C;
    padding: 7px;
    border-radius: 4px;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #2C2C2C;
  }
  
  .createPost__selectTypeOption:hover {
    border: 1px solid #A885D7;
  }

  .createPost__selectTypeOption:hover .tiptext{
    visibility: visible;
  }
  
  .createPost__selectTypeOption.active {
    border: 1px solid #A885D7;
    background-color: #121212;
  }
  
  .modalCreatePostHeader__number,
  .createPost__selectTypeOption span{
    font-family: 'Inter';
  }

  .modalCreatePostHeader__number,
  .createPost__selectTypeOption div{
    font-family: 'Inter';
  }
  
  .createPost__btn {
    flex: 1 1;
    padding: 12.5px;
    border-radius: 12px;
  }

  .modalCreatePost .buttonClose {
    border: 1px solid #7400F9;
    color: #7400F9;
    
  }
  
  .modalCreatePost .buttonSend {
    background: #7400F9;
    border: 1px solid #7400F9;
    color: white;
    border: 0;
  }

  .createPost__selectTypeOption .tiptext {
    visibility: hidden;
    width: 95px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 6px;
    position: absolute;
    z-index: 1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin-left: -10px;
    bottom: 70%;
}

/*DropdownReachPost*/

.reachPost-container{
    display: flex;
    justify-content: space-between;
}

.reachPost-container select{
    background-color: var(--t-blue);
    border-radius: 7px;
    color: white;
    border: 1px solid var(--t-blue);
    cursor: pointer;
}

/*CreatePost*/

.containerPostCreate{
  margin-top: 5px;
  width: 100%;
  border: 1px solid var(--t-blue);
  border-radius: 10px;
  background-color: var(--t-blue);
}

.containerPostCreate textarea{
  width: 100%;
  height: 50px;
  border: 0;
  padding: 15px;
  resize: none;
  outline: none;
  color: white;
  background: transparent;
  font-family: 'Inter';
  font-size: 18px;
}

.containerPostCreate textarea::-webkit-input-placeholder {
  color: var(--simpley-gray-2);
  font-family: 'Inter';
  font-size: 18px;
}

.containerPostCreate textarea::placeholder {
  color: var(--simpley-gray-2);
  font-family: 'Inter';
  font-size: 18px;
}

textarea::-webkit-input-placeholder {
  color: white;
  font-family: 'Inter';
  font-size: 18px;
}

textarea::placeholder {
  color: white;
  font-family: 'Inter';
  font-size: 18px;
}

.containerPostCreate img{
  width: 100%;
}

.buttonsCapture{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap:40px
}

.buttonsCapture button{
  width: 60px;
  height: 60px;
  background-color: #383838;
  bottom: 0px;
  left: 50%;
  margin: -25px 0 0 -25px;
  border-radius: 50%;
}

.textDragPostCreate{
  width: 100%;
  height: 50px;
  border: 0;
  padding: 15px;
  resize: none;
  outline: none;
  color: var(--t-blue);
  background: transparent;
  font-family: 'Inter';
}

.paddingCreateText{
  padding: 10px;
}

.containerPostCreateImage{
  margin-top: 5px;
  width: 100%;
  border: 1px solid var(--t-blue);
  border-radius: 10px;
  border-style: dashed;
  font-size: 18px;
}

.containerPostCreateImage.Ins{
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 1em;
}
.containerPostCreateImage.Ins .containerPostCreateLink{
  margin: 0;
  width: 50%;
}

/*Post Modal*/

.PostModal-round-avatar-create-post {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.PostModal-LabelGeneral{
  font-size: 16px;
  /* color: #FFFFFF; */
    color: var(--t-blue);
  text-align: left;
  /* margin-left: 10px; */
}

.PostModal-ButtonsContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 350px);
}

.PostModal-LabelContainer{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.PostModal-upper-buttons{
  margin-top: 10px;
}

.PostModal-createPost__selectTypeOption {
  flex: 1 1;
  /* background-color: #28115a; */
    background-color: var(--t-blue);
  padding: 7px;
  border-radius: 10px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #FFFFFF;
  font-family: 'Inter';
}

.PostModal-createPost__selectTypeOption:hover {
  border: 1px solid var(--t-blue);
  background-color: var(--t-blue);
  color: #FFFFFF;
}

.PostModal-createPost__selectTypeOption.active {
  border: 1px solid var(--t-blue);
  background-color: var(--t-blue);
  color: #FFFFFF;
}

.PostModal-Exit{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.PostModal-IntroduceInfoContainer{
  display: flex;
  flex-direction: row;
  gap:10px;
  align-items: center;
}

.PostModal-IntroduceInfoContainer.text{
  margin-top: 10px;
}

.widthResponsive{
  height: 65px;
  width: 80px !important;
  cursor: default;
}

.PostModal-ActionsContainer{
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 40px;
  margin-top: 15px;
  justify-content: flex-end;
}

.PostModal-ActionsContainer.bulletin{
  justify-content: space-between !important;
}

.PostModal-buttonSavePost{
  background: var(--t-blue);
  border: 1px solid var(--t-blue);
  color: white;
  border-radius: 10px;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.PostModal-buttonSavePost.activeLoading{
  background: var(--t-blue);
  cursor: default;
}

/*Create Post with Links*/
.containerPostCreateLink{
  border: 1px solid var(--t-blue);
  border-radius: 10px;
  background-color: var(--t-blue);
  margin: 1em;
}

.containerPostCreateLink-inputText{
  width: 100%;
  height: 50px;
  border: 0;
  padding: 15px;
  resize: none;
  outline: none;
  color: var(--t-blue);
  background: transparent;
  font-family: 'Inter';
  font-size: 18px;
}

.containerPostCreateLink-inputText.HeightAuto{
  height: auto;
}

.containerPostCreateLink-inputText.link{
  text-align: center;
  padding: 5px;
  /* height: 40px; */
}

.containerPostCreateLink-inputText::-webkit-input-placeholder {
  color: var(--simple-gray-2) !important;
  font-family: 'Inter';
  font-size: 18px;
}

.containerPostCreateLink .containerPostCreateLink-inputText,
.containerPostCreateLink-inputText::placeholder {
  color: var(--simple-gray-2) !important;
  font-family: 'Inter';
  font-size: 18px;
}

.containerPostCreateLink-inputText.link::-webkit-input-placeholder {
  color: var(--t-blue) !important;
  font-family: 'Inter';
  font-size: 16px;
}

.containerPostCreateLink-inputText.link::placeholder {
  color: var(--t-blue) !important;
  font-family: 'Inter';
  font-size: 16px;
}

textarea::-webkit-input-placeholder {
  color: var(--t-blue);
  font-family: 'Inter';
  font-size: 18px;
}

textarea::placeholder {
  color: var(--t-blue);
  font-family: 'Inter';
  font-size: 18px;
}

.PostModal-preview{
  display: flex;
  flex-direction: column;
}

@-webkit-keyframes spin {
  100% {
      -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

.icon-spinner-third {
  margin-top: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.buttonSendSignUp {
  height: 25px;
  border: 3px solid;
  width: 25px;
  display: block;
  border-radius: 50%;
  border-bottom-color: white;
  border-top-color: #0000ff00;
  border-right-color: #0000ff00;
  border-left-color: #0000ff00;
  margin-top: -3px !important;
}