.App {
  text-align: center;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-float infinite 3s ease-in-out;
            animation: App-logo-float infinite 3s ease-in-out;
  }
}

.pointer {
  cursor: pointer;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@-webkit-keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

/* Modals */

.hidden {
  display: none;
}

.open {
  display: block !important;
}

.modal-open {
  overflow: hidden;
}

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  /*background-color: #00000063;*/
  padding-top: 100px;
}

.modal-dialog {
  position: relative;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modal-content {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #999999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  /*background-color: #0000003b;*/
}

.modal-header {
  padding: 24px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.42857143px;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px 24px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
}

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: .5;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: auto;
  }

  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  .modal-sm {
    width: 300px;
  }
  
  .ModalPayForm .modal-dialog{
    width: 500px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

[role="button"] {
  cursor: pointer;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

.modal-content {
  background: white;
  border-radius: 12px;
  padding: 10px;
}

.modal-header,
.modal-footer {
  border: 0;
}

.modalInviteMemebers .modal-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--t-blue);
}

.modalSeePayment .modal-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: normal;
  color: var(--t-blue);
  text-align: left;
}

.modalSeePayment {
  background-color: #00000063;
}


.modalSeePayment .modal-footer {
  padding: 15px 15px;
}

.modalSeePayment .modal-body {
  padding: 0px 15px;
}

.modalSeePayment .modal-dialog {
  width: 70vw;
}

.modalModalitiesCreate .modal-body {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}

.modalModalitiesCreate .modal-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: normal;
  color: var(--t-blue);
}

.modalModalitiesCreate .modal-footer {
  display: flex;
  gap: 10px;
  padding: 0px;
  width: 100%;
}

/* Modal Clubs */

.modalCreateClub .modal-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #FFF;
}

.nav {
  display: flex;
  margin-bottom: 30px;
}

.nav div {
  background-color: #250D44;
  width: 33%;
  text-align: center;
  padding: 9px;
  color: white;
  font-family: 'Inter';
  font-size: 19px;
  margin: 0 2px;
  height: 35px;
  cursor: pointer;
}

.nav div:first-child {
  border-radius: 6px 0px 0px 6px;
}

.nav div:last-child {
  border-radius: 0px 6px 6px 0px;
}

.affiliated {
  /* margin: 10px 0; */
  width: 100%;
  background-color: #2C2C2C;
  padding: 10px;
  border-radius: 3px;
  color: white;
}

.selectAffiliated label {
  font-size: 16px;
  color: #A885D7;
  font-family: 'Inter';
  font-weight: bold;
  margin: 10px 0;
  display: block;
  text-align: left;
}

.affiliated select {
  font-size: 15px;
  color: #fff;
}

.modalCreateClub h2 {
  color: #a985d7;
  font-size: 16px;
  margin: 10px 0;
  font-family: 'Inter';
  font-weight: 700;
}

.modalCreateClub .subtitle {
  color: white;
  font-size: 14px;
  display: block;
  font-family: 'Inter';
}

.modalCreateClub .containers-input {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

.modalCreateClub .option-chechbox {
  display: flex;
  flex-direction: row;
  height: 20px;
  padding: 0;
}

.maximunMembers input {
  width: 100%;
  background-color: #2c2c2c;
  color: white;
  border: 0;
  height: 45px;
  padding: 13px;
  font-size: 16px;
}

.infoMembers>div {
  margin-bottom: 13px;
}

.addAdvisor label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
}

.modalCreateClub .modal-footer button {
  margin: 0px 7px;
  width: 50%;
}

.addAdvisor {
  margin: 25px 0 0 0;
}

.modalCreateClub .add-advisor-modal {
  margin: 10px 0 0 0;
}

.nav .active {
  background-color: #34266B;
}

.textDescription {
  background-color: #2c2c2c;
  width: 100%;
  height: 160px;
  border: 0;
  border-radius: 8px;
}

.textSkills {
  background-color: #2c2c2c;
  width: 100%;
  height: 70px;
  border: 0;
  border-radius: 8px;
  color: white;
  padding: 10px;
}

.jodit-status-bar {
  display: flex !important;
  background-color: transparent !important;
  color: var(--simple-gray) !important;
  border: 0px !important;
  padding: 0px !important;
  margin-right: -5px !important;
}

.jodit-add-new-line {
  display: none !important;
}

.jodit-status-bar .jodit-status-bar__item>span {
  font-family: 'Inter' !important;
}

.jodit-status-bar-link {
  display: none !important;
}

.jodit-status-bar .jodit-status-bar__item.jodit-status-bar__item-right {
  text-transform: capitalize !important;
}

.jodit-container:not(.jodit_inline) {
  border: 0 !important;
}

.textareaEvent .jodit-container:not(.jodit_inline) .jodit-workplace {
  background-color: #28115A !important;
}

.jodit-container:not(.jodit_inline) .jodit-workplace {
  /* background-color: #2c2c2c !important; */
  border-radius: 0px 0px 3px 3px !important;
  margin-bottom: 2px;
}

.jodit-container:not(.jodit_inline) {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.jodit-toolbar__box {
  /* order: 1; */
  background-color: #fff !important;
  border-radius: 3px 3px 0px 0px !important;
  border-bottom: 1px solid black !important;

}

.modalCreateNewEvent .jodit-status-bar__item.jodit-status-bar__item-right{
  position: absolute;
  bottom: 2px;
  right: 9px;
}

.modalCreateNewEvent .jodit-toolbar__box{
  max-height: 72px;
  min-height: 38px;
}

.jodit-toolbar-editor-collection_mode_horizontal:after {
  display: none !important;
}

.jodit-icon {
  fill: rgb(0, 0, 0) !important;
}

.jodit-toolbar-button__button:hover {
  background-color: var(--simple-gray-2) !important;
}

.jodit-wysiwyg {
  /* color: white; */
  font-family: 'Inter';
}

.react-numeric-input {
  width: 100% !important;
}

.react-numeric-input input {
  border: 0 !important;
}

.react-numeric-input b {
  background-color: #121212 !important;
}

/*.react-numeric-input b:hover{
  background-color: purple !important;
}
*/

.react-numeric-input b:before {
  width: 15px;
  content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='chevron-up' class='svg-inline--fa fa-chevron-up fa-sm ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' color='%23FFF'%3E%3Cpath fill='currentColor' d='M15.41 302.7l191.1-183.1C212 114.2 218 111.1 224 111.1s11.97 2.219 16.59 6.688l191.1 183.1c9.594 9.152 9.906 24.34 .7187 33.9c-9.125 9.625-24.38 9.938-33.91 .7187L224 169.2l-175.4 168c-9.5 9.219-24.78 8.906-33.91-.7187C5.502 327 5.814 311.8 15.41 302.7z'%3E%3C/path%3E%3C/svg%3E");
  /* font-family: 'Font Awesome 5 Pro-Regular'; */
  display: inline-block;
  font-weight: 900;
  position: absolute;
  top: 60%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  font-size: 20px;
}

.react-numeric-input b:last-child:before {
  content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down fa-sm ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' color='%23FFF'%3E%3Cpath fill='currentColor' d='M432.6 209.3l-191.1 183.1C235.1 397.8 229.1 400 224 400s-11.97-2.219-16.59-6.688L15.41 209.3C5.814 200.2 5.502 184.1 14.69 175.4c9.125-9.625 24.38-9.938 33.91-.7187L224 342.8l175.4-168c9.5-9.219 24.78-8.906 33.91 .7187C442.5 184.1 442.2 200.2 432.6 209.3z'%3E%3C/path%3E%3C/svg%3E");
}

::-webkit-scrollbar {
  display: none;
}

/* .rc-time-picker-panel-select{
  width: 100px !important; ;
}

.rc-time-picker-panel{
  width: 210px !important; ;
}

.rc-time-picker-panel-input-wrap{
  display: none;
}

.rc-time-picker-panel-select{
  border: 0px solid #e9e9e9 !important;
  font-size: 20px !important; ;
  color: #fff;
  background-color: #121212;
  min-height: 50px;
}

.rc-time-picker-panel-inner{
  background-color: transparent !important; 
  box-shadow: none !important; 
  border: 0px solid #ccc !important; 
}

.rc-time-picker-panel-select li{
  padding: 9px 0 !important; ;
  text-align: center !important;
  height: auto !important;
}

.rc-time-picker-panel-combobox{
  display: flex;
  position: absolute;
  top: -58px;
}

li.rc-time-picker-panel-select-option-selected {
  background: #2c2c2c !important;
}

.rc-time-picker-panel-select {
  border-radius: 10px;
  margin-right: 10px;
}

.rc-time-picker-panel-select li:hover {
  background: #2c2c2cd4 !important;
} */

.content-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-family: 'Inter';
}

.meeting-location .containers-input input {
  width: 100%;
  height: 40px;
  background-color: #2c2c2c;
  border: 0;
  border-radius: 3px;
  color: white;
}

.rc-time-picker-input {
  padding: 12px 12px 12px 45px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #878787;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  background-color: #383838;
  transition: .4s;
}

input:checked+.slider {
  background-color: #878787;
  ;
}

/* input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
} */

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
  width: 51px;
  left: -22px;
  border-radius: 34px;
  background-color: #590CF6;
}

.inputEvent .rc-time-picker {
  background-color: #28115A;
  display: flex;
}

.inputEvent .rc-time-picker-input {
  background-color: #28115A;
  border: 0;
  font-size: 16px;
  padding: 0 0px 0 12px !important;
  color: white;
}

.inputBirthdayGrid .inputDirthday {
  background-color: var(--light-brown) !important;
}

.jodit-wysiwyg {
  text-align: left !important;
}

.CharCounter__wrapper {
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
}

.CharCounter__text {
  color: white;
  font-size: 12px;
  font-family: 'Inter';
}

.ant-fade-enter,
.ant-fade-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-fade-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  -webkit-animation-name: antFadeIn;
  animation-name: antFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-fade-leave.ant-fade-leave-active {
  -webkit-animation-name: antFadeOut;
  animation-name: antFadeOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.ant-fade-leave {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes antFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes antFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}

[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #1890ff;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}

@-webkit-keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}

@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}

@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}

@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}

.ant-slide-up-enter,
.ant-slide-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-slide-up-leave.ant-slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-slide-up-enter,
.ant-slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.ant-slide-down-enter,
.ant-slide-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-slide-down-leave.ant-slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-slide-down-enter,
.ant-slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.ant-slide-left-enter,
.ant-slide-left-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
  animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-slide-left-leave.ant-slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
  animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-slide-left-enter,
.ant-slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.ant-slide-right-enter,
.ant-slide-right-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
  animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-slide-right-leave.ant-slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
  animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-slide-right-enter,
.ant-slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}

@keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}

@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}

@keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}

@-webkit-keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}

@keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}

@-webkit-keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}

@keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}

.ant-scroll-number {
  overflow: hidden;
  direction: ltr;
}

.ant-scroll-number-only {
  position: relative;
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}

.ant-scroll-number-only>p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}

.ant-scroll-number-symbol {
  vertical-align: top;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
}

.ant-btn>.anticon {
  line-height: 1;
}

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}

.ant-btn:not([disabled]):hover {
  text-decoration: none;
}

.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}

.ant-btn[disabled] {
  cursor: not-allowed;
}

.ant-btn[disabled]>* {
  pointer-events: none;
}

.ant-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
}

.ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px;
}

.ant-btn>a:only-child {
  color: currentcolor;
}

.ant-btn>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn:hover,
.ant-btn:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: #fff;
}

.ant-btn:hover>a:only-child,
.ant-btn:focus>a:only-child {
  color: currentcolor;
}

.ant-btn:hover>a:only-child::after,
.ant-btn:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn:active {
  color: #096dd9;
  border-color: #096dd9;
  background: #fff;
}

.ant-btn:active>a:only-child {
  color: currentcolor;
}

.ant-btn:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn[disabled]>a:only-child,
.ant-btn[disabled]:hover>a:only-child,
.ant-btn[disabled]:focus>a:only-child,
.ant-btn[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn[disabled]>a:only-child::after,
.ant-btn[disabled]:hover>a:only-child::after,
.ant-btn[disabled]:focus>a:only-child::after,
.ant-btn[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #fff;
}

.ant-btn>span {
  display: inline-block;
}

.ant-btn-primary {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary>a:only-child {
  color: currentcolor;
}

.ant-btn-primary>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #40a9ff;
  background: #40a9ff;
}

.ant-btn-primary:hover>a:only-child,
.ant-btn-primary:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-primary:hover>a:only-child::after,
.ant-btn-primary:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-primary:active {
  color: #fff;
  border-color: #096dd9;
  background: #096dd9;
}

.ant-btn-primary:active>a:only-child {
  color: currentcolor;
}

.ant-btn-primary:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-primary[disabled]>a:only-child,
.ant-btn-primary[disabled]:hover>a:only-child,
.ant-btn-primary[disabled]:focus>a:only-child,
.ant-btn-primary[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-primary[disabled]>a:only-child::after,
.ant-btn-primary[disabled]:hover>a:only-child::after,
.ant-btn-primary[disabled]:focus>a:only-child::after,
.ant-btn-primary[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #40a9ff;
  border-left-color: #40a9ff;
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #40a9ff;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary+.ant-btn-primary {
  border-left-color: #40a9ff;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary+.ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}

.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: transparent;
}

.ant-btn-ghost>a:only-child {
  color: currentcolor;
}

.ant-btn-ghost>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}

.ant-btn-ghost:hover>a:only-child,
.ant-btn-ghost:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-ghost:hover>a:only-child::after,
.ant-btn-ghost:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-ghost:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}

.ant-btn-ghost:active>a:only-child {
  color: currentcolor;
}

.ant-btn-ghost:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-ghost[disabled]>a:only-child,
.ant-btn-ghost[disabled]:hover>a:only-child,
.ant-btn-ghost[disabled]:focus>a:only-child,
.ant-btn-ghost[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-ghost[disabled]>a:only-child::after,
.ant-btn-ghost[disabled]:hover>a:only-child::after,
.ant-btn-ghost[disabled]:focus>a:only-child::after,
.ant-btn-ghost[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
  border-style: dashed;
}

.ant-btn-dashed>a:only-child {
  color: currentcolor;
}

.ant-btn-dashed>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: #fff;
}

.ant-btn-dashed:hover>a:only-child,
.ant-btn-dashed:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-dashed:hover>a:only-child::after,
.ant-btn-dashed:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dashed:active {
  color: #096dd9;
  border-color: #096dd9;
  background: #fff;
}

.ant-btn-dashed:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dashed:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dashed[disabled]>a:only-child,
.ant-btn-dashed[disabled]:hover>a:only-child,
.ant-btn-dashed[disabled]:focus>a:only-child,
.ant-btn-dashed[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dashed[disabled]>a:only-child::after,
.ant-btn-dashed[disabled]:hover>a:only-child::after,
.ant-btn-dashed[disabled]:focus>a:only-child::after,
.ant-btn-dashed[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-danger {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-danger>a:only-child {
  color: currentcolor;
}

.ant-btn-danger>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}

.ant-btn-danger:hover>a:only-child,
.ant-btn-danger:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-danger:hover>a:only-child::after,
.ant-btn-danger:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-danger:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}

.ant-btn-danger:active>a:only-child {
  color: currentcolor;
}

.ant-btn-danger:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-danger[disabled]>a:only-child,
.ant-btn-danger[disabled]:hover>a:only-child,
.ant-btn-danger[disabled]:focus>a:only-child,
.ant-btn-danger[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-danger[disabled]>a:only-child::after,
.ant-btn-danger[disabled]:hover>a:only-child::after,
.ant-btn-danger[disabled]:focus>a:only-child::after,
.ant-btn-danger[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link {
  color: #1890ff;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}

.ant-btn-link>a:only-child {
  color: currentcolor;
}

.ant-btn-link>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}

.ant-btn-link:hover>a:only-child,
.ant-btn-link:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-link:hover>a:only-child::after,
.ant-btn-link:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}

.ant-btn-link:active>a:only-child {
  color: currentcolor;
}

.ant-btn-link:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-link[disabled]>a:only-child,
.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-link[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-link[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link:hover {
  background: transparent;
}

.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-link[disabled]>a:only-child,
.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-link[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-link[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text {
  color: rgba(0, 0, 0, 0.85);
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}

.ant-btn-text>a:only-child {
  color: currentcolor;
}

.ant-btn-text>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}

.ant-btn-text:hover>a:only-child,
.ant-btn-text:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-text:hover>a:only-child::after,
.ant-btn-text:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}

.ant-btn-text:active>a:only-child {
  color: currentcolor;
}

.ant-btn-text:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-text[disabled]>a:only-child,
.ant-btn-text[disabled]:hover>a:only-child,
.ant-btn-text[disabled]:focus>a:only-child,
.ant-btn-text[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-text[disabled]>a:only-child::after,
.ant-btn-text[disabled]:hover>a:only-child::after,
.ant-btn-text[disabled]:focus>a:only-child::after,
.ant-btn-text[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}

.ant-btn-text:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}

.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-text[disabled]>a:only-child,
.ant-btn-text[disabled]:hover>a:only-child,
.ant-btn-text[disabled]:focus>a:only-child,
.ant-btn-text[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-text[disabled]>a:only-child::after,
.ant-btn-text[disabled]:hover>a:only-child::after,
.ant-btn-text[disabled]:focus>a:only-child::after,
.ant-btn-text[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  background: #fff;
}

.ant-btn-dangerous>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
  background: #fff;
}

.ant-btn-dangerous:hover>a:only-child,
.ant-btn-dangerous:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous:hover>a:only-child::after,
.ant-btn-dangerous:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
  background: #fff;
}

.ant-btn-dangerous:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous[disabled]>a:only-child,
.ant-btn-dangerous[disabled]:hover>a:only-child,
.ant-btn-dangerous[disabled]:focus>a:only-child,
.ant-btn-dangerous[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous[disabled]>a:only-child::after,
.ant-btn-dangerous[disabled]:hover>a:only-child::after,
.ant-btn-dangerous[disabled]:focus>a:only-child::after,
.ant-btn-dangerous[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-dangerous.ant-btn-primary>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-primary>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}

.ant-btn-dangerous.ant-btn-primary:hover>a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-primary:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}

.ant-btn-dangerous.ant-btn-primary:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-primary:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-primary[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-primary[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}

.ant-btn-dangerous.ant-btn-link:hover>a:only-child,
.ant-btn-dangerous.ant-btn-link:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}

.ant-btn-dangerous.ant-btn-link:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: transparent;
  background: transparent;
}

.ant-btn-dangerous.ant-btn-link:hover>a:only-child,
.ant-btn-dangerous.ant-btn-link:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: transparent;
  background: transparent;
}

.ant-btn-dangerous.ant-btn-link:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}

.ant-btn-dangerous.ant-btn-text:hover>a:only-child,
.ant-btn-dangerous.ant-btn-text:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}

.ant-btn-dangerous.ant-btn-text:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff7875;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.018);
}

.ant-btn-dangerous.ant-btn-text:hover>a:only-child,
.ant-btn-dangerous.ant-btn-text:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text:active {
  color: #d9363e;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.028);
}

.ant-btn-dangerous.ant-btn-text:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -3px;
}

.ant-btn-icon-only>* {
  font-size: 16px;
}

.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 2px;
}

.ant-btn-icon-only.ant-btn-lg>* {
  font-size: 18px;
}

.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 2px;
}

.ant-btn-icon-only.ant-btn-sm>* {
  font-size: 14px;
}

.ant-btn-icon-only>.anticon {
  display: flex;
  justify-content: center;
}

.ant-btn-icon-only .anticon-loading {
  padding: 0 !important;
}

a.ant-btn-icon-only {
  vertical-align: -1px;
}

a.ant-btn-icon-only>.anticon {
  display: inline;
}

.ant-btn-round {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}

.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
}

.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 24px;
}

.ant-btn-round.ant-btn-icon-only {
  width: auto;
}

.ant-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}

.ant-btn-circle.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}

.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}

.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}

.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn .anticon.anticon-plus>svg,
.ant-btn .anticon.anticon-minus>svg {
  shape-rendering: optimizespeed;
}

.ant-btn.ant-btn-loading {
  position: relative;
  cursor: default;
}

.ant-btn.ant-btn-loading::before {
  display: block;
}

.ant-btn>.ant-btn-loading-icon {
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn>.ant-btn-loading-icon .anticon {
  padding-right: 8px;
  -webkit-animation: none;
  animation: none;
}

.ant-btn>.ant-btn-loading-icon .anticon svg {
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}

.ant-btn-group {
  position: relative;
  display: inline-flex;
}

.ant-btn-group>.ant-btn,
.ant-btn-group>span>.ant-btn {
  position: relative;
}

.ant-btn-group>.ant-btn:hover,
.ant-btn-group>span>.ant-btn:hover,
.ant-btn-group>.ant-btn:focus,
.ant-btn-group>span>.ant-btn:focus,
.ant-btn-group>.ant-btn:active,
.ant-btn-group>span>.ant-btn:active {
  z-index: 2;
}

.ant-btn-group>.ant-btn[disabled],
.ant-btn-group>span>.ant-btn[disabled] {
  z-index: 0;
}

.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}

.ant-btn-group .ant-btn+.ant-btn,
.ant-btn+.ant-btn-group,
.ant-btn-group span+.ant-btn,
.ant-btn-group .ant-btn+span,
.ant-btn-group>span+span,
.ant-btn-group+.ant-btn,
.ant-btn-group+.ant-btn-group {
  margin-left: -1px;
}

.ant-btn-group .ant-btn-primary+.ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}

.ant-btn-group .ant-btn {
  border-radius: 0;
}

.ant-btn-group>.ant-btn:first-child,
.ant-btn-group>span:first-child>.ant-btn {
  margin-left: 0;
}

.ant-btn-group>.ant-btn:only-child {
  border-radius: 2px;
}

.ant-btn-group>span:only-child>.ant-btn {
  border-radius: 2px;
}

.ant-btn-group>.ant-btn:first-child:not(:last-child),
.ant-btn-group>span:first-child:not(:last-child)>.ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-btn-group>.ant-btn:last-child:not(:first-child),
.ant-btn-group>span:last-child:not(:first-child)>.ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-btn-group-sm>.ant-btn:only-child {
  border-radius: 2px;
}

.ant-btn-group-sm>span:only-child>.ant-btn {
  border-radius: 2px;
}

.ant-btn-group-sm>.ant-btn:first-child:not(:last-child),
.ant-btn-group-sm>span:first-child:not(:last-child)>.ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-btn-group-sm>.ant-btn:last-child:not(:first-child),
.ant-btn-group-sm>span:last-child:not(:first-child)>.ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-btn-group>.ant-btn-group {
  float: left;
}

.ant-btn-group>.ant-btn-group:not(:first-child):not(:last-child)>.ant-btn {
  border-radius: 0;
}

.ant-btn-group>.ant-btn-group:first-child:not(:last-child)>.ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-btn-group>.ant-btn-group:last-child:not(:first-child)>.ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-btn-rtl.ant-btn-group .ant-btn+.ant-btn,
.ant-btn-rtl.ant-btn+.ant-btn-group,
.ant-btn-rtl.ant-btn-group span+.ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn+span,
.ant-btn-rtl.ant-btn-group>span+span,
.ant-btn-rtl.ant-btn-group+.ant-btn,
.ant-btn-rtl.ant-btn-group+.ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn+.ant-btn,
.ant-btn-group-rtl.ant-btn+.ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span+.ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn+span,
.ant-btn-group-rtl.ant-btn-group>span+span,
.ant-btn-group-rtl.ant-btn-group+.ant-btn,
.ant-btn-group-rtl.ant-btn-group+.ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}

.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group>.ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group>span:first-child:not(:last-child)>.ant-btn {
  border-radius: 0 2px 2px 0;
}

.ant-btn-group-rtl.ant-btn-group>.ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group>span:last-child:not(:first-child)>.ant-btn {
  border-radius: 2px 0 0 2px;
}

.ant-btn-group-rtl.ant-btn-group-sm>.ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm>span:first-child:not(:last-child)>.ant-btn {
  border-radius: 0 2px 2px 0;
}

.ant-btn-group-rtl.ant-btn-group-sm>.ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm>span:last-child:not(:first-child)>.ant-btn {
  border-radius: 2px 0 0 2px;
}

.ant-btn:focus>span,
.ant-btn:active>span {
  position: relative;
}

.ant-btn>.anticon+span,
.ant-btn>span+.anticon {
  margin-left: 8px;
}

.ant-btn.ant-btn-background-ghost {
  color: #fff;
  border-color: #fff;
}

.ant-btn.ant-btn-background-ghost,
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:active,
.ant-btn.ant-btn-background-ghost:focus {
  background: transparent;
}

.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}

.ant-btn.ant-btn-background-ghost:active {
  color: #096dd9;
  border-color: #096dd9;
}

.ant-btn.ant-btn-background-ghost[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-primary {
  color: #1890ff;
  border-color: #1890ff;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-primary>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-primary>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}

.ant-btn-background-ghost.ant-btn-primary:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-primary:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-primary:active {
  color: #096dd9;
  border-color: #096dd9;
}

.ant-btn-background-ghost.ant-btn-primary:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-primary:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-primary[disabled]>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-primary[disabled]>a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus>a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-danger>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-danger>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  border-color: #ff7875;
}

.ant-btn-background-ghost.ant-btn-danger:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-danger:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9363e;
  border-color: #d9363e;
}

.ant-btn-background-ghost.ant-btn-danger:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-danger:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-danger[disabled]>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-danger[disabled]>a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus>a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
}

.ant-btn-background-ghost.ant-btn-dangerous:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled]>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled]>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  border-color: transparent;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: transparent;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: transparent;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}

.ant-btn-two-chinese-chars>*:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}

.ant-btn.ant-btn-block {
  width: 100%;
}

.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}

a.ant-btn {
  padding-top: 0.01px !important;
  line-height: 30px;
}

a.ant-btn-disabled {
  cursor: not-allowed;
}

a.ant-btn-disabled>* {
  pointer-events: none;
}

a.ant-btn-disabled,
a.ant-btn-disabled:hover,
a.ant-btn-disabled:focus,
a.ant-btn-disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}

a.ant-btn-disabled>a:only-child,
a.ant-btn-disabled:hover>a:only-child,
a.ant-btn-disabled:focus>a:only-child,
a.ant-btn-disabled:active>a:only-child {
  color: currentcolor;
}

a.ant-btn-disabled>a:only-child::after,
a.ant-btn-disabled:hover>a:only-child::after,
a.ant-btn-disabled:focus>a:only-child::after,
a.ant-btn-disabled:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

a.ant-btn-lg {
  line-height: 38px;
}

a.ant-btn-sm {
  line-height: 22px;
}

.ant-btn-rtl {
  direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary+.ant-btn-primary {
  border-right-color: #40a9ff;
  border-left-color: #d9d9d9;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary+.ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #40a9ff;
}

.ant-btn-rtl.ant-btn>.ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}

.ant-btn-rtl.ant-btn>.anticon+span,
.ant-btn-rtl.ant-btn>span+.anticon {
  margin-right: 8px;
  margin-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-picker-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum", "tnum";
          font-feature-settings: 'tnum', "tnum", "tnum";
  background: #fff;
}

.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}

.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px;
}

.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
}

.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: auto;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%;
}

.ant-picker-calendar-mini {
  border-radius: 2px;
}

.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 2px 2px;
}

.ant-picker-calendar-mini .ant-picker-content {
  height: 256px;
}

.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}

.ant-picker-calendar-mini .ant-picker-cell::before {
  pointer-events: none;
}

.ant-picker-calendar-full .ant-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
  display: none;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before {
  display: none;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #e6f7ff;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #1890ff;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  transition: background 0.3s;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  text-align: left;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #1890ff;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: rgba(0, 0, 0, 0.85);
}

@media only screen and (max-width: 480px) {
  .ant-picker-calendar-header {
    display: block;
  }

  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%;
  }

  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px);
  }

  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }

  .ant-picker-calendar-header .ant-picker-calendar-mode-switch>label {
    width: 50%;
    text-align: center;
  }
}

.ant-picker-calendar-rtl {
  direction: rtl;
}

.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}

.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #ff4d4f;
}

.ant-picker-status-error.ant-picker-focused,
.ant-picker-status-error.ant-picker:focus {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-picker-status-error.ant-picker .ant-picker-active-bar {
  background: #ff7875;
}

.ant-picker-status-warning.ant-picker,
.ant-picker-status-warning.ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #faad14;
}

.ant-picker-status-warning.ant-picker-focused,
.ant-picker-status-warning.ant-picker:focus {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-picker-status-warning.ant-picker .ant-picker-active-bar {
  background: #ffc53d;
}

.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 40px;
  width: 100%;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum", "tnum";
          font-feature-settings: 'tnum', "tnum", "tnum";
  padding: 4px 11px 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: 0px solid #d9d9d9;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #40a9ff00;
  border-right-width: 1px;
}

.ant-picker-focused {
  border-color: #40a9ff00;
  /* box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); */
  border-right-width: 1px;
  outline: 0;
}

.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: rgba(0, 0, 0, 0.25);
}

.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.ant-picker-input>input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  outline: none;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}

.ant-picker-input>input::-webkit-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-picker-input>input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-picker-input>input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-picker-input>input:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-picker-input>input:focus,
.ant-picker-input>input-focused {
  border-color: #40a9ff00;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-picker-input>input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-picker-input>input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-picker-input>input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-picker-input>input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-picker-input>input-borderless,
.ant-picker-input>input-borderless:hover,
.ant-picker-input>input-borderless:focus,
.ant-picker-input>input-borderless-focused,
.ant-picker-input>input-borderless-disabled,
.ant-picker-input>input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

textarea.ant-picker-input>input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}

.ant-picker-input>input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-picker-input>input-sm {
  padding: 0px 7px;
}

.ant-picker-input>input:focus {
  box-shadow: none;
}

.ant-picker-input>input[disabled] {
  background: transparent;
}

.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}

.ant-picker-input-placeholder>input {
  color: #bfbfbf;
}

.ant-picker-large {
  padding: 6.5px 11px 6.5px;
}

.ant-picker-large .ant-picker-input>input {
  font-size: 16px;
}

.ant-picker-small {
  padding: 0px 7px 0px;
}

.ant-picker-suffix {
  display: flex;
  flex: none;
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
}

.ant-picker-suffix>* {
  vertical-align: top;
}

.ant-picker-suffix>*:not(:last-child) {
  margin-right: 8px;
}

.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: transparent;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}

.ant-picker-clear>* {
  vertical-align: top;
}

.ant-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}

.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}

.ant-picker-focused .ant-picker-separator {
  color: rgba(0, 0, 0, 0.45);
}

.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}

.ant-picker-range {
  position: relative;
  display: inline-flex;
}

.ant-picker-range .ant-picker-clear {
  right: 11px;
}

.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}

.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #1890ff;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}

.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1;
}

.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}

.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}

.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}

.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum", "tnum";
          font-feature-settings: 'tnum', "tnum", "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
}

.ant-picker-dropdown-hidden {
  display: none;
}

.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 2.58561808px;
  display: block;
  -webkit-transform: rotate(-135deg) translateY(1px);
          transform: rotate(-135deg) translateY(1px);
}

.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 2.58561808px;
  display: block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
}

.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}

.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
}

.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
}

.ant-picker-dropdown-range {
  padding: 7.54247233px 0;
}

.ant-picker-dropdown-range-hidden {
  display: none;
}

.ant-picker-dropdown .ant-picker-panel>.ant-picker-time-panel {
  padding-top: 4px;
}

.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}

.ant-picker-ranges>li {
  display: inline-block;
}

.ant-picker-ranges .ant-picker-preset>.ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
  cursor: pointer;
}

.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}

.ant-picker-range-wrapper {
  display: flex;
}

.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 11.3137085px;
  height: 11.3137085px;
  margin-left: 16.5px;
  box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-out;
  border-radius: 0 0 2px;
  pointer-events: none;
}

.ant-picker-range-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: inset(33% 33%);
  clip-path: inset(33% 33%);
  -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}

.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #000;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
}

.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}

.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 0px 0;
  border-radius: 0;
}

.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
  text-align: center;
}

.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0;
}

.ant-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
}

.ant-picker-panel-focused {
  border-color: #1890ff;
}

.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #f0f0f0;
}

.ant-picker-header>* {
  flex: none;
}

.ant-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-picker-header>button {
  min-width: 1.6em;
  font-size: 14px;
}

.ant-picker-header>button:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}

.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}

.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}

.ant-picker-header-view button:hover {
  color: #1890ff;
}

.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}

.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}

.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}

.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.ant-picker-next-icon,
.ant-picker-super-next-icon {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}

.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}

.ant-picker-content th {
  height: 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
}

.ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.ant-picker-cell-in-view {
  color: rgba(0, 0, 0, 0.85);
}

.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}

.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #1890ff;
  border-radius: 2px;
  content: '';
}

.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #e6f7ff;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #1890ff;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #e6f7ff;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #7ec1ff;
  border-bottom: 1px dashed #7ec1ff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}

.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel> :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel> :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #cbe6ff;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #cbe6ff;
  transition: all 0.3s;
  content: '';
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}

.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%;
}

.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%;
}

tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #7ec1ff;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #7ec1ff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}

.ant-picker-cell-disabled .ant-picker-cell-inner {
  background: transparent;
}

.ant-picker-cell-disabled::before {
  background: rgba(0, 0, 0, 0.04);
}

.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.25);
}

.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
  height: 264px;
}

.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px;
}

.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}

.ant-picker-footer {
  width: -webkit-min-content;
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}

.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #a885d8
}

.ant-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left;
}

.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 0px solid #f0f0f0;
}

.ant-picker-now {
  text-align: left;
}

.ant-picker-today-btn {
  color: #1890ff;
}

.ant-picker-today-btn:hover {
  color: #40a9ff;
}

.ant-picker-today-btn:active {
  color: #096dd9;
}

.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}

.ant-picker-decade-panel .ant-picker-cell::before {
  display: none;
}

.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
}

.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px;
}

.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
}

.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0;
}

.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0;
}

.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
}

.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px;
}

.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: transparent !important;
}

.ant-picker-week-panel-row td {
  transition: background 0.3s;
}

.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}

.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #1890ff;
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff;
}

.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}

.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}

.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}

.ant-picker-date-panel .ant-picker-content th {
  width: 36px;
}

.ant-picker-datetime-panel {
  display: flex;
}

.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0;
}

.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity 0.3s;
}

.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3;
}

.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}

.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}

.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 120px;
}

.ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}

.ant-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: '';
}

.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 198px;
}

.ant-picker-time-panel-column:not(:first-child) {
  border-left: 0px solid #f0f0f0;
}

.ant-picker-time-panel-column-active {
  background: rgba(230, 247, 255, 0.2);
}

.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}

.ant-picker-time-panel-column>li {
  margin: 0;
  padding: 0;
  font-family: 'Inter';
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: white;
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: var(--light-brown);
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #000;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}

/* stylelint-disable selector-type-no-unknown,selector-no-vendor-prefix */
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}

.ant-picker-rtl {
  direction: rtl;
}

.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}

.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0;
}

.ant-picker-rtl .ant-picker-separator {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}

.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 11px;
}

.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 11px;
  margin-left: 0;
}

.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}

.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}

.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}

.ant-picker-panel-rtl {
  direction: rtl;
}

.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}

.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0;
  left: 50%;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}

.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}

.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}

.ant-picker-panel-rtl tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #7ec1ff;
  border-left: none;
  border-radius: 0 2px 2px 0;
}

.ant-picker-panel-rtl tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
}

.ant-picker-panel-rtl tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl tr>.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.ant-picker-panel-rtl tr>.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed #7ec1ff;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px;
}

.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right;
}

.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}

.ant-picker-ok button {
  border-color: #A885D7;
  background: #A885D7;
}

.ant-picker-suffix {
  display: none;
}

.modalSendAha.colorPurple .modal-dialog {
  width: 400px;
}

.modalSendAha.colorPurple .modal-content {
  background: var(--simple-brown-light);
}

.modalSendAha .modal-title {
  font-size: 18px;
  color: white;
  font-weight: 700;
  font-family: 'Inter';
}

.countAha {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  color: white;
  font-family: 'Inter';
  margin: 16px 0;
}

.countAha .aha-logo-text-post {
  width: 60px !important;
  height: 60px !important;
  margin: 0 !important;
}

.containerAha {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
}

.containerAha label {
  text-align: left;
  color: var(--lavender);
  font-family: 'Inter';
  font-weight: 700;
}

.containerAha .inputSend {
  width: 100%;
  margin: 5px 0 20px 0;
  background-color: #291159;
  border-radius: 4px;
}

.containerSend .inputSend {
  display: flex;
}

.containerAha .inputSend input {
  width: 100%;
  background-color: #291159;
  border-radius: 4px;
  border: 0;
  height: 40px;
  padding: 10px;
  color: white;
  font-size: 16px;
}

.containerAha .inputSend select {
  width: 100%;
  background-color: #291159;
  border-radius: 4px;
  border: 0;
  height: 40px;
  padding: 10px;
  color: white;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.containerAha .inputSend textarea {
  width: 100%;
  background-color: #291159;
  border-radius: 4px;
  border: 0;
  height: 40px;
  padding: 10px;
  color: white;
  font-size: 16px;
  font-family: 'Inter';
}

.containerAha .countLetters {
  font-size: 12px;
  color: var(--lavender);
  font-family: 'Inter';
  text-align: right;
  width: 100%;
  display: block;
  padding: 5px 10px 7px 0;
}

.modalSendAha .modal-footer button {
  color: white;
  width: 100%;
  padding: 10px 0;
  background-color: var(--electric-yellow);
  border: 0;
  border-radius: 6px;
  font-family: 'Inter';
  cursor: pointer;
}

.containerReason {
  position: relative;
}

.containerReason::before {
  content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down fa-lg chevron-down ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' color='%23a885d7' data-v-2b2d6bb2=''%3E%3Cpath fill='currentColor' d='M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z'%3E%3C/path%3E%3C/svg%3E");
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  -webkit-appearance: none;
          appearance: none;
  /*
  font-family: var(--font-family-font_awesome_5_pro-regular);
  */
  color: var(--lavender);
  font-size: 18px;
  top: 31px;
  right: -9px;
}

.containerProfile .infoPostHeader {
  padding: 0 !important;
  margin-bottom: 16px;
  align-items: center;
  display: flex !important;
  flex-direction: inherit !important;
}

.containerProfile {
  display: flex;
}

.modalSendCAha .labelEventCreated {
  font-size: 16px;
  color: white;
  font-family: 'Inter';
  text-align: center;
}

.modalSendCAha .bCancel,
.modalSendCAha .bSend {
  width: 48%;
  height: 40px;
  border: 0;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.modalSendCAha .bSend {
  background-color: #590CF6;
  color: white;
}

.modalSendCAha .Modalexclamation-circle {
  font-size: 50px;
  margin-bottom: 20px;
}

.modalSendCAha .modal-dialog {
  width: 470px;
}

.DisabledButtonSendAha {
  opacity: 0.3;
  cursor: no-drop !important;
}

.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.modalConnection {
  /* background-color: #ff000000 !important; */
}

.modalConnection .modal-dialog {
  align-items: center;
  background: #250d44;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 235px;
}

.modalConnection .modal-dialog,
.modalConnection .modal-content {
  background-color: var(--white) !important;
  border-radius: 5px;
}

.modalConnection .modal-content {
  width: 100%;
}

.modalConnection .imageProfile img {
  background-color: #fff;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  border: 2px solid var(--t-blue);
}

.modalConnection .nameClass,
.modalConnection .nameFriend {
  color: var(--t-blue);
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  text-align: center;
}

.modalConnection .nameFriend {
  font-weight: 700;
  padding: 5px 0;
  cursor: pointer;
}

.modalConnection .nameClass {
  font-weight: 400;
}

.modalConnection .nameClass,
.modalConnection .nameFriend {
  color: var(--t-blue);
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  text-align: center;
}

.modalConnection .infoTabs {
  color: var(--t-blue);
  font-family: 'Inter';
  font-size: 12px;
  height: 35px;
  line-height: 16px;
  margin-top: 10px;
  overflow: scroll;
  overflow-x: hidden;
}

.modalConnection .buttonsConnections {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  width: 100%;
}

.modalConnection .buttonsConnections button {
  border: 0;
  border-radius: 12px;
  padding: 10px 0;
  cursor: pointer;
  font-size: 16px;
  padding: 9px 0;
}

.modalConnection .buttonAcceptFriend {
  background-color: var(--t-blue);
  color: #fff;
  width: 47%;
}

.modalConnection .buttonRejectFriend {
  background-color: #fff;
  color: var(--t-blue);
  border: 1px solid var(--t-blue) !important;
  width: 47%;
}

.modalConnection .buttonCancelRequest {
  background-color: var(--t-blue);
  color: #fff;
  width: 100%;
}

.modalConnection .buttonCancelRequest {
  background-color: var(--t-blue);
  color: #fff;
  width: 100%;
}

.modalConnection .buttonAddRequest {
  background-color: var(--t-blue);
  color: #fff;
  width: 100%;
}

.modalConnection .buttonFriends {
  background-color: var(--t-blue);
  color: #fff;
  width: 100%;
  border-radius: 12px;
  padding: 10px 0;
}

.modalConnection .buttonFriends svg {
  margin: 0 10px 0 0;
}

.modalConnection .icon-times {
  position: absolute;
  top: 0;
  right: 0;
}

.modalConnection .icon-comment {
  position: absolute;
  top: 0;
  left: 0;
}

.modalConnection .nameFriend h2 {
  cursor: pointer;
}

.modalConnection .nameFriend h2:hover {
  text-decoration: underline;
}

.modalDetailClub .buttonCancelRequest {
  background: #FFFFFF;
  border: 1px solid #7400F9;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 14px 20px;
  width: 150px;
  color: #7400F9;
  cursor: pointer;
  width: 100% !important;
}

.buttonCancelRequest .buttonSendSignUp {
  border-bottom-color: #34266b !important;
}

.infoCommonClub {
  color: var(--t-blue);
  font-family: 'Inter';
  font-size: 12px;
  margin-top: 10px;
}

/* Styles new modal vibe */

.modalEventDetail {
  background-color: #00000063;
}

.modalEventDetail .modal-dialog {
  width: 50vw;
}

.modalEventDetail .modal-header {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.modalEventDetail .modal-body {
  padding: 0px 24px 24px 24px;
}

.modalEventDetail .modal-title {
  color: #282A2F;
  font-family: 'Inter-Bold';
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.1px;
}

.modalShareVibes .modal-content{
  padding: 0px;
}

.modalShareVibes .modal-body {
    padding: 15px 24px;
}

.modalShareVibes .modal-dialog{
  width: 470px;
}

.modalShareVibes .modal-header{
  font-family: 'Inter-Bold';
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  border-bottom: 1px solid #BFBFBF;
}

.modalContributor {
  background-color: #00000063;
}

.modalContributor .modal-content {
  background: white;
  border-radius: 12px;
  padding: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 600px;
  max-width: 60vw;
  min-width: 500px;
  height: 60vh;
}

.modalContributor .modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  font-size: 20px;
  font-family: 'Inter-bold';
  text-align: left;
}

.modalContributor .modal-body {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  height: 50vh;
  overflow: scroll;
}

.modalContributor .modal-footer {
  border-top: 1px solid var(--simple-gray-2);
  width: 100%;
  background-color: var(--white);
  display: flex;
  gap: 10px;
}

.modalContributorSA {
  background-color: #00000063;
}

.modalContributorSA .modal-content {
  background: white;
  border-radius: 12px;
  padding: 10px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  width: 600px;
  max-width: 60vw;
  min-width: 500px;
  height: 50vh;
}

.modalContributorSA .modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  font-size: 20px;
  font-family: 'Inter-bold';
  text-align: left;
}

.modalContributorSA .modal-body {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  height: 100%;
  overflow: scroll;
}

.modalContributorSA .modal-footer {
  border-top: 1px solid var(--simple-gray-2);
  width: 100%;
  background-color: var(--white);
  display: flex;
  gap: 10px;
}

.modalArticle {
  background-color: #00000063;
}

.modalArticle .modal-content {
  background: white;
  border-radius: 12px;
  padding: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 510px;
  max-width: 60vw;
  min-width: 300px;
  height: 90vh;
}

.modalArticle .modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  font-size: 20px;
  font-family: 'Inter-bold';
}

.modalArticle .modal-body {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  height: 80vh;
  overflow: scroll;
}

.modalArticle .modal-footer {
  border-top: 1px solid var(--simple-gray-2);
  width: 100%;
  background-color: var(--white);
  display: flex;
  gap: 10px;
}

.modalViewArticle {
  background-color: #00000063;
}

.modalViewArticle .modal-content {
  background: white;
  border-radius: 12px;
  padding: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 510px;
  max-width: 60vw;
  min-width: 300px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 90vh;
}

.modalViewArticle .modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  font-size: 20px;
  font-family: 'Inter-bold';
}

.modalViewArticle .modal-body {
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
  height: 100%;
  overflow: scroll;
  font-size: 16px;
  font-family: 'Inter';
  width: 100%;
  text-align: left;
}

.modalViewArticle .modal-footer {
  border-top: 1px solid var(--simple-gray-2);
  width: 100%;
  background-color: var(--white);
  display: flex;
  gap: 10px;
}

.modalPurchaseEvent {
  background-color: #00000063;
}

.modalPurchaseEvent .modal-content {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 510px;
  max-width: 60vw;
  min-width: 300px;
  max-height: 90vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  padding: 0px;
}

.modalPurchaseEvent .modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  font-size: 20px;
  font-family: 'Inter-bold';
}

.modalPurchaseEvent .modal-header{
  border-bottom: 1px solid var(--simple-gray);
}

.modalPurchaseEvent .notConfirm{
  border-bottom: 0px;
}

.modalPurchaseEvent .modal-body {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: 100%;
  overflow: scroll;
  text-align: center;
  font-size: 18px;
}

.modalPurchaseEvent .modal-footer {
  border-top: 1px solid var(--simple-gray);
  width: 100%;
  background-color: var(--white);
  display: flex;
  gap: 10px;
}

.modalCertification {
  background-color: #00000063;
}

.modalCertification .modal-header{
  padding-bottom: 0px;
}

.modalCertification .modal-content {
  background: white;
  border-radius: 12px;
  padding: 10px;
  overflow: hidden;

  width: 585px;
  min-width: 420px;
}

.modalCertification .modal-title {
  display: flex;
  align-items: center;
}

.modalCertification .modal-body {
  padding: 0;
}

.modalCertification .modal-footer {
  position: absolute;
  bottom: 0;
  border-top: 1px solid var(--simple-gray-2);
  width: 100%;
  background-color: var(--white);
  display: flex;
}

.modalCertification .modal-title svg {
  margin-left: 20px;
}

.modalInfoPayment {
  background-color: #00000063;
}

.modalInfoPayment .modal-header{
  padding: 32px 0px 10px 0px;
}

.modalInfoPayment .modal-content {
  background: white;
  border-radius: 12px;
  padding: 10px;
  overflow: hidden;

  width: 585px;
  min-width: 420px;
}

.modalInfoPayment .modal-title {
  display: flex;
  align-items: center;
  background-color: var(--simple-gray-2);
  border-radius: 5px;
  gap: 15px;
  padding: 10px;
  width: 100%;
}

.modalInfoPayment .modal-body {
  padding: 0;
}

.modalInfoPayment .modal-footer {
  position: absolute;
  bottom: 0;
  border-top: 1px solid var(--simple-gray-2);
  width: 100%;
  background-color: var(--white);
  display: flex;
}

.modalSetup {
  background-color: #00000063;
}

.modalSetup .modal-content {
  background: white;
  border-radius: 12px;
  padding: 10px;
  overflow: hidden;

  width: 585px;
  min-width: 420px;
}

.modalMedia .modal-content {
  height: 80vh !important;
}

.modalSetup .modal-title {
  display: flex;
  align-items: center;
}

.modalSetupCommunities .modal-title {
  display: flex;
  align-items: flex-start;
}

.modalSetup .modal-title svg {
  margin-left: 20px;
}

.modalSetupCommunities .modal-title svg {
  margin-left: 0px;
}

.modalSetup .modal-body {
  padding: 0;
}

.modalSetup .modal-footer {
  position: absolute;
  bottom: 0;
  border-top: 1px solid var(--simple-gray-2);
  width: 100%;
  background-color: var(--white);
  display: flex;
}

.modalAdd.modal {
  background-color: #00000063;
}

.modalAdd .modal-content {
  padding: 24px 0;
  width: 95%;
  margin: 0 auto;
}

.modalAdd .modal-header,
.modalAdd .modal-body {
  padding: 0 24px;
}

.modalAdd .modal-footer {
  padding: 24px 24px 0px 24px;
  border-top: 1px solid var(--simple-gray-2);
  margin-top: 24px;
}

@media only screen and (max-width: 500px) {
  .buttonLetsVibe {
    width: 100%;
  }
  .modalMS .modal-dialog {
    width: 95% !important;
  }
}


.modalMS .modal-dialog {
  width: auto;
}

.modalMS .modal-body {
  padding: 0 35px;
  display: flex;
  gap: 35px;
}

.modalTemplates .modal-dialog {
  width: auto;
}

.modalTemplates .modal-content {
  padding: 20px;
}

.modalTemplates .modal-header {
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.modalProfile {
  background-color: #00000063;
}

.modalProfile .modal-dialog {
  width: auto;
}

.modalProfile .modal-body {
  padding: 0px;
  display: flex;
  gap: 35px;
}

.modalProfile .modal-header {
  padding: 0px;
}

.modalProfile .modal-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.modalTeam {
  background-color: #00000063;
}

.modalTeam .modal-dialog {
  width: 40vw;
}

.modalTeam .modal-body {
  padding: 0px 5px;
}

.modalTeam .modal-header {
  padding: 0px 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 15px;
}

.modalTeam .modal-footer {
  padding: 15px 5px;
}

.modalMod {
  background-color: #00000063;
}

.modalMod .modal-content {
  max-width: 70vw;
  max-height: 90vh;
  height: 90vh;
  min-width: 70vw;
  min-height: 90vh;
}

.modalMod .modal-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(90vh - 80px);
  overflow: hidden;
}

.modalMod .modal-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.modalFeatured {
  background-color: #00000063;
}

.modalFeatured {
  background-color: #00000063;
}

.modalFeatured .modal-content {
  width: 50vw;
  box-shadow: none;
  background-color: transparent;
  border: none;
  z-index: 1000000;
}

.modalHealers .modal-dialog {
  width: 400px;
}

.modalHealers .modal-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  gap: 0;
  height: 50vh;
  overflow: scroll;
}

.modalHealers .modal-content{
  padding: 0;
}

.modalHealers .modal-header {
  padding: 17px;
}

.modalCreateEvent {
  background-color: #00000063;
}

.modalRefer {
  background-color: #00000063;
}

.modalRefer .modal-dialog {
  width: 35vw;
  height: 90vh;
  overflow: hidden;
  top: 55vh;
}

.ModalPayForm {
  background-color: #00000063;
}

.ModalPayForm .modal-content{
  min-height: 90vh;
}

.ModalPayForm .modal-header{
  padding: 20px 20px 0px 20px;
}

.ModalPayForm .modal-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ModalPayForm .modal-body {
  max-height: calc(90vh - 225px);
  overflow: scroll;
}

.ModalAddPayment {
  background-color: #00000063;
}

.ModalAddPayment .modal-content{
  min-height: 60vh;
  min-width: 40vw;
}

.ModalAddPayment .modal-header{
  padding: 20px 20px 0px 20px;
}

.ModalAddPayment .modal-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ModalAddPayment .modal-body {
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ModalSuccessPay {
  background-color: #00000063;
}

.ModalSuccessPay .modal-content{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.ModalSuccessPay .modal-header{
  padding: 20px 20px 0px 20px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.ModalSuccessPay .modal-body {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  color: #333;

  text-align: center;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modalRefer .modal-footer {
  padding: 15px 15px;
}

.modalRefer .modal-body {
  padding: 0px 15px;
  height: calc(90vh - 230px);
}

.modalRefer .modal-title {
  color: var(--default-dark);
  text-align: center;
  font-family: 'Inter';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.fade.modalShareVibes.modal.show{
  background-color: #00000063 !important;
}

.inputDatePicker {
  padding-left: 15px;
  height: 38px;
  border-radius: 12px;
  width: calc(100% + 35px);
  background-color: transparent;
  z-index: 10000;
}

.datePickerYearMonth .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: black;
  padding: 14px 0;
  font-size: 16px;
}

.datePickerYearMonth .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
  display: none
}

.datePickerYearMonth .react-datepicker__navigation--previous {
  left: 20px;
}

.datePickerYearMonth .react-datepicker__navigation--next {
  right: 20px;
}

.datePickerYearMonth .react-datepicker__year-read-view--down-arrow,
.datePickerYearMonth .react-datepicker__month-read-view--down-arrow,
.datePickerYearMonth .react-datepicker__month-year-read-view--down-arrow {
  top: 2px;
}

.datePickerYearMonth .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  width: 10px;
  height: 10px;
  border: 1px solid #e89f4d;
  border-right: 0;
  border-bottom: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 4px;
  margin: 6px auto;
}

.datePickerYearMonth .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  width: 10px;
  height: 10px;
  border: 1px solid #e89f4d;
  border-left: 0;
  border-top: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: -4px;
  margin: 6px auto;
}

.datePickerYearMonth .react-datepicker__input-container{
  display: flex;
}

.datePickerYearMonth .inputDatePicker{
  padding-left: 20px;
  font-size: 16px;
}

.datePickerYearMonth .react-datepicker__year-dropdown{
  height: 270px;
  overflow: scroll;
}

.datePickerYearMonthList{
  border: 0 !important;
}

@media only screen and (max-width: 700px) {
  .modalRefer .modal-dialog {
    width: 100%;
    display: flex;
    height: calc(100vh - 3px);
  }

  .modalRefer .modal-content {
    overflow: auto;
    width: 100%;
  }

  .modalSeePayment .modal-dialog {
    width: 90vw;
  }

  .datePickerYearMonth .inputDatePicker{
    background-color: transparent;
    width: calc(100% - 20px);
  }

  .datePickerYearMonth svg{
    position: absolute;
    right: 10px;
  }

  .react-datepicker__input-container{
    width: calc(100% - 20px);
  }
}

.modalCreateEventbrite .jodit-status-bar__item.jodit-status-bar__item-right{
  position: absolute;
  bottom: 2px;
  right: 9px;
}

.modalCreateEventbrite .jodit-toolbar__box{
  max-height: 72px;
  min-height: 38px;
}

.modalCreateEventbrite {
  background-color: #00000063;
}

.modalCreateEventbrite .modal-dialog {
  width: 60vw;
  height: 60vh;
}

.modalCreateEventbrite .modal-body {
  padding: 0 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.modalCreateEventbrite .modal-content {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.modalCreateEventbrite .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 0px 24px;
}

.modalCreateEventbrite .modal-footer {
  gap: 16px;
  padding: 14px 24px 24px 24px;
}

.modalCreateEventbrite .react-datepicker__input-container {
  text-align: left;
}

.modalCreateNewEvent {
  background-color: #00000063;
}

.modalCreateNewEvent .modal-dialog {
  width: 65vw;
  height: 80vh;
}

.modalCreateNewEvent .modal-body {
  padding: 0 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.modalCreateNewEvent .modal-content {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.modalCreateNewEvent .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 0px 24px;
}

.modalCreateNewEvent .modal-footer {
  gap: 16px;
  padding: 14px 24px 24px 24px;
  border-top: 1px solid #b3b3b3;
}

.modalCreateNewEvent .react-datepicker__input-container {
  text-align: left;
}

.modalSearchWarning .jodit-status-bar__item.jodit-status-bar__item-right{
  position: absolute;
  bottom: 2px;
  right: 9px;
}

.modalSearchWarning .jodit-toolbar__box{
  max-height: 72px;
  min-height: 50px;
}

.modalSearchWarning .modal-dialog {
  width: 53vw;
  height: 35vh;
}

.modalSearchWarning .modal-body {
  padding: 0 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
  /* height: 100%; */
}

.modalSearchWarning .modal-content {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* height: 100%; */
}

.modalSearchWarning .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 0px 24px;
}

.modalSearchWarning .modal-footer {
  gap: 16px;
  padding: 14px 24px 24px 24px;
  border-top: 1px solid #b3b3b3;
}

.modalSearchWarning .react-datepicker__input-container {
  text-align: left;
}

@media only screen and (max-width: 900px) {
  .modalMod {
    z-index: 9999;
  }

  .modalMod .modal-dialog {
    width: 100%;
    display: flex;
    bottom: 0;
    -webkit-transform: none;
            transform: none;
    top: auto;
    left: auto;
  }

  .modal-content {
    width: 100%;
  }
}

.modalEventBrite .modal-dialog{
  width: 580px;
}

.modalEventBrite .modal-body{
  padding: 0;
}

.modalEventBrite .modal-footer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.modalEventBrite .modal-content{
  padding: 24px;
}

.modalEventBrite .modal-header{
  padding: 0 0 24px 0;
}

.lineLink{
  text-decoration: underline;
  cursor: pointer;
}

.listTop ul{
  top: -200px;
}

.heightAuto{
  height: auto !important;
}

/*Modal Filters*/

.modalFilters .modal-dialog {
  top: 50%;
  left: 50%;
  max-width: 300px;
}

.modalFiltersLeftPanelCommunities .modal-dialog {
  top: 340px;
  left: calc(25% - 125px);
  max-width: 300px;
}

.modalFiltersLeftPanelCommunitiesAdmin .modal-dialog {
  top: 530px;
  left: calc(25% - 100px);
  max-width: 300px;
}

.modalFiltersLeftPanelEvents .modal-dialog {
  top: 430px;
  left: calc(25% - 125px);
  max-width: 300px;
}

.modalFiltersLeftPanelEventsAdmin .modal-dialog {
  top: 630px;
  left: calc(25% - 100px);
  max-width: 300px;
}


.modalFiltersProfileModalities .modal-dialog {
  top: 500px;
  left: calc(25% - 100px);
  max-width: 300px;
}

.modalFiltersProfileEvents .modal-dialog {
  top: 310px;
  left: calc(100% - 300px);
  max-width: 300px;
}

.modalFiltersProfileCommunities .modal-dialog {
  top: 310px;
  left: calc(78% - 300px);
  max-width: 300px;
}

.modalFiltersModalityCommunities .modal-dialog {
  top: 360px;
  left: calc(78% - 300px);
  max-width: 300px;
}

.modalFiltersModalityEvents .modal-dialog {
  top: 460px;
  left: calc(100% - 300px);
  max-width: 300px;
}

@media (max-width: 900px){
  .modalFiltersProfileModalities .modal-dialog {
    top: 0;
    left: 0;
    max-width: 300px;
    z-index: 300000000;
  }
  
  .modalFiltersProfileEvents .modal-dialog {
    top: 450px;
    left: calc(100% - 200px);
    max-width: 300px;
  }
  
  .modalFiltersProfileCommunities .modal-dialog {
    top: 350px;
    left: calc(100% - 200px);
    max-width: 300px;
  }
  
  .modalFiltersModalityCommunities .modal-dialog {
    top: 330px;
    left: calc(100% - 200px);
    max-width: 300px;
  }
  
  .modalFiltersModalityEvents .modal-dialog {
    top: 330px;
    left: calc(100% - 200px);
    max-width: 300px;
  }
}