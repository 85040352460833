.container-layout[data-v-60429785] {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
}
.club-content[data-v-60429785] {
  flex: 1 1;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 1024px;
  position: relative;
  width: 100%;
  background-color: var(--light-brown);
}
.screen a[data-v-60429785] {
  display: contents;
  text-decoration: none;
}
.club-center[data-v-60429785] {
  width: calc(100% - 770px);
  min-height: 1000px;
  /* background-color: var(--light-brown); */
  padding: 10px;
  margin-top: 95px;
}
