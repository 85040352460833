.container-members-club{
    width: 100%;
    padding: 0 30px;
    font-family: 'Inter';
}

.listMembersClub {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.listMembersClub > div {
    width: 45%;
    display: flex;
    padding: 15px;
    align-items: center;
    position: relative;
    gap: 5px;
}

/* .listMembersClub img {
    width: 50px;
    border-radius: 50%;
} */

.listMembersClub p {
    color: var(--t-blue);;
    padding-left: 15px;
    font-family: var(--font-family-roboto_condensed);
}

.listMembersClub button {
    position: absolute;
    top: 30px;
    right: 0;
}

.cardMember button{
    border: 0;
    background-color: transparent;
}

.count-members{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}

.count-members p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--t-blue);  
}

.membersClubHeader{
    margin: 0;
}

.membersClubHeader .filterButton{
    border: 0;
    background-color: transparent;
    margin-left: 15px;
}

.buttonInviteMembersClub{
    top: 0;
    right: 0px;
    width: 157px;
    height: 44px;
    background: var(--t-blue);
    border-radius: 12px;
    border: 0;
    color: white;
    cursor: pointer;
}

.container-members-club input::-webkit-input-placeholder, .container-members-club textarea::-webkit-input-placeholder {
    color: white;
    font-family: var(--font-family-roboto_condensed);
    font-size: 14px;
}

.container-members-club input::placeholder,
.container-members-club textarea::placeholder {
    color: white;
    font-family: var(--font-family-roboto_condensed);
    font-size: 14px;
}

.ClubMember-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left;
}

.ClubMember-Name{
    color: var(--t-blue) !important;
    font-weight: 700;
}

.ClubMember-Position{
    color: var(--t-blue) !important;
}