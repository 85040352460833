.row[data-v-af6af48b]{
    margin-bottom: 20px;
}
.labelInfo[data-v-af6af48b]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: var(--t-blue);
    width: 100%;
    text-align: left;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.labelTitle[data-v-af6af48b]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 5px;
    color: var(--t-blue); 
    text-align: left;
}
.buttonAdd[data-v-af6af48b]{
    padding: 10px 15px;
    background: var(--t-blue);
    color: white;
    border-radius: 12px;
    border: 0;
}
.inputInfo textarea[data-v-af6af48b]{
    width: 100%;
    background: var(--t-blue);
    border-radius: 3px;
    color: white;
    border: 0;
    height: 70px;
    outline: none;
}
.officer[data-v-af6af48b] {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}
.profileOfficer[data-v-af6af48b]{
    width: 30%;
    color: var(--t-blue);
    font-family: 'Inter';
    line-height: 18px;
}
.nameOfficer[data-v-af6af48b] {
    margin: 0 !important;
    padding: 0 !important;
    font-weight: 700;
}
.professionOfficer[data-v-af6af48b] {
    margin: 0 !important;
    padding: 0 !important;
}
.emailOfficer[data-v-af6af48b]{
    width: 70%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: var(--t-blue);
}
.inputContainerOfficer[data-v-af6af48b]{
    background: #28115A;
    border-radius: 3px;
    width: 100%;
    margin-top: 5px;
}
.inputContainerOfficer input[data-v-af6af48b]{
    background: var(--t-blue);
    border-radius: 3px;
    color: white;
    padding: 7px 15px;
    width: 100%;
    border: 0;
}
.buttonTrashOfficer[data-v-af6af48b]{
    background-color: white;
    border-radius: 50%;
    padding: 8px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--t-blue);
}
.buttonPenOfficer[data-v-af6af48b]{
    background-color: var(--t-blue);
    border-radius: 50%;
    padding: 8px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--t-blue);
}
.actionsOfficer[data-v-af6af48b]{
    display: flex;
    width: 100px;
    justify-content: space-between;
    padding-left: 12px;
}
.rowcol3[data-v-af6af48b] {
    display: flex;
    justify-content: space-between;
}
.rowcol3 .col1[data-v-af6af48b] {
    width: 31%;
}
.selectInformation[data-v-af6af48b] {
    background-color:  var(--t-blue);
    margin: 5px 0 15px 0;
}
.selectInformation select[data-v-af6af48b],
.selectInformation input[data-v-af6af48b]{
    height: 40px;
    color: white;
    padding: 0 10px;
}
.selectInformation input[data-v-af6af48b]{
    background-color: transparent;
    border: 0;
}
.buttonsEditClub[data-v-af6af48b]{
    width: 100%;
    padding: 20px 0px;
    display: flex;
    justify-content: right;
}
.buttonCancelEditClub[data-v-af6af48b]{
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--t-blue);
    width: 100px;
}
.buttonSaveEditClub[data-v-af6af48b]{
    background-color: var(--t-blue);
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--t-blue);
    color: white;
    width: 100px;
    margin-left: 12px;
}
.infoProfile[data-v-af6af48b]{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    margin: 20px 0;
}
.imageProfile[data-v-af6af48b]{
    width: 60px;
    height: 60px;
    border: 2px solid gray;
    overflow: hidden;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}
.imageProfile img[data-v-af6af48b]{
    width: 60px;
    height: 60px;
    min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
    object-fit: cover;
}
.nameProfile[data-v-af6af48b]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--t-blue);
}
.listOrgs[data-v-af6af48b]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--t-blue);
    text-align: left;
}
.listInterest[data-v-af6af48b]{
    display: flex;
    flex-wrap: wrap;
}
.itemInterest[data-v-af6af48b]{
    padding: 5px 15px;
    background: var(--t-blue);
    border-radius: 82px;
    font-family: 'Inter';
    color: var(--white);
    margin-bottom: 10px;
    margin-right: 10px;
}
.aboutProfile[data-v-af6af48b],
.interestProfile[data-v-af6af48b],
.containerT[data-v-af6af48b]{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.titleContainer[data-v-af6af48b]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--t-blue);
    text-align: left;
}
.cardClub[data-v-af6af48b] {
    width: 140px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
    background: var(--t-blue);
    border-radius: 8px 8px 8px 8px;
}
.listClubTeams[data-v-af6af48b]{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
}
.headerCard[data-v-af6af48b] {
    position: relative;
}
.containerTitle[data-v-af6af48b] {
    position: absolute;
    background-color: rgb(39 48 59 / 83%);
    width: 100%;
    border-radius: 8px 8px 0px 0px;
}
.headerCard img[data-v-af6af48b] {
    width: 140px;
    height: 105px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}
.titleClub[data-v-af6af48b] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    color: var(--white);
    margin-top: 10px;
    margin-left: 5px;
    margin-bottom: 5px;
    text-align: left;
}
.countMembers[data-v-af6af48b] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: #FFFFFF;
    display: block;
    padding: 10px;
}
.countIcon[data-v-af6af48b] {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 2px;
}
.counts[data-v-af6af48b]{
    display: flex;
    align-items: center;
    color: white;
}
.cIcon[data-v-af6af48b]{
    margin-right: 5px;
    width: 18px;
}
.cIcon.calendar[data-v-af6af48b]{
    width: 15px;
}
.containerButton[data-v-af6af48b]{
    display: flex;
    margin-bottom: 10px;
    width: 100%;
    padding: 0 10px;
}
.btnWhite[data-v-af6af48b]{
    width: 100%;
    padding: 10px 20px;
    background: #FFFFFF;
    border: 1px solid #590CF6;
    border-radius: 12px;
    cursor: pointer;
}
.btnPurple[data-v-af6af48b]{
    width: 100%;
    padding: 10px 20px;
    background: #590CF6;
    border-radius: 12px;
    border: 0;
    color: white;
    cursor: pointer;
}
.tButtons[data-v-af6af48b]{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.tButtons button[data-v-af6af48b]{
    width: 49%;
}
