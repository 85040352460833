.fontawesome5pro-regular-normal-lavender-20px {
  color: var(--lavender);
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.section-navbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 50px;
  margin-top: 6px;
}

.section-label {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
}

.section-column {
  border-bottom: 2px solid transparent;
  width: 100%;
}

@media only screen and (max-width: 1440px) {
  .section-column-selected.pointer,
  .section-column.pointer{
    font-size: 14px;
  } 
}

.section-column-selected {
  background-color: var(--t-blue);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

#left-rad {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
}

#right-rad {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.containerListClubs{
  width: 100%;
  overflow: auto; 
  overflow-x: hidden;
}

.containerListClubs:hover::-webkit-scrollbar{
  display: block;
}

.containerListClubs::-webkit-scrollbar {
  width: 5px;
  display: none;
}

.containerListClubs::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
.containerListClubs::-webkit-scrollbar-thumb {
  background: var(--lavender); 
  border-radius: 10px;
}

.containerListClubs::-webkit-scrollbar-thumb:hover {
  background: var(--light-brown); 
}