.clubrow-container {
  /* background-color: var(--onyx); */
  width: calc(100% - 20px);
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 12px 0;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.clubrow-pending {
  width: calc(100% - 25px) !important;
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  align-items: center;
  justify-content: flex-start;
}

.clubrow-container:hover .clubrow-container,
.itemClubs:hover .mainInfo{
  display: none;
}

.itemClubs:hover .clubrow-container-empty{
  display: flex;
  width: 100%;
  margin-left: 0;
  padding: 0 40px;
}

.itemClubs .clubrow-container{
  margin: 0 !important;
  padding: 12px 0 !important;
  width: 100%;
}

.itemClubs.pendingclub#color,
.pendingclub{
  display: flex;
}

.itemClubs.pendingclub:hover .clubrow-container-empty {
  width: calc(100% - 110px);
}

.itemClubs > div{
  width: 100%;
}

.clubPendingSetup{
  opacity: 0.6;
}

.itemClubs {
  width: 100%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 0px 0;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

div#color::before {
  content: '';
  width: 10px;
  background-color: var(--t-blue);
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 12;
}

.clubrow-container-empty {
  background-color: var(--t-blue);
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  height: 74px;
  cursor: pointer;
}

.club-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
  width: 100%;
  flex: 1 1;
  min-width: 0;
}

.club-column-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
  margin-right: 5px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.club-image {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  margin-left: 12px;
  object-fit: cover;
}

#transparent {
  background-color: transparent;
}

#color {
  background-color: var(--t-blue);
}

.cancel-club {
  color: #590cf6;
  background-color: white;
  padding: 8px 12px;
  border: 1px solid #590cf6;
  border-radius: 12px;
  position: absolute;
  right: 50px;
}

.club-pending-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-hover {
  display: flex;
  flex-direction: row;
}

.clubrow-container-empty{
  display: none;
}

.HoveringClub-LeftPanel-Pending{
  color: black;
  font-size: 16px;
  font-family: "Inter";
}

.btnLoaderCancel{
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnLoaderCancel .buttonSendSignUp {
  height: 25px;
  border: 3px solid;
  width: 25px;
  display: block;
  border-radius: 50%;
  border-bottom-color: var(--light-brown);
  border-top-color: #0000ff00;
  border-right-color: #0000ff00;
  border-left-color: #0000ff00;
  margin-top: -3px !important;
}

.clubNameText{
  text-decoration: none;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  line-height: 28px;
}