@font-face {
    font-family: "Inter-Regular";
    font-style: normal;
    font-weight: 400;
    src: url("https://fonts.animaapp.com/RobotoCondensed-Regular")
      format("truetype");
  }
  /* @font-face {
    font-family: "Font Awesome 5 Pro-Regular";
    font-style: normal;
    font-weight: 400;
    src: url("https://fonts.animaapp.com/FontAwesome5Pro-Regular")
      format("opentype");
  }
  @font-face {
    font-family: "Font Awesome 5 Pro-Solid";
    font-style: normal;
    font-weight: 400;
    src: url("https://fonts.animaapp.com/FontAwesome5Pro-Solid")
      format("opentype");
  } */
  
  .container-center-horizontal {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
  }
  
  .content-org {
    width: 100%;
    z-index: 1;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    align-items: center;
    min-height: 944px;
    background-color: transparent;
    padding-left: 174px;
    padding-right: 20px;
  }
  
  @media screen and (max-width: 1450px) {
    .content-org {
      width: 4000px;
    }
  }
  
  .organizations {
    flex: 1 1;
    align-items: flex-start;
    display: flex;
    height: 1024px;
    position: relative;
    width: 100%;
  }
  
  .select-container {
    display: flex;
    flex-direction: row;
    border: 2px solid var(--t-blue);
    border-radius: 8px;
    padding: 14px;
    justify-content: space-between;
    width: 100%;
  }
  
  .option {
    padding-right: 50px;
    display: flex;
    flex-direction: row;
    height: 20px;
  }
  
  .option label {
    padding-left: 15px;
    padding-top: 2px;
    color: var(--t-blue);;
    font-family: var(--font-family-roboto_condensed);
  }
  
  /* custom radio input */
  
  .content-input input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  .content-input input {
    visibility: hidden;
    position: absolute;
    right: 0;
  }
  
  .content-input {
    position: relative;
    margin-bottom: 30px;
    padding: 0; /* Damos un padding de 60px para posicionar el elemento <i> en este espacio*/
    display: block;
  }
  
  .content-input input + i {
    background: transparent;
    border: 2px solid var(--t-blue);
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .content-input input[type="radio"] + i {
    height: 18px;
    width: 18px;
    border-radius: 100%;
    left: 3px;
  }
  
  .content-input input[type="radio"] + i:before {
    content: "";
    display: block;
    height: 6px;
    width: 6px;
    border-radius: 100%;
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 4px;
    background: var(--t-blue);
    transition: all 0.25s ease; /* Todas las propiedades | tiempo | tipo movimiento */
    -webkit-transform: scale(0) /* Lo reducimos a 0*/;
            transform: scale(0) /* Lo reducimos a 0*/;
    opacity: 0; /* Lo ocultamos*/
  }
  
  .content-input input[type="radio"]:checked + i:before {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  
  .content-input:hover input[type="radio"]:not(:checked) + i {
    background: var(--t-blue);
  opacity: 0.5;
  }
  
  .i {
    font-size: 50px;
  }
  
  .shwo-view-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-top: 20px;
  }
  

.apexcharts-legend-series{
    text-align: left;
}

.col2.cardDark.GrphPieMulti > div {
  width: 33%;
}

.inputDashboard{
  border: 0.5px solid #FFFFFF !important;
  border-radius: 8px;
}