.inputSearchClub::-webkit-input-placeholder {
  color: var(--white);
  font-family: var(--font-family-roboto_condensed);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}

.inputSearchClub::placeholder {
  color: var(--white);
  font-family: var(--font-family-roboto_condensed);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}

.inputSearch {
  width: 100%;
  background-color: var(--t-blue);
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.inputSearch input {
  width: 100%;
  padding: 10px 0px;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  color: white;
}

.btnSort {
  width: 40px;
}

.btnFilter {
  width: 40px;
}

.inputSearch input::-webkit-input-placeholder {
  color: var(--white);
  font-family: var(--font-family-roboto_condensed);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}

.inputSearch input::placeholder {
  color: var(--white);
  font-family: var(--font-family-roboto_condensed);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}

.filterInputSearch::before {
  display: block;
  font-family: "Font Awesome 5 Pro-Regular";
  content: "\f002";
  position: absolute;
  font-size: 20px;
  top: 8px;
  left: 8px;
  color: white;
}

.inputSearchClub::-webkit-input-placeholder {
  color: var(--white);
  font-family: var(--font-family-roboto_condensed);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}

.inputSearchClub::placeholder {
  color: var(--white);
  font-family: var(--font-family-roboto_condensed);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}

/* .filterInputSearchClub::before {
  display: block;
  font-family: "Font Awesome 5 Pro-Regular";
  content: "\f002";
  position: absolute;
  font-size: 20px;
  top: 8px;
  left: 8px;
  color: white;
} */

.filterInputSearchClub.hide {
  display: none;
}

.filterInputSearchClub {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.inputSearchClub {
  width: 100%;
  padding: 12px 35px;
  background-color: var(--light-brown);
  border: 0px;
  border-radius: 4px;
  color: white;
}

.filter-bar-club {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 50px;
  margin-top: 18px;
  margin-bottom: 18px;
  position: relative;
}

.filter-bar-event {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: right;
  justify-content: right;
  padding-left: 30px;
  padding-right: 50px;
  margin-top: 18px;
  margin-bottom: 18px;
  position: relative;
}

.filter-bar-club>div {
  position: relative;
}

.optionsSort {
  position: relative;
  background-color: var(--t-blue);
  padding: 20px;
  border-radius: 3px;
  z-index: 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.optionsSort h2 {
  color: #ffffff;
  font-weight: 700;
  font-family: 'Inter';
  text-align: left;
  padding: 5px 0;
}

.optionsSort .option-status {
  cursor: pointer;
}

.optionsSort .option-status label {
  padding-left: 20px;
  cursor: pointer;
  font-family: 'Inter';
  text-align: left;
  color: var(--white);
}

.optionsSort .content-input input+i {
  border: 2px solid white;
}

.a-z {
  margin-left: 20px;
  cursor: pointer;
}


.containerOptions {
  position: absolute !important;
  left: 15vw;
  top: 18vh;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}