.requests-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px 10px 30px;
}

.request-section {
  border-bottom: 2px solid transparent;
  width: 100%;
  margin: 0 12%;
  display: flex;
    flex-direction: column;
    align-items: center;
}

.request-section-selected {
  border-bottom: 2px solid var(--t-blue);
  width: 100%;
  margin: 0 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.request-section-label {
  margin-top: 8px;
  margin-bottom: 18px;
  width: 100%;
}

.request-column {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  width: 50%;
}

.single-request {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}

.single-request-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.request-avatar {
  width: 50px;
  height: 50px;
  border: 2px solid var(--onyx);
  border-radius: 100%;
}

.request-name {
  margin-left: 14px;
}

.request-icons {
  width: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.request-main-column {
  display: flex;
  flex-direction: column;
  width: 98%;
}

.request-main-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.Requests-buttonCancelRequest{
  width: 30px;
  height: 30px;
  background-color: white;
  border: 1px solid var(--t-blue);
  border-radius: 7px;
}

.Requests-buttonCancelRequest.lavanda{
  color: #A885D7 !important;
  background-color: #A885D7 !important;
}

.Requests-buttonAcceptRequest{
  width: 28px;
  background-color: var(--t-blue);
  border: 0;
  border-radius: 9px;
  margin-left: 5px;
}

.Requests-btnRequest{
  display: flex;
  gap: 5px;
  width: 190px;
  justify-content: center;
}