.content-clubSettings{
    width: 100%;
    padding: 0 30px;
    font-family: 'Inter';
} 

.infoSettingClub h4 {
    color: white;
    background: #28115A;
    padding: 12px;
    border-radius: 3px;
    text-align: left;
    margin-bottom: 30px;
}

.infoSettingClub input {
    color: white;
    background: var(--t-blue);
    padding: 12px;
    border-radius: 3px;
    text-align: left;
    margin-bottom: 30px;
    width: 100%;
    border: 0;
    font-family: 'Inter';
    font-size: 16px;
}

.listSettingClub {
    display: flex;
    color: var(--t-blue);
}

.listSettingClub label {
    width: 30%;
    text-align: left;
    font-weight: 400;
}

.infoSettingClub li {
    padding: 10px 0;
}

.listSettingPrivacy .option{
    padding: 0 !important;
    height: auto;
    margin-bottom: 20px;
}

.listSettingPrivacy .option label{
    font-weight: bold;
    padding-top: 0 !important;
}

.textsPrivacy {
    display: flex;
    flex-direction: column;
    text-align: left;
}   

span.privacyDetails {
    padding-left: 15px;
    padding-top: 2px;
    color: var(--t-blue);
    font-size: 14px;
    font-family: var(--font-family-roboto_condensed);
}

.listSettingNotification{
    display: flex;
    align-items: center;
}

.listSettingNotification > label{
    width: 100%;
    text-align: left;
    color: var(--t-blue);
}

.listSettingNotification .option{
    padding-right: 0 !important;
}

.groupOptions{
    margin: 10px 0;
    display: flex;
}