.text-invites[data-v-2a91e6f2]{
    color: var(--t-blue);
    font-family: 'Inter';
    font-weight: 700;
    line-height: 24px;
    font-size: 16px;
}
.modalInviteAttendees .titleModal[data-v-2a91e6f2]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--t-blue);
    text-align: left;
}
.modalInviteAttendees textarea[data-v-2a91e6f2]{
    padding: 10px;
    width: 100%;
    height: 130px;
    background: var(--t-blue);
    border-radius: 3px;
    border: 0;
    color: white;
    font-family: 'Inter';
}
.modalInviteAttendees .modal-header[data-v-2a91e6f2]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    text-align: left;
    color: var(--t-blue);
}
.subtext-invites[data-v-2a91e6f2]{
    color: var(--t-blue);
    font-family: 'Inter';
    font-weight: 400;
    line-height: 19px;
    font-size: 16px;
}
.modalInviteMemebers a[data-v-2a91e6f2]{
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: var(--t-blue);
    padding-top: 10px;
}
.container-drag[data-v-2a91e6f2]{
    padding: 20px;
    background: var(--t-blue);
    border-radius: 16px;
    margin-top: 16px;
    color: white;
    text-align: center;
    font-family: 'Inter';
    line-height: 25px;
}
.container-drag span[data-v-2a91e6f2]{
    display: block;
    color: var(--white);
    text-decoration: underline;
    cursor: pointer;
}
.modal-footer[data-v-2a91e6f2]{
    display: flex;
    justify-content: space-between;
}
.buttonClose[data-v-2a91e6f2]{
    background: #FFFFFF;
    border: 1px solid var(--t-blue);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 14px 20px;
    width: 150px;
    color: var(--t-blue);
    cursor: pointer;
}
.buttonSend[data-v-2a91e6f2]{
    background: var(--t-blue);
    border: 1px solid var(--t-blue);
    border-radius: 12px;
    padding: 14px 20px;
    width: 150px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
}
button.inProcess[data-v-2a91e6f2]{
    pointer-events: none;
    opacity: 0.8;
}
.buttonSendSpinner[data-v-2a91e6f2]{
    height: 25px;
    border: 3px solid;
    width: 25px;
    display: block;
    border-radius: 50%;
    border-bottom-color: white;
    border-top-color: #0000ff00;
    border-right-color: #0000ff00;
    border-left-color: #0000ff00;  
    margin-top: -3px !important;
}
.modalInviteMemebers .row[data-v-2a91e6f2]{
    align-items: baseline;
}
.modalInviteMemebers .row label[data-v-2a91e6f2]{
    width: 100px;
    color: var(--t-blue);
    font-family: 'Inter';
}
.modalInviteMemebers .row input[data-v-2a91e6f2]{
    width: calc(100% - 100px);
    height: 35px;
    margin-bottom: 14px;
    background-color: var(--t-blue);
    border: 0;
}
.text-invites[data-v-2a91e6f2],
.subtext-invites[data-v-2a91e6f2],
.modal-body a[data-v-2a91e6f2],
.modalInviteMemebers .modal-title[data-v-2a91e6f2]{
    text-align: left;
}
.imageMember[data-v-2a91e6f2]{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-self: center;
    align-items: center;
}
@media (max-width: 1200px) {
.listMembersClub .cardMember[data-v-2a91e6f2]{
        width: 100%;
}
.listMembersClub .imageMember[data-v-2a91e6f2]{
        overflow: initial;
}
}

/* .imageMember img{
    height: 100%;
    width: auto;
    border-radius: 0;
} */
.count-members[data-v-2a91e6f2]{
    display: flex;
}
.containerSearchM[data-v-2a91e6f2]{
    width: calc(100% - 170px);
    display: flex;
    align-items: center;
}
.inputSearchM[data-v-2a91e6f2]{
    background: var(--t-blue);
    border: 0;
    margin-left: 10px;
    border-radius: 5px;
    height: 40px;
    color: white;
    padding: 0 15px;
    display: flex;
    align-items: center;
}
.inputSearchMI[data-v-2a91e6f2]{
    background: #28115A;
    border: 0;
    width: 100%;
    height: 40px;
    color: white;
    padding: 0 20px;
}