.embed-link iframe {
    text-align: left;
    width: 100% !important;
    margin-top: 20px;
}

/*.embed-link {*/
/*    position: relative;*/
/*}*/

.container-post-embed-preview .close {
    font-size: 35px;
    margin: 0;
    padding: 0;
    color: black;
    position: absolute;
    border-color: white;
    /*width: 30px;*/
    /*height: 30px;*/
    top: 25px;
    right: 10px;
    /*border-radius: 50%;*/
    /*background-color: red;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-content: center;*/
}

.container-post-embed-preview {
    position: relative;
}
