.myTimePicker[data-v-a1cec2f8] {
    padding: 10px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    border-radius: 7px;
    margin-top: 5px;
    display: none;
}
.timePickerContainer[data-v-a1cec2f8] {
    display: flex;
}
.titleTimePicker[data-v-a1cec2f8] {
    font-size: 13px;
    color: #878787;
    text-align: left;
    display: block;
    font-weight: 700;
    padding: 5px 0 20px 0;
    z-index: 1028;
}
.timepickerInputs[data-v-a1cec2f8] {
    display: flex;
    /* align-items: center; */
}
.timepickerInput[data-v-a1cec2f8] {
    display: flex;
    flex-direction: column;
}
.timepickerInput input[data-v-a1cec2f8] {
    border-radius: 4px;
    border: 1px solid var(--simple-gray) !important;
    height: 55px;
    color: black;
    font-size: 44px;
    text-align: center;
    padding: 0;
    width: 65px;
}
.timepickerInput input[data-v-a1cec2f8]:focus {
    /* background-color: white; */
}
.timepickerInput label[data-v-a1cec2f8] {
    color: gray;
    font-size: 11px;
    text-align: left;
}
.separatorTimePicker[data-v-a1cec2f8] {
    font-size: 50px;
    color: black;
    padding: 10px 6px;
    position: relative;
    top: -9px;
}
.buttonsAMP[data-v-a1cec2f8] {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-left: 10px;
    border-radius: 5px;
    /* border: 2px solid rgb(199, 199, 199); */
    height: 55px;
    /* margin-top: 7px; */
}
.buttonsAMP button[data-v-a1cec2f8] {
    height: 35px;
    font-size: 14px;
    padding: 0 10px;
    background: transparent;
    font-weight: 500;
    cursor: pointer;
}
.timeAM[data-v-a1cec2f8] {
    border-radius: 5px 5px 0px 0px;
    border: 2px solid rgb(199, 199, 199);
    border-bottom: 1px solid rgb(199, 199, 199);
}
.timePM[data-v-a1cec2f8] {
    border-radius: 0px 0px 5px 5px;
    border: 2px solid rgb(199, 199, 199);
    border-top: 1px solid rgb(199, 199, 199);
}
.timeActive[data-v-a1cec2f8] {
    background-color: var(--electric-yellow) !important;
    color: #fff !important;
    font-family: 'Inter-bold';
}
.buttonsTimePicker[data-v-a1cec2f8] {
    display: flex;
    justify-content: end;
}
.buttonsTimePicker button[data-v-a1cec2f8] {
    background-color: transparent;
    border: 0;
    /* margin-top: 10px; */
    color: #ffffff;
    padding: 2px 13px;
    text-transform: uppercase;
    cursor: pointer;
}
.contentTime[data-v-a1cec2f8] {
    padding: 10px 0;
}
.contentTime p[data-v-a1cec2f8] {
    padding: 0px 10px;
    color: black;
    font-family: 'Inter';
    font-size: 14px;
}
.timePanelActive[data-v-a1cec2f8] {
    display: block;
    position: absolute;
    z-index: 1028;
    background-color: white;
}
.timePanelActive.TimeEnd[data-v-a1cec2f8] {
    right: 0;
}
.containerTimePicker[data-v-a1cec2f8] {
    position: relative;
    width: 100%;
    cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input[data-v-a1cec2f8]::-webkit-outer-spin-button,
input[data-v-a1cec2f8]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number][data-v-a1cec2f8] {
    -moz-appearance: textfield;
}
.changeInfoDate[data-v-a1cec2f8]{
    background-color: var(--t-blue);
    margin: 0;
    padding: 0 21px;
    height: 40px;
}
.changeInfoDate p[data-v-a1cec2f8]{
    padding: 0px 0 0px 0 !important;
}


