.row[data-v-aebf6a13]{
    margin-bottom: 20px;
}
.labelInfo[data-v-aebf6a13]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: var(--t-blue);
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.labelTitle[data-v-aebf6a13]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 5px;
    color: var(--t-blue); 
    text-align: left;
}
.buttonAdd[data-v-aebf6a13]{
    padding: 10px 15px;
    background: var(--t-blue);
    color: white;
    border-radius: 12px;
    border: 0;
}
.inputInfo textarea[data-v-aebf6a13]{
    width: 100%;
    background: var(--t-blue);
    border-radius: 3px;
    color: white;
    border: 0;
    height: 70px;
    outline: none;
    font-family: 'Inter';
    padding: 10px;
}
.officer[data-v-aebf6a13] {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}
.profileOfficer[data-v-aebf6a13]{
    width: 30%;
    color: var(--t-blue);
    font-family: 'Inter';
    line-height: 18px;
}
.nameOfficer[data-v-aebf6a13] {
    margin: 0 !important;
    padding: 0 !important;
    font-weight: 700;
}
.professionOfficer[data-v-aebf6a13] {
    margin: 0 !important;
    padding: 0 !important;
}
.emailOfficer[data-v-aebf6a13]{
    width: 70%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: var(--t-blue);
}
.emailOfficerAdd[data-v-aebf6a13]{
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: var(--t-blue);
}
.emailOfficerAddHalf[data-v-aebf6a13]{
    padding-right: 10px;
    width: 50%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: var(--t-blue);
}
.inputContainerOfficer[data-v-aebf6a13]{
    background: #28115A;
    border-radius: 3px;
    width: 100%;
    margin-top: 5px;
}
.inputContainerOfficer input[data-v-aebf6a13]{
    background: var(--t-blue);
    border-radius: 3px;
    color: white;
    padding: 7px 15px;
    width: 100%;
    border: 0;
}
.buttonTrashOfficer[data-v-aebf6a13]{
    background-color: white;
    border-radius: 50%;
    padding: 8px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--t-blue);
}
.buttonPenOfficer[data-v-aebf6a13]{
    background-color: var(--t-blue);
    border-radius: 50%;
    padding: 8px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--t-blue);
}
.actionsOfficer[data-v-aebf6a13]{
    display: flex;
    width: 100px;
    justify-content: space-between;
    padding-left: 12px;
}
.rowcol3[data-v-aebf6a13] {
    display: flex;
    justify-content: space-between;
}
.rowcol3 .col1[data-v-aebf6a13] {
    width: 31%;
}
.selectInformation[data-v-aebf6a13] {
    background-color: var(--t-blue);
    margin: 5px 0 15px 0;
}
.selectInformation select[data-v-aebf6a13],
.selectInformation input[data-v-aebf6a13]{
    height: 40px;
    color: white;
    padding: 0 10px;
}
.selectInformation input[data-v-aebf6a13]{
    background-color: transparent;
    border: 0;
}
.buttonsEditClub[data-v-aebf6a13]{
    width: 100%;
    padding: 20px 0px;
    display: flex;
    justify-content: right;
}
.buttonCancelEditClub[data-v-aebf6a13]{
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--t-blue);
    width: 100px;
    color: var(--t-blue);
}
.buttonSaveEditClub[data-v-aebf6a13]{
    background-color: var(--t-blue);
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--t-blue);
    color: white;
    width: 100px;
    margin-left: 12px;
}
.UpdateClub__clubName[data-v-aebf6a13] {
    width: 100%;
    padding: 10px;
    background: var(--t-blue);
    outline: none;
    border: none;
    border-radius: 5px;
    color: white;
}