.dashboardHeader[data-v-0bf89dd0]{
    width: 100%;
    display: flex;
    background: #34266B;
    border-radius: 24px;
}
.cardHeader[data-v-0bf89dd0]{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    color: white;
    font-family: 'Inter';
    padding: 15px 25px;
    opacity: 1;
}
.cardHeader.active[data-v-0bf89dd0]{
    background: linear-gradient(180deg, #7300FC 0%, #580CF5 100%);
    border-radius: 12px;
    opacity: 1;
}
.cardHeader.active .cardTitle[data-v-0bf89dd0]{
    font-family: 'Inter';
    font-style: normal;
    font-size: 24px;
    line-height: 28px;
}
.descriptionCards[data-v-0bf89dd0]{
    width: 100%;
    display: flex;
    margin-top: 10px;
}
.cardTitle[data-v-0bf89dd0] {
    height: 30px;
    font-weight: 700;
    display: flex;
    align-items: flex-end;
}
.countCard[data-v-0bf89dd0] {
    width: 100%;
    display: flex;
}
.countCard span[data-v-0bf89dd0] {
    font-weight: 700;
}
.row[data-v-0bf89dd0]{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.col1[data-v-0bf89dd0]{
    width: 32%;
}
.col3[data-v-0bf89dd0]{
    width: 66%;
}
.cardDark[data-v-0bf89dd0]{
    background: #34266B;
    border: 1px solid rgba(236, 211, 255, 0.15);
    border-radius: 12px;
}
.cardLight[data-v-0bf89dd0]{
    background: #FFFFFF;
    border: 1px solid rgba(236, 211, 255, 0.15);
    border-radius: 12px;
}
.GrphPieMulti[data-v-0bf89dd0]{
    display: flex;
    width: 100%;
    padding: 40px 0px;
}
.GrphPieMulti > div[data-v-0bf89dd0]{
    width: 33%;
}
.titleTable[data-v-0bf89dd0]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    padding: 15px;
    text-align: left;
}
.infoTable[data-v-0bf89dd0]{
    width: 100%;
    color: white;
    text-align: left;
    padding: 0 20px;
    font-family: 'Inter';
    border-collapse: collapse;
    display: table;
    margin: 0px 15px 15px 15px;
}
.infoTable td[data-v-0bf89dd0]{
    padding: 7px 0;
}
.nmClass[data-v-0bf89dd0]{
    font-weight: 700;
}
.cardLight .titleTable[data-v-0bf89dd0]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #250D44;
}
.cardLight .infoTable[data-v-0bf89dd0]{
    color: #250D44;
}
.cardFooter[data-v-0bf89dd0] {
    padding: 15px
}
.cardFooter p[data-v-0bf89dd0]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #250D44;
    text-align: initial;
}
.cardFooter span[data-v-0bf89dd0]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #250D44;
    text-align: left;
    display: block;
}
.iconAhaPurple[data-v-0bf89dd0]{
    background-image: url(../../../public/aHa.svg);
    width: 30px;
    height: 25px;
    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: initial;
}
.cardAlert[data-v-0bf89dd0] {
    display: flex;
    align-items: center;
}
.cardAlert img[data-v-0bf89dd0] {
    border: 3px solid var(--electric-yellow);
    width: 50px;
    border-radius: 50%;
    margin-right: 12px;
}
.descCard p[data-v-0bf89dd0] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #250D44;
    text-align: left;
}
.descCard span[data-v-0bf89dd0]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #A885D7;
    text-align: left;
    display: block;
}
.alerts[data-v-0bf89dd0]{
    padding: 5px 15px;
}
.dateTime[data-v-0bf89dd0]{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #250D44;
    padding-right: 19px;
    width: 70px;
    text-align: center;
    padding-top: 5px;
}
.titleViewAll[data-v-0bf89dd0]{
    display: flex;
    justify-content: space-between;
}
.linkViewAll[data-v-0bf89dd0]{
    background-color: transparent;
    border: 0;
    font-size: 18px;
    text-decoration: underline;
    color: #250D44;
    font-weight: 700;
    font-family: 'Inter';
}
.headerDashboard[data-v-0bf89dd0]{
    display: flex;
}
.titleDashboard[data-v-0bf89dd0]{
    min-height: 28px;
    font-family: var(--font-family-roboto_condensed);
    font-weight: 700;
    color: var(--t-blue);
    font-size: var(--font-size-xxxl);
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    padding-bottom: 25px;
}
.headerDashboard[data-v-0bf89dd0]{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.containerDates[data-v-0bf89dd0]{
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}
.containerDates label[data-v-0bf89dd0]{
    margin: 0 12px;
    color: #afafaf;
}
.dashboard__chartLabel[data-v-0bf89dd0] {
    color: white;
    font-family: 'Inter';
    position: relative;
    top: 0px;
    left: 0px;
}
#table-wrapper[data-v-0bf89dd0] {
    position:relative;
    padding: 20px 0;
}
#table-scroll[data-v-0bf89dd0] {
    height:170px;
    overflow: scroll;
    overflow-x: hidden;
    /*margin-top:20px;*/
}
#table-scroll[data-v-0bf89dd0]::-webkit-scrollbar {
    display: block;
    width: 5px;
}
#table-scroll[data-v-0bf89dd0]:hover::-webkit-scrollbar{
    display: block;
}
#table-scroll[data-v-0bf89dd0]::-webkit-scrollbar-thumb{
background: var(--lavender);
border-radius: 4px;
}
#table-scroll[data-v-0bf89dd0]::-webkit-scrollbar-thumb:active {
/* background-color: red; */
}
#table-scroll[data-v-0bf89dd0]::-webkit-scrollbar-thumb:hover{
background: var(--lavender);
box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
#table-scroll[data-v-0bf89dd0]::-webkit-scrollbar-track{
background: transparent;
border-radius: 4px;
}
#table-scroll[data-v-0bf89dd0]::-webkit-scrollbar-track:hover, 
#table-scroll[data-v-0bf89dd0]::-webkit-scrollbar-track:active {
background: transparent;
}
#table-wrapper table[data-v-0bf89dd0] {
    width:100%;
}
#table-wrapper table thead th[data-v-0bf89dd0] {
    padding-bottom: 7px;
}
#table-wrapper table thead th .text-fixed-header[data-v-0bf89dd0] {
    /*position:absolute;*/
    top:-20px;
    z-index:2;
    height:20px;
    width:35%;
    /*border:1px solid red;*/
}
#table-scroll[data-v-0bf89dd0]::before {
    display: block;
    position: absolute;
    bottom: 11px;
    right: 15px;
    content: '';
    width: 15px;
    height: 15px;
    border: 3px solid #a885d8;
    background: #ca424200;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top: 0;
    border-left: 0;
}
.infoTable th[data-v-0bf89dd0] {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin-bottom: 50px;
}
