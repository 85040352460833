.selectInformation[data-v-da4073b9]{
    display: flex;
}
.selectInformation input[data-v-da4073b9]{
    width: 100%;
}
.input-selectors .containers-input[data-v-da4073b9]{
    display: flex;
    justify-content: space-between;
    color: white;
    font-family: 'Inter';
    font-size: 14px;
    margin: 15px 0;
}
.input-selectors .option[data-v-da4073b9] {
    padding: 0;
}
.rowcol2[data-v-da4073b9] {
    display: flex;
    justify-content: space-between;
}
.rowcol2 .col1[data-v-da4073b9] {
    width: 49%;
}
.upper-margin[data-v-da4073b9]{
    margin-top: 30px;
}