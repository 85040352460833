/* Variables */

* {
  box-sizing: border-box;
}

body{
  background: #ebe8e6;
  background: var(--background-light);
  /*background-attachment: fixed;*/
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700|Roboto:400,700");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@800&display=swap');

@font-face {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/RobotoCondensed-Regular")
    format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Inter-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Inter-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Inter-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Inter-SemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/Inter-SemiBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/AustinModern.ttf")
    format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/NunitoSans_7pt-Regular.ttf")
    format("truetype");
}

/* @font-face {
  font-family: "Poppins-Bold";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/RobotoCondensed-Regular") format("truetype");
} */
/* @font-face {
  font-family: "Font Awesome 5 Pro-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/FontAwesome5Pro-Regular") format("opentype");
}
@font-face {
  font-family: "Font Awesome 5 Pro-Solid";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/FontAwesome5Pro-Solid") format("opentype");
} */

/* @font-face {
  font-family: "Font Awesome 5 Pro-Regular";
  src: local("FontAwesome5Pro-Regular-400"),
    url("./assets/fonts/FontAwesome5Pro-Regular-400.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Font Awesome 5 Pro-Solid";
  src: local("FontAwesome5Pro-Solid-900"),
    url("./assets/fonts/FontAwesome5Pro-Solid-900.otf");
  font-weight: 900;
} */

@font-face {
  font-family: "Font Awesome 5 Pro-Regular";
  src: url(https://zaahah-fonts.s3.amazonaws.com/FontAwesome5Pro-Regular-400.otf) format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "Font Awesome 5 Pro-Solid";
  src: url(https://zaahah-fonts.s3.amazonaws.com/FontAwesome5Pro-Solid-900.otf) format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "Poppins-Light";
  src: local("/Poppins-Light"),
    url(../public/fonts/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Avant Garde Gothic";
  src: url(https://zaahah-fonts.s3.amazonaws.com/ITC-Avant-Garde-Gothic-LT-Bold.otf) format("opentype");
  font-weight: bold;
}

:root {
  --black-2: rgba(0, 0, 0, 0.4);
  --black-3: rgba(0, 0, 0, 0.6);
  --black: rgba(0, 0, 0, 1);
  --absolute-zero: rgba(0, 61, 219, 1);
  --licorice: rgba(18, 18, 18, 1);
  --black-rock: rgba(22, 3, 45, 0.8);
  --light-brown: #736f6d;
  --violent-violet: rgb(90, 55, 17);
  --heavy-metal: rgba(44, 44, 44, 1);
  --onyx: rgba(56, 56, 56, 1);
  --electric-yellow: #dcb568;
  --electric-yellow-2: #e89f4d;
  --mountain-mist: #c1c9d2;
  --lavender: #CEBEAD;
  --maroon-flush: #D92B5F;
  --maroon-flush-light: #F5E6EB;
  --accept-green: #2AD07C;
  --alto: rgba(222, 222, 222, 1);
  --blue-chalk: rgba(241, 224, 255, 1);
  --banana: rgba(255, 196, 41, 1);
  --white-2: rgba(255, 255, 255, 0.6);
  --white: rgba(255, 255, 255, 1);
  --absolute-zero: #2a4cd3;
  --valhalla: #4e331c;
  --simple-brown: #cbbeaf;
  --simple-brown-dark: #2e2623;
  --simple-gray: #aeaeae;
  --simple-gray-2: #eeeeee;
  --simple-gray-3: #6B6B6B;

  /* Tara new Colors */
    --t-green: #ABB69B;
    --t-light-tan: #dfd5ca;
      /* --t-light-tan: #CEBEAD; */
      /* --t-light-tan: #F1C773; */
    --t-blue: #27303B;
    --t-yellow: #F1C773;
    --t-off-white: #F7F5F3;
    /* Tara new Colors */
  --simple-green: #c7cabb;
  --simple-green-dark: #166E42;
  --simple-blue-dark: #27303b;
  --simple-blue-dark-2: #282f3a;
  --simple-blue-light: #8792a2;
  --simple-brown-light: #cbbeae;
  --simple-brown-light-2: #CFBFAE;
  --simple-brown-gray: #bebaaa;
  --simple-yellow-light: #e7c57a;
  --background-light: #ebe8e6;
  --simple-yellow-light-2: #F5E6C4;
  --simple-green-light: #D2D9B4;
  --dark-yellow: #E89F4D;
  --border-gray: #D1CECD;
  --strong-yellow: #E8C57A;
  --strong-blue: #3062D4;

  --border-default: #E9ECEF;
  --border-subtle-neutral: #CFD6DD;
  --background-default: #FFF;
  --default-dark: #333;
  --placeholder-default: #999;
  --content-tertiary: #7E8B99;
  --default-menu: #28303A;
  --dark-blue: #29303B;
  --orange-home: #E59B50;
  --text-headlines: #222;
  --m-3-sys-light-on-surface-variant: #49454F;
  --filter-rounded-on: #FAF0E6;
  --m-3-sys-light-secondary-container: #E8DEF8;
  --m-3-state-layers-light-on-surface-variant-opacity-012: rgba(73, 69, 79, 0.12);
  --m-3-state-layers-light-on-secondary-container-opacity-016: rgba(29, 25, 43, 0.16);
  --m-3-sys-light-surface: #FEF7FF;
  --non-contextual-blue-muted: #D7E4FF;
  --non-contextual-grey-muted: #DEE3E7;
  --non-contextual-blue-on-muted: #05205E;
  --non-contextual-grey-on-muted: #1B242C;
  --content-secondary: #555F6D;

  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 20px;
  --font-size-xl: 22px;
  --font-size-xxl: 24px;
  --font-size-xxxl: 30px;

  --font-family-font_awesome_5_pro-light: "Font Awesome 5 Pro-Light";
  --font-family-font_awesome_5_pro-regular: "Font Awesome 5 Pro-Regular";
  --font-family-font_awesome_5_pro-solid: "Font Awesome 5 Pro-Solid";
  --font-family-poppins: "Poppins";
  --font-family-poppins-light: "Poppins-Light";
  --font-family-roboto: "Inter";
  --font-family-roboto-bold: "Inter-bold";
  --font-family-roboto_condensed: "Inter";
}

/* Classes */

.roboto-bold-lavender-14px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: 'Inter-bold';
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
}
.roboto-bold-lavender-16px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: 'Inter-bold';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.poppins-light-white-36px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Poppins";
  font-family: var(--font-family-poppins);
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
}
.roboto-normal-white-14px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Inter';
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.border-1px-white {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 1);
  border-color: var(--white);
}
.fontawesome5pro-regular-normal-lavender-10px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-normal-absolute-16px {
  color: #2a4cd3;
  color: var(--absolute-zero);
  font-family: 'Inter';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.roboto-bold-white-16px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Inter-bold';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.robotocondensed-bold-lavender-14px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: 'Inter-bold';
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
}
.robotocondensed-bold-electric-violet-14px {
  color: #e89f4d;
  color: var(--electric-yellow-2);
  font-family: 'Inter-bold';
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
}
.robotocondensed-bold-violet-14px {
  color: #736f6d;
  color: var(--light-brown);
  font-family: 'Inter-bold';
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
}
.robotocondensed-normal-white-24px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Inter';
  font-size: 24px;
  font-size: var(--font-size-xxl);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-regular-normal-lavender-20px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 20px;
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-regular-normal-gray-20px {
  color: #c1c9d2;
  color: var(--mountain-mist);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 20px;
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-regular-normal-white-20px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 20px;
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-regular-normal-white-16px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-light-white-16px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Light";
  font-family: var(--font-family-font_awesome_5_pro-light);
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 300;
  font-style: normal;
}
.robotocondensed-normal-violent-violet-16px {
  color: rgb(90, 55, 17);
  color: var(--violent-violet);
  font-family: 'Inter';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-bold-violent-violet-16px {
  color: rgb(90, 55, 17);
  color: var(--violent-violet);
  font-family: 'Inter-bold';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.robotocondensed-normal-violent-violet-14px {
  color: rgb(90, 55, 17);
  color: var(--violent-violet);
  font-family: 'Inter';
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-bold-violent-violet-14px {
  color: rgb(90, 55, 17);
  color: var(--violent-violet);
  font-family: 'Inter-bold';
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
}
.robotocondensed-bold-white-14px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Inter-bold';
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
}
.fontawesome5pro-solid-normal-lavender-24px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: "Font Awesome 5 Pro-Solid";
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: 24px;
  font-size: var(--font-size-xxl);
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-normal-white-12px-2 {
  color: rgba(255, 255, 255, 0.6);
  color: var(--white-2);
  font-family: 'Inter';
  font-size: 12px;
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-normal-lavender-14px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: 'Inter';
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.roboto-bold-lavender-14px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: 'Inter-bold';
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
}
.fontawesome5pro-light-white-18px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Light";
  font-family: var(--font-family-font_awesome_5_pro-light);
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
}
.fontawesome5pro-solid-normal-absolute-zero-12px {
  color: #2a4cd3;
  color: var(--absolute-zero);
  font-family: "Font Awesome 5 Pro-Solid";
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: 12px;
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-regular-normal-alto-20px {
  color: rgba(222, 222, 222, 1);
  color: var(--alto);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 20px;
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.roboto-normal-white-16px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Inter';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.roboto-normal-t-blue-16px {
  color: #27303B;
  color: var(--t-blue);
  font-family: 'Inter';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.roboto-normal-gray-16px {
  color: #868686;
  font-family: 'Inter';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.roboto-normal-white-28px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
}
.poppins-light-white-36px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Poppins-Light";
  font-family: var(--font-family-poppins-light);
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
}
.fontawesome5pro-regular-normal-white-14px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-bold-white-48px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Inter-bold';
  font-size: 48px;
  font-weight: 700;
  font-style: normal;
}
.roboto-bold-lavender-16px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: 'Inter-bold';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.fontawesome5pro-light-white-78px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Light";
  font-family: var(--font-family-font_awesome_5_pro-light);
  font-size: 78px;
  font-weight: 300;
  font-style: normal;
}
.fontawesome5pro-solid-normal-white-16px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Solid";
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-regular-normal-white-20px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 20px;
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-solid-normal-banana-12px {
  color: rgba(255, 196, 41, 1);
  color: var(--banana);
  font-family: "Font Awesome 5 Pro-Solid";
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: 12px;
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-regular-normal-white-24px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 24px;
  font-size: var(--font-size-xxl);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-solid-normal-white-24px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Solid";
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: 24px;
  font-size: var(--font-size-xxl);
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-normal-lavender-16px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: 'Inter';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-solid-normal-lavender-10px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: "Font Awesome 5 Pro-Solid";
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-bold-white-12px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Inter-bold';
  font-size: 12px;
  font-size: var(--font-size-xs);
  font-weight: 700;
  font-style: normal;
}
.border-1px-electric-violet {
  border-width: 1px;
  border-style: solid;
  border-color: #dcb568;
  border-color: var(--electric-yellow);
}
.fontawesome5pro-regular-normal-white-32px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 32px;
  font-weight: 400;
  font-style: normal;
}
.roboto-normal-electric-violet-14px {
  color: #dcb568;
  color: var(--electric-yellow);
  font-family: 'Inter';
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.roboto-bold-white-16px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Inter-bold';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.fontawesome5pro-solid-normal-white-14px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Solid";
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-normal-white-14px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Inter';
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-solid-normal-white-20px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Solid";
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: 20px;
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-solid-normal-color-20px {
  color: rgb(90, 55, 17);
  color: var(--violent-violet);
  font-family: "Font Awesome 5 Pro-Solid";
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: 20px;
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-normal-white-12px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Inter';
  font-size: 12px;
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-bold-lavender-16px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: 'Inter-bold';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.robotocondensed-bold-t-blue-16px {
  color: #27303B;
  color: var(--t-blue);
  font-family: 'Inter-bold';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.fontawesome5pro-regular-normal-lavender-24px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 24px;
  font-size: var(--font-size-xxl);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-regular-normal-white-18px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
}
.border-0-5px-white {
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 1);
  border-color: var(--white);
}
.fontawesome5pro-solid-normal-maroon-flush-12px {
  color: #D92B5F;
  color: var(--maroon-flush);
  font-family: "Font Awesome 5 Pro-Solid";
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: 12px;
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-bold-white-24px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Inter-bold';
  font-size: 24px;
  font-size: var(--font-size-xxl);
  font-weight: 700;
  font-style: normal;
}
.robotocondensed-bold-lavender-24px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: 'Inter-bold';
  font-size: 24px;
  font-size: var(--font-size-xxl);
  font-weight: 700;
  font-style: normal;
}
.fontawesome5pro-regular-normal-white-22px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 22px;
  font-size: var(--font-size-xl);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-regular-normal-electric-violet-14px {
  color: #dcb568;
  color: var(--electric-yellow);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.fontawesome5pro-regular-normal-electric-violet-16px {
  color: #dcb568;
  color: var(--electric-yellow);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-bold-white-16px-2 {
  font-family: 'Inter-bold';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.fontawesome5pro-solid-normal-lavender-20px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: "Font Awesome 5 Pro-Solid";
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: 20px;
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-normal-blue-chalk-14px {
  color: rgba(241, 224, 255, 1);
  color: var(--blue-chalk);
  font-family: 'Inter';
  font-size: 14px;
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.roboto-normal-electric-violet-16px {
  color: #dcb568;
  color: var(--electric-yellow);
  font-family: 'Inter';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.roboto-bold-electric-violet-16px {
  color: #dcb568;
  color: var(--electric-yellow);
  font-family: 'Inter';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 700;
  text-align: left;
}
.robotocondensed-normal-white-16px-2 {
  font-family: 'Inter';
  font-size: 16px;
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.robotocondensed-normal-white-16px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Inter';
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}
.robotocondensed-normal-black-16px {
  color: black;
  font-family: 'Inter';
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}
.robotocondensed-bold-black-16px {
  color: black;
  font-family: 'Inter-bold';
  font-size: var(--font-size-l2);
  font-style: bold;
  font-weight: 700;
}
.robotocondensed-normal-t-blue-16px {
  color: #27303B;
  color: var(--t-blue);
  font-family: 'Inter';
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}

body {
  /* background-color: #322468; */
}

input,
select {
  outline: none;
  border: 0px;
}

.listData {
  width: calc(100% - 40px);
  margin: 30px 0;
  border-collapse: collapse;
}

.listData th {
  /* background-image: url(https://anima-uploads.s3.amazonaws.com/projects/621fc5babe91377f45cca168/releases/62275bda4aa2a115d7083fa6/img/header-1@1x.svg); */
  background-size: cover;
  background-position: center center;
  padding: 0 0 0 15px;
  text-align: left;
  vertical-align: middle;
}

.listData tr th > div {
  display: flex;
  align-items: center;
}

.listData tr td {
  text-align: left;
  vertical-align: middle;
  padding: 15px;
}

.listData .field-id {
  background-position: left center;
}

.listData .field-actions {
  background-position: right center;
}

.listData > tr:nth-child(even) {
  background: #736f6d;
  background: var(--light-brown)
}
.listData > tr:nth-child(odd) {
  background: #27303B;
  background: var(--t-blue);
}

.field-actions {
  text-align: center !important;
}

.actionsButtons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.statusField {
  display: flex;
}

.labelType {
  display: none;
}

.active .labelType {
  color: #a885d7;
  font-weight: bolder;
  font-family: 'Inter';
  margin-right: 10px;
  display: block !important;
}

.tabla {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  min-height: 254px;
  width: 100%;
  padding: 0 15px;
}

.line-3 {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  margin-top: 1px;
  min-width: 1250px;
  padding: 0 2px;
}

.id-field {
  letter-spacing: 0;
  min-height: 19px;
  min-width: 120px;
}

.robotocondensed-regular-normal-white-16px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: var(--font-family-roboto_condensed-regular);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}

.actions {
  align-items: center;
  display: flex;
  height: 40px;
  padding: 0 20px !important;
  margin-left: 20px;
  justify-content: center;
}

.fontawesome5pro-regular-normal-lavender-20px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: "Font Awesome 5 Pro-Regular";
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: var(--font-size-xl2);
  font-style: normal;
  font-weight: 400;
}

.pen-2 {
  letter-spacing: 0;
  margin-left: 26px;
  min-height: 20px;
  min-width: 20px;
}

.place-1 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 23px;
}

.status {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: flex-start;
}

.fontawesome5pro-solid-normal-absolute-zero-12px {
  color: #2a4cd3;
  color: var(--absolute-zero);
  font-family: "Font Awesome 5 Pro-Solid";
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: var(--font-size-s2);
  font-style: normal;
  font-weight: 400;
}

.place {
  height: 12px;
  letter-spacing: 0;
  min-width: 12px;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.status-field {
  letter-spacing: 0;
  margin-left: 10px;
  min-height: 19px;
  width: 100%;
  text-align: left;
}

.robotocondensed-regular-normal-absolute-zero-16px {
  color: #2a4cd3;
  color: var(--absolute-zero);
  font-family: var(--font-family-roboto_condensed-regular);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}

.bogot-1 {
  letter-spacing: 0;
  margin-left: 72px;
  min-height: 19px;
  width: 128px;
}

.place-5 {
  letter-spacing: 0;
  margin-left: 52px;
  min-height: 19px;
  width: 128px;
}

.line-1 {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: flex-start;
  margin-top: 2px;
  width: 100%;
}

.line-2 {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  margin-top: 2px;
  min-width: 1250px;
  padding: 0 2px;
  background-color: #4e331c;
  background-color: var(--valhalla);
}

.city-field {
  letter-spacing: 0;
  margin-left: 72px;
  min-height: 19px;
  width: 10%;
  text-align: left;
}

.trash-alt {
  letter-spacing: 0;
  margin-left: 25px;
  min-height: 20px;
  min-width: 18px;
}

.trash-alt-sm {
  letter-spacing: 0;
  margin-left: 25px;
  min-height: 15px;
  min-width: 13px;
}

.trash-alt-super {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 18px;
}

.overlap-group2 {
  height: 19px;
  margin-left: 20px;
  position: relative;
  width: 388px;
}

.name-1 {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  width: 280px;
}

.superadmin-1 {
  left: 260px;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  width: 128px;
}

.place-3 {
  letter-spacing: 0;
  margin-left: 72px;
  min-height: 19px;
  width: 128px;
}

.status-3 {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  margin-left: 129px;
  min-width: 175px;
  padding: 0 7.5px;
}

.pending {
  color: #D92B5F;
  color: var(--maroon-flush);
  font-family: var(--font-family-roboto_condensed-regular);
  font-size: var(--font-size-l2);
  font-weight: 400;
  letter-spacing: 0;
  margin-left: 10px;
  min-height: 19px;
  width: 128px;
}

.place-4 {
  color: #D92B5F;
  color: var(--maroon-flush);
  font-family: "Font Awesome 5 Pro-Solid";
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: var(--font-size-s2);
  font-weight: 400;
  height: 12px;
  letter-spacing: 0;
  min-width: 12px;
}

.pen-1 {
  letter-spacing: 0;
  margin-left: 26px;
  min-height: 20px;
  min-width: 20px;
}

.not-set {
  letter-spacing: 0;
  margin-left: 52px;
  min-height: 19px;
  min-width: 44px;
}

.overlap-group1 {
  height: 19px;
  margin-left: 20px;
  position: relative;
  width: 388px;
}

.association {
  left: 260px;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  width: 128px;
}

.cactus-wren-elementary-school {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  width: 280px;
}

.school-1 {
  left: 260px;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  width: 128px;
}

.robotocondensed-regular-normal-lavender-16px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: var(--font-family-roboto_condensed-regular);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}

.not-set-1 {
  letter-spacing: 0;
  margin-left: 52px;
  min-height: 19px;
  width: 128px;
}

.pen-3 {
  letter-spacing: 0;
  margin-left: 26px;
  min-height: 20px;
  min-width: 20px;
}

.line {
  align-items: center;
  background-color: #4e331c;
  background-color: var(--valhalla);
  display: flex;
  height: 50px;
  justify-content: flex-end;
  margin-top: 1px;
  min-width: 1250px;
  padding: 0 2px;
}

.place-6 {
  letter-spacing: 0;
  margin-left: 72px;
  min-height: 19px;
  width: 128px;
}

.place-7 {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Pro-Solid";
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: var(--font-size-s2);
  font-weight: 400;
  height: 12px;
  letter-spacing: 0;
  min-width: 12px;
}

.school-container {
  height: 19px;
  margin-left: 20px;
  position: relative;
  width: 388px;
}

.cactus-wren-elementary-school-1 {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  width: 280px;
}

.chevron-down {
  letter-spacing: 0;
  margin-left: 10px;
  min-height: 20px;
  min-width: 18px;
}

.type {
  align-items: center;
  display: flex;
  height: 40px;
}

.type-1 {
  letter-spacing: 0;
  min-height: 19px;
  min-width: 31px;
}

.name {
  align-items: center;
  display: flex;
  height: 40px;
  margin-left: 20px;
}

.search-by-name {
  letter-spacing: 0;
  min-height: 19px;
  min-width: 118px;
  text-decoration: underline;
}

.actions-1 {
  align-self: center;
  letter-spacing: 0;
  margin-left: 20px;
  margin-top: 1px;
  min-height: 19px;
  min-width: 150px;
}

.status-1 {
  align-items: center;
  display: flex;
  height: 40px;
  margin-left: 41px;
  min-width: 175px;
  padding: 0 10px;
}

.robotocondensed-bold-lavender-16px {
  color: #CEBEAD;
  color: var(--lavender);
  font-family: 'Inter-bold';
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 700;
}

.status-2 {
  letter-spacing: 0;
  min-height: 19px;
  min-width: 42px;
}

.sort-amount-down {
  letter-spacing: 0;
  margin-left: 10px;
  min-height: 20px;
  min-width: 20px;
}

.robotocondensed-bold-white-16px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Inter-bold';
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 700;
}

.id-1 {
  letter-spacing: 0;
  margin-bottom: 1px;
  min-height: 19px;
  min-width: 14px;
}

.pen {
  letter-spacing: 0;
  margin-left: 26px;
  min-height: 20px;
  min-width: 20px;
}

.status-DELETED div {
  color: #c1c9d2;
  color: var(--mountain-mist);
}

.status-ACTIVE div {
  color: #2a4cd3;
  color: var(--absolute-zero);
}

.status-PENDING_CONFIRMATION div {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
}

.status-INACTIVE div {
  color: #c1c9d2;
  color: var(--mountain-mist);
}

.status-PENDING_APPROVAL div {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #E89F4D;
  border-color: var(--dark-yellow);
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: #aeaeae;
  border-bottom-color: var(--simple-gray);
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
  display: none;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
  border-bottom-color: var(--simple-gray);
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
  display: none;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  bottom: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #aeaeae;
  border-top-color: var(--simple-gray);
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: rgba(255, 255, 255, 1);
  background-color: var(--white);
  color: rgb(255, 255, 255);
  border: 1px solid  rgba(255, 255, 255, 1);
  border: 1px solid  var(--white);
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 3;
}
.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 0px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^=right] {
  padding-left: 8px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  padding-right: 8px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  background-color: var(--white);
  border-bottom: 0px solid #aeaeae;
  border-bottom: 0px solid var(--simple-gray);
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin: 15px 0;
  color: #333;
  color: var(--default-dark);
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 16px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 45px;
}
.react-datepicker__navigation--next {
  right: 45px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
  display: inline-block;
    width: 57px;
    margin: 2px;
    height: 57px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DatetickerYear{
  border: 1px solid #eeeeee;
  border: 1px solid var(--simple-gray-2);
    border-radius: 11px;
    overflow: hidden;
}

.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.react-datepicker__month {
  margin: 0.4rem 1.4rem 1.4rem 2.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  border-left: 1px solid var(--simple-gray);
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border: 1px solid var(--simple-gray);
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #7400F9;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #7400F9;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: rgba(0, 0, 0, 1);
  color: var(--black);
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker__day{
  text-align: center;
  padding: 6px 0;
  font-size: 14px;
}

.react-datepicker__day-name{
  font-weight: 700;
  font-size: 18px;
  color: #333;
  color: var(--default-dark);
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #7400F9;
  color: #fff;
}
.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #7400F9;
}
.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}
.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 8px;
  background-color: #e8e8e8;
  color: rgba(0, 0, 0, 1);
  color: var(--black);
  border: 0px;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  background-color: var(--white);
  color: rgba(0, 0, 0, 1);
  color: var(--black);
  border: 2px solid #E89F4D;
  border: 2px solid var(--dark-yellow);
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #e8e8e8;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  background-color: var(--white);
  color: rgba(0, 0, 0, 1);
  color: var(--black);
  border: 2px solid #E89F4D;
  border: 2px solid var(--dark-yellow);
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  border-radius: 8px;
  background-color: #e8e8e8;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #7400F9;
}
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: transparent !important;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
  border: 1px solid var(--simple-gray);
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-select,
.react-datepicker__month-select {
  color: black;
  display: block;
  background-color: white;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #7400F9;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  border-top: 1px solid var(--simple-gray);
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker-popper.some-custom-class{
  padding-top: 0px;
  border: 1px solid;
  width: calc(100% + 2px);
  left: -12px !important;
}

.some-custom-class .react-datepicker,
.some-custom-class .react-datepicker__month-container{
  width: 100%;
}
.some-custom-class .react-datepicker__month{
  width: 100%;
  margin: 0 auto;
}

input.red-border {
  padding: 0 0;
    font-family: 'Inter-Bold';
    font-size: 16px;
}

input.red-border::-webkit-input-placeholder { 
  font-family: 'Inter-Bold';
  color: black;
  font-size: 16px;
}

input.red-border::placeholder { 
  font-family: 'Inter-Bold';
  color: black;
  font-size: 16px;
}

input.red-border::-webkit-input-placeholder  {
  color: rgb(0, 0, 0);
  opacity: 1; /* Firefox */
}

input.red-border::placeholder  {
  color: rgb(0, 0, 0);
  opacity: 1; /* Firefox */
}

input.red-border:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: rgb(0, 0, 0);
}

input.red-border::-ms-input-placeholder { /* Microsoft Edge */
 color: rgb(0, 0, 0);
}

.textTo{
  padding: 0 20px;
  color: white;
  font-family: 'Inter';
}


.inputDirthday{
  height: 40px;
  background-color: #402a73;
  color: white;
  border: 0;
}

strong {
  font-weight: bold !important;
}

em {
  font-style: italic !important;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
  width: 110px !important;
}

.OBottons{
  justify-content: center !important;
}

.OBottons .buttonClose{
  background: transparent !important;
  border: 1px solid #a885d7 !important;
  color: #a885d7 !important;
  font-size: 16px !important;
}

option{
  background-color: #2c2c2c;
}
.link-post {
  color: #7400F9 !important;
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
  display: inline-block !important;
}

.round-avatar {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.modalDiscardChangesTitle {
  font-family: 'Inter-bold' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #27303B;
  color: var(--t-blue);
}

.modalDiscardChanges-footer{
  display: flex;
  justify-content: space-between;
}

.ahaInput::-webkit-outer-spin-button,
.ahaInput::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.ahaInput {
  -moz-appearance:textfield; /* Firefox */
}

.ahaDescriptionTextarea {
  outline: none;
  resize: none;
}

.iconReaction{
  margin-right: 5px;
  padding-right: 5px;
}

.iconReactionLeft {
  margin-left: 5px;
  padding-left: 5px;
}

.ant-picker-time-panel-column:after {
  content: "";
  display: block;
  height: 0px;
}

.carousel .control-prev.control-arrow:before {
  content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='chevron-left' class='svg-inline--fa fa-chevron-left ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' color='%2327313b'%3E%3Cpath fill='currentColor' d='M206.7 464.6l-183.1-191.1C18.22 267.1 16 261.1 16 256s2.219-11.97 6.688-16.59l183.1-191.1c9.152-9.594 24.34-9.906 33.9-.7187c9.625 9.125 9.938 24.37 .7187 33.91L73.24 256l168 175.4c9.219 9.5 8.906 24.78-.7187 33.91C231 474.5 215.8 474.2 206.7 464.6z'%3E%3C/path%3E%3C/svg%3E") !important;
  width: 15px;
  height: 15px;
}
.carousel .control-next.control-arrow:before {
  content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='chevron-right' class='svg-inline--fa fa-chevron-right ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' color='%2327313b'%3E%3Cpath fill='currentColor' d='M113.3 47.41l183.1 191.1c4.469 4.625 6.688 10.62 6.688 16.59s-2.219 11.97-6.688 16.59l-183.1 191.1c-9.152 9.594-24.34 9.906-33.9 .7187c-9.625-9.125-9.938-24.38-.7187-33.91l168-175.4L78.71 80.6c-9.219-9.5-8.906-24.78 .7187-33.91C88.99 37.5 104.2 37.82 113.3 47.41z'%3E%3C/path%3E%3C/svg%3E") !important;
  width: 15px;
  height: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-inp {
  margin-top: 15px;
}

.inputBirthdayAdmin {
  background-color: #27303B !important;
  background-color: var(--t-blue) !important;
}

.center-items {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PhoneInput{
  background-color: white;
    height: 50px;
    padding: 0px 10px;
    border-radius: 12px;
}

.PhoneInput input{
  border: 0
}

.PhoneInputCountryIcon--border {
  border: none;
  background-color: transparent !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paddingRight15{
  padding-right: 15px;
}