.club-right-section {
  width: 25%;
  z-index: 2;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
background: #aa9275;
  background-size: cover;
  position: fixed;
  padding-top: 25px;
  padding-bottom: 110px;
  height: 100%;
  overflow-y: auto;
  right: 0;
}

.menu-sidebar::-webkit-scrollbar {
  display: none;
}

.club-right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background-color: var(--t-blue);
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.club-right-title {
  padding-top: 20px;
  color: var(--white);
  font-family: var(--font-family-roboto_condensed);
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
}

.club-balance-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.club-wallet-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.balance {
  color: white;
  font-size: 30px;
  margin-right: 4px;
  font-family: "Poppins-light";
}

.go-to-wallet {
  text-decoration: underline;
  font-weight: bold;
  margin-right: 10px;
}

.club-birthday-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.club-birthday-row textarea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 65%;
  background-color: var(--simple-blue-light);
  border: 1px solid white;
  border-radius: 8px;
  padding: 10px 10px;
  font-family: 'Inter';
  resize: none;
  outline: none;
}

.club-birthday-row textarea::-webkit-input-placeholder {
  color: white;
}

.club-birthday-row textarea::placeholder {
  color: white;
}

.image-birthday {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

#messaging {
  height: 600px;
}

.say-something {
  color: white;
}

.aha-logo {
  width: 40px;
  height: 40px;
  margin-left: -4px;
  margin-right: -4px;
}

.hbdToday{
  border: 1px solid var(--t-blue);
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--t-blue);
  font-family: 'Inter';
  font-size: 16px;
  height: 40px;
}

.hbdToday button{
  border: 0;
  background-color: transparent;
  cursor: pointer;  
  outline: none;
}