.club-view-information {
  width: 100%;
  padding: 5px 30px;
  display: flex;
  flex-direction: column;
  min-height: 1024px;
}

.club-view-information h1 {
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--t-blue);
  font-size: var(--font-size-xxxl);
  text-align: left;
}

.club-view-information p {
  width: 100%;
  padding: 10px 0 20px 0 !important;
  text-align: left;
}

.information-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.edit-info-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--t-blue);
  border-radius: 12px;
  padding: 12px 24px;
  cursor: pointer;
}

.club-view-edit-text {
  margin-left: 6px;
  color: white;
  font-family: var(--font-family-roboto);
}

.key-words-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.key-word {
  background-color: #28115a;
  padding: 10px 20px;
  border-radius: 82px;
  margin-right: 14px;
}

#officers-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

#view-all-officers {
  text-decoration: underline;
}

.question-icon {
  margin-left: 20px;
}

.officers-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
}

.officers-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 48px;
}

.officer-avatar {
  width: 50px;
  height: 50px;
  border: 2px solid var(--onyx);
  border-radius: 100%;
}

.officer-name {
  font-weight: bold;
  margin-bottom: 4px;
  text-align: left;
}

.advisors-column {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.advisors-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.advisors-row-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.advisor-avatar {
  width: 50px;
  height: 50px;
  border: 2px solid var(--onyx);
  border-radius: 100%;
}

.advisor-name {
  margin-left: 14px;
}
