.fontawesome5pro-regular-normal-lavender-20px {
  color: var(--lavender);
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.icons-navbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 50px;
}

.icon-label {
  margin-top: 8px;
  margin-bottom: 18px;
  width: 100%;
  text-align: center !important;
}

.icon-label.relative{
  position: relative;
}

.icons-column {
  border-bottom: 2px solid transparent;
  width: 100%;
}

.icons-column-selected {
  /* border-bottom: 2px solid var(--lavender); */
  
    border-bottom: 2px solid var(--t-blue);
  width: 100%;
}
