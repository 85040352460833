.content-clubInvites{
    width: 100%;
    font-family: 'Inter';
    padding: 0 30px;
} 

.labelListInvites {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--t-blue);
    text-align: left;
    display: block;
    padding: 12px 0;
    margin-right: 15px;
}

.cardInvite {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cardInvite img{
    width: 45px;
    border-radius: 50%;
    margin: 14px 14px 14px 0px;
}

.cardInvite > p {
    width: 40%;
    text-align: left;
    color: #fff;
}

.cardInvite .isOpen p{
    font-size: 14px;
    font-weight: bold;
    color: var(--t-blue);
}

.cardInvite .isOpen span{
    font-size: 14px;
    font-weight: lighter;
    color: var(--t-blue);
}

.nameInvite {
    display: flex;
    align-items: center;
    color: var(--t-blue);
    width: 40%;
}

.buttonTrash{
    background-color: transparent;
    border: 0;  
    cursor:pointer;
}

.containerInputSearch{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.openInviteActions{
    display: flex;
    flex-direction: row;
}

.paper-plane-invite-club{
    margin-right: 20px;
    min-height: 20px;
    min-width: 20px;
}

.wrapButton-disabled-paperPlane{
    background: transparent;
    border-width: 0px;
}