.fontawesome5pro-regular-normal-lavender-20px {
    color: var(--lavender);
    font-family: var(--font-family-font_awesome_5_pro-regular);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 400;
  }
  
  .section-navbar {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 50px;
    margin-top: 6px;
  }
  
  .section-label {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    text-align: center;
  }
  
  .section-column {
    border-bottom: 2px solid transparent;
    width: 100%;
  }
  
  .section-column-selected {
    background-color: var(--t-blue);
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .section-column-selected .section-label{
    color: var(--white);
  }
  
  #left-rad {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow: hidden;
  }
  
  #right-rad {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
  }
  
  .dateEvent{
    width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .dateEvent .react-datepicker{
    background-color: transparent;
    border: 0;
    width: 100%;
  }
  
  .dateEvent .react-datepicker__month-container {
    width: 100%;
  }
  
  .dateEvent .react-datepicker__day-name, 
  .dateEvent .react-datepicker__day, 
  .dateEvent .react-datepicker__time-name {
    width: 1.3rem;
    margin: 3px 12px;
  }
  
  .dateEvent .react-datepicker__month{
    margin: 0.4rem;
  }
  
  .dateEvent .react-datepicker__header{
    background-color: transparent;
  }
  
  .listMyEvents{
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
  }
  
  .titleDate {
    width: 100%;
    height: 19px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #A885D7;
    margin-left: 30px;
    margin-top: 15px;
    text-align: left;
  }
  
  .cardEvents.active{
    background-color: var(--onyx);
  }
  
  .cardEvents{
    border-radius: 60px 0px 0px 60px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 12px 0;
  }
  
  .cardEvents.active::before {
    content: '';
    width: 9px;
    background-color: #383838;
    top: 0;
    right: 0;
    position: absolute;
    height: 100vh;
  }
  
  .cardEvents img {
    width: 60px;
    height: 60px;
    border-radius: 3px;
    margin-left: 25px;
  }
  
  .infoCardEvent {
    padding-left: 15px;
    color: white;
    text-align: left;
  }
  
  .infoCardEvent h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }
  
  .infoLocationItem {
    display: flex;
      align-items: center;
      font-family: 'Inter';
      padding: 4px 0;
  }
  
  .infoLocationItem > div{
    margin-right: 10px;
  }
  
  .buttonCheckedEvent {
    position: absolute;
    bottom: 10px;
    right: 15px;
    display: flex;
  }
  
  .buttonCheckedEvent button {
    width: 30px;
    height: 30px;
    border: 0;
    border-radius: 12px;
    margin: 0 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .buttonCheckedEvent button div{
    margin: 0;
  }
  
  button.btnCclEvent {
    background-color: #ffffff;
    border: 1px solid #590CF6;
  }
  
  button.btnCclEvent div{
    color: #590CF6;
  }
  
  button.btnChkEvent {
    background-color:#590CF6;
  }
  
  .cardNoEvents{
    display: flex;
    align-items: center;
    font-family: 'Inter';
    padding: 4px 0;
    color: white;
    font-size: 14px;
    margin-left: 30px;
    margin-top: 15px;
  }
  
  .cardNoEvents > div {
    margin-right: 10px;
  }
  
  .containerButtonCreate{
    width: 100%;
  }
  
  .btnCreateEvent{
    height: 40px;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid var(--t-blue);
    padding: 10px 15px;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-family: 'Inter';
    font-size: 16px;
    justify-content: center;
    background-color: var(--t-blue);
  }
  
  .modalCreateEvent .titleModal{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--t-blue);
    display: inline-block;
    /*padding-bottom: 0;*/
  }
  
  .EventModal__row .EventModal__input label{
    margin-top: 5px;
  }
  
  .modalCreateEvent .nav div{
    width: 50%;
  }
  
  .modalCreateEvent .modal-dialog{
    display: flex;
    align-items: center;
  }
  
  .modalCreateEvent .modal-content{
    padding-bottom: 0;
    width: 100%;
  }
  
  .modalCreateEvent .modal-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    padding-bottom: 0;
  }
  
  .modalCreateEventHeader__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  
  .modalCreateEventHeader__number {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    border: 1px solid var(--t-blue);
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--t-blue);
  }
  
  .modalCreateEvent .modal-body{
    max-height: 75vh;
    overflow: scroll;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .modalCreateEventHeader__number.active {
    border: 0;
    background-color: var(--t-blue);
    color: var(--white);
    font-weight: 600;
  }
  
  .createEvent__selectTypeLabel {
    font-size: 16px;
    font-weight: 700;
    padding: 10px;
    color: var(--t-blue);
    font-family: 'Inter';
  }
  
  .createEvent__selectTypeOptions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
  
  .createEvent__selectTypeActions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    gap: 20px;
  }
  
  .createEvent__selectTypeOption {
    flex: 1 1;
    background-color: #2C2C2C;
    padding: 20px;
    border-radius: 4px;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #2C2C2C;
  }
  
  .createEvent__selectTypeOption:hover {
    border: 1px solid var(--t-off-white);
  }
  
  .createEvent__selectTypeOption.active {
    border: 1px solid var(--t-off-white);;
    background-color: #121212;
  }
  
  .modalCreateEventHeader__number,
  .createEvent__selectTypeOption span{
    font-family: 'Inter';
  }
  
  .createEvent__btn {
    flex: 1 1;
    padding: 12.5px;
    border-radius: 12px;
  }
  
  .inputActivityName{
    width: 100%;
    height: 40px;
    background-color: #383838;
    border: 0;
    border-bottom: 2px solid #A885D7;
    color: white;
  }
  
  label.noFocus {
    position: absolute;
  }
  
  label.focus {
    position: relative;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #A885D7;
    margin: 0;
    padding: 0;
  }
  
  .textareaActivity h2,
  .titleInput{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--t-blue);
    margin-bottom: 10px;
    display: block;
    margin-top: 20px;
    text-align: left;
  }
  
  .jodit-react-container{
    /* height: 90px; */
  }
  
  .inputContainerIcon{
    display: flex;
    align-items: center;
    background-color: #2c2c2c;
  }
  
  .iconMaxAtt{
    padding: 0 0 0 15px;
  }
  
  .select-container-status{
    font-family: 'Inter';
  }
  
  .modalCreateEvent .modal-footer{
    display: flex;
    justify-content: space-between;
  }
  
  .modalCreateEvent .modal-footer button {
    width: 45%;
    height: 40px;
    border-radius: 7px;
  }
  
  .modalCreateEvent .buttonClose {
    border: 1px solid var(--t-blue);
    color: var(--t-blue);
  }
  
  .modalCreateEvent .buttonSend {
    background-color: var(--t-blue);
    color: var(--t-off-white);
    border: 0;
  }
  
  .modalCreateEvent .react-datepicker__input-container input {
    font-size: 16px;
    outline: none;
    width: 100%;
    background-color: #2c2c2c;
    margin: 0px 0;
    border: 0;
    height: 40px;
    padding: 0px 20px;
    color: white;
  }
  
  .rc-time-picker-input {
    padding: 12px 12px 12px 45px !important;
  }
  
  *[class^='rc-time']{
    font-family: 'Roboto' !important;
  }
  
  
  .contentTime{
    display: flex;
    align-items: center;
    border-radius: 9px;
    border: 1px solid var(--simple-gray);
    width: 100%;
  }
  
  .timeInput {
    width: 100%;
  }
  
  .iconClock{
    padding: 0 0 0 15px;
    font-size: 18px;
  }
  
  .containersInput{
    display: flex;
    align-items: center;
    background-color: #2c2c2c;
    border-radius: 5px;
  }
  
  .map-marker-alt{
    margin: 0 10px;
  }
  
  .containersInput input{
    background-color: #2c2c2c;
    border: 0;
    height: 40px;
    color: white;
  }
  
  .containerCalendar{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #2c2c2c;
  }
  
  .calendar-alt {
    margin-right: 15px;
  }
  
  .btnInviteAttendees{
    height: 40px;
    border: 1px solid #7500f9;
    border-radius: 8px;
    color: #ffffff;
    background-color: #7500f9;
    cursor: pointer;
    position: relative;
    top: 42px;
    padding: 0 15px;
  }
  
  .dateToday{
    text-transform: capitalize;
    border: 1px solid var(--t-blue);
    padding: 10px 15px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--t-blue);
    font-family: 'Inter';
    font-size: 16px;
    cursor: pointer;
  }
  
  .dateToday button{
    border: 0;
    background-color: transparent;
    cursor: pointer;  
    outline: none;
  }
  
  .listMyEvents {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .listMyEvents:hover::-webkit-scrollbar{
    display: block;
  }
  
  .listMyEvents::-webkit-scrollbar {
    width: 5px;
    display: none;
  }
  
  .listMyEvents::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  .listMyEvents::-webkit-scrollbar-thumb {
    background: var(--lavender); 
    border-radius: 10px;
  }
  
  .listMyEvents::-webkit-scrollbar-thumb:hover {
    background: var(--light-brown); 
  }