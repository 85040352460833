.container-layout {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
}

.club-content {
  flex: 1 1;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 1024px;
  position: relative;
  width: 100%;
  background-color: var(--light-brown);
}

.screen a {
  display: contents;
  text-decoration: none;
}

.club-center {
  width: 47%;
  min-height: 1000px;
  /* background-color: var(--light-brown); */
    /* TARA */
  /* background-color: var(--t-light-tan); */
  padding: 10px;
  margin-top: 95px;
}

.paddingProfilePost{
  padding-left: 30px;
  padding-right: 30px;
}