.overlap{
    position: absolute;
    background-color: #00000073;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    display: none;
    z-index: 1000;
}

#sidebar{
    position: fixed;
    width: 380px;
    height: 100vh;
    background: var(--t-off-white) ;
    top: 0;
    right: -380px;
    transition: all 500ms linear;
    display: flex;
    flex-direction: column;
    z-index: 1002;
}

.overlap.active{
    display: block;
}

#sidebar.active{
    right: 0px;
}

.buttonCloseMenu{
    border: 0;
    width: 30px;
    margin-top: 20px;
    position: absolute;
    right: 15px;
    background-color: transparent;
}

.photoProfile{
    display: flex;
    margin-top: 60px;
    justify-content: center;
    flex-direction: column;
}

.photoProfile img{
    height: 100%;
    width: 100%;
    object-fit: cover;
   /*  height: 80px;
    margin: 0 auto;
    border: 5px solid #590cf6;
    border-radius: 50%; */
}

.photoProfile span{
    font-size: 24px;
    color: var(--t-blue);
    font-family: 'Inter';
    margin: 20px 0;
    font-weight: 700;
}

.listMenu li {
    display: flex;
    color: var(--t-blue);
    font-size: 16px;
    font-family: 'Inter';
    margin: 35px 15px;
}

.listMenu .iconMenuL {
    padding: 0 20px;
    width: 30px !important;
}

.listMenu .letterMenuL {
    padding: 0 42px;
    padding-left: 28px;
    width: 30px !important;
}

#sidebar .buttonCloseMenu{
    cursor: pointer;
}

.listMenu li{
    cursor: pointer;
}

.letterA{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    color: var(--t-blue);
    font-size: var(--font-size-l);
}

.containerImagProfile{
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 5px solid var(--simple-blue-dark);
    border-radius: 50%;
    margin: 0 auto; 
}

.containerImageProfileHeader{
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;      
}

.prof-pic{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.linkTermsPrivacy{
    color: var(--t-blue);
}